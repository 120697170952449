import styled from '@emotion/styled';
import { StyledFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/styles';

export const StyledGuestsFilter = styled(StyledFilter)`
  min-width: 220px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${(props) => props.theme['padding-sm']};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
