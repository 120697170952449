import React from 'react'
import { Block, WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks'
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext'
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks'
import { Flex, Box, chakra } from '@chakra-ui/react'
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage'
import splitScreenCdnProps from '@rentivo/gatsby-core/src/components/wpg/lazy/HeroGallery/splitScreen.cdn'
import cdnProps from '@rentivo/gatsby-core/src/components/wpg/lazy/HeroGallery/cdn'
import loadable from '@loadable/component'
const Gallery = loadable(() =>
  import('@rentivo/gatsby-core/src/components/wpg/lazy/HeroGallery/Gallery')
)
import { isArray } from '@rentivo/gatsby-core/src/utils/arrays'
import { useHeaderHeight } from '@rentivo/gatsby-core/src/hooks/useHeaderHeight'

const HeroGalleryBlock = props => {
  const {
    attrs,
    innerBlocks,
    className,
    isMobileOnly,
    classNameType = 'wpg-b_hero-gallery',
  } = props

  const headerHeight = useHeaderHeight(true)
  let {
    images,
    fullHeight = false,
    height = '550px',
    splitScreen = false,
    splitAlignImage = 'left',
    bgOverlay = true,
    bgOverlayColor = 'blackAlpha.500',
    innerNav = false,
    focalPoint = { x: 0.5, y: 0.5 },
    dimRatio = 1,
    backgroundClassName = '',
  } = attrs

  let galleryImages = []
  if (images) {
    if (isArray(images)) {
      galleryImages = images
    } else {
      galleryImages = JSON.parse(decodeURIComponent(images))
    }
  }

  const useSlider = galleryImages.length > 1
  const { options, ...classNameProps } = useClassNameProps(
    className,
    classNameType
  )
  const {
    options: backgroundOptions,
    ...backgroundClassNameProps
  } = useClassNameProps(backgroundClassName, 'wpg-b_hero-gallery-img')
  const propValue = (
    regularProp,
    splitScreenLeftProp = regularProp,
    splitScreenRightProp = splitScreenLeftProp
  ) =>
    splitScreen
      ? splitAlignImage === 'left'
        ? splitScreenLeftProp
        : splitScreenRightProp
      : regularProp

  const cornerProp = { base: 'auto', lg: propValue(0, 'auto') }
  const cdnPropsToUse =
    attrs.splitScreen && !isMobileOnly ? splitScreenCdnProps : cdnProps
  const childProps = {
    color: { base: 'text', lg: propValue('white', 'text') },
    textShadow: {
      base: 'none',
      lg: propValue('0px 1px 7px rgba(0,0,0,0.5)', 'none'),
    },
  }

  return (
    <Block attrs={attrs} position="relative" {...classNameProps}>
      <Flex
        display="flex"
        direction={{ base: 'column', lg: propValue('column', 'row') }}
      >
        <Box
          position="relative"
          order={{ base: 0, lg: propValue(0, 0, 1) }}
          w={{ base: '100%', lg: propValue('100%', '50%') }}
          h={{
            base: 0,
            lg: fullHeight ? `calc(100vh - ${headerHeight})` : height,
          }}
          paddingBottom={{ base: '56.25%', lg: 0 }}
          overflow="hidden"
          _after={{
            position: 'absolute',
            zIndex: 1,
            inset: '0px',
            content: '""',
            backgroundColor: bgOverlay ? bgOverlayColor : 'transparent',
            opacity: dimRatio,
          }}
        >
          <chakra.div
            layerStyle="coverImage"
            css={{
              img: {
                top: `${(1 - focalPoint.y) * 100 + 1}%`,
                left: `${(1 - focalPoint.x) * 100 + 1}%`,
              },
            }}
            position={innerNav || !useSlider ? 'absolute' : 'relative'}
            inset="0px"
          >
            {useSlider ? (
              <Gallery
                {...props}
                galleryImages={galleryImages}
                cdnPropsToUse={cdnPropsToUse}
                fullHeight={fullHeight}
                height={height}
                id={classNameType}
              />
            ) : (
              <CdnImage
                alt={galleryImages?.[0]?.alt}
                uri={galleryImages?.[0]?.url}
                {...cdnPropsToUse}
                {...backgroundOptions}
                {...backgroundClassNameProps}
              />
            )}
          </chakra.div>
        </Box>
        {innerBlocks && innerBlocks.length > 0 && (
          <Box
            order={{ base: 1, lg: propValue(1, 1, 0) }}
            w={{ base: '100%', lg: propValue('100%', '50%') }}
            position={{
              base: 'relative',
              lg: propValue('absolute', 'relative'),
            }}
            zIndex={2}
            top={cornerProp}
            bottom={cornerProp}
            left={cornerProp}
            right={cornerProp}
            display={{ base: 'block', lg: 'flex' }}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              className="wpg-b_hero-gallery__content"
              {...childProps}
              py={{ base: 6, lg: propValue(0, 0) }}
              px={{
                base: propValue(0, 4),
                sm: propValue(0, 6),
                md: propValue(0, 8),
                lg: propValue(0, 6),
              }}
            >
              {innerBlocks.map((block, i) => (
                <WPGBlock key={i} block={block} childProps={childProps} />
              ))}
            </Box>
          </Box>
        )}
      </Flex>
    </Block>
  )
}

export default withResponsive(HeroGalleryBlock)
