import { navigate } from 'gatsby';
import get from 'lodash/get';
import { trimFirstAndLastSlash } from '@rentivo/gatsby-core/src/utils/strings';

export const redirectToNewLocale = (oldLang, newLang, defaultLang, translations, uri, isDynamic = false) => {

  const uriParts = uri.split('/');

  // First, let's check if there's a translation for us
  const translationUri = get(translations, `${newLang}`, false);
  if(translationUri) {
    //console.log('navigating to ' + translationUri);
    return navigate(translationUri);
  } else {

    // Handle just /
    if(uri === '/' && newLang !== defaultLang) {
      return navigate(`${uri}/${newLang}/`);
    }

    // Handle non-default -> default
    if(newLang === defaultLang) {
      const newUriParts = uriParts.filter(p => p && p !== oldLang); // Remove old lang (if there).
      const newUri = '/' + newUriParts.join('/') + '/';
      return navigate(newUri);
    }

    if(!uriParts.includes(newLang)) {
      let newUriParts = uriParts.filter(p => p && p !== oldLang).map(p => trimFirstAndLastSlash(p)); // Remove old lang (if there).
      newUriParts = [trimFirstAndLastSlash(newLang), ...newUriParts]; // Add in new lang
      const newUri = '/' + newUriParts.join('/') + '/'; // Put them together
      return navigate(newUri);

      // TODO: Have not created the pages without translations for blog content...
      // TODO: Maybe we don't redirect to a newly created page... however, we just switch lang in redux, which changes intl messages & LocaleLink...
      // TODO: But then what happens if you share a link with someone??
    }

  }

  return null;
};
