import React, { useState, useEffect, useRef, useMemo } from 'react'
import get from 'lodash/get'
import Geosuggest from 'react-geosuggest'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/messages'
import {
  getSuggestLabel,
  onSuggestNoResults,
  renderSuggestItem,
  skipSuggest,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/utils'
import {
  StyledLocationFilterGooglePlacesGeoInput,
  StyledPretendLocationFilterGooglePlacesInput,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/GooglePlaces/styles'
import GoogleApiWrapper from '@rentivo/gatsby-core/src/components/generic/GoogleApiWrapper'
import { capitalize } from '@rentivo/gatsby-core/src/utils/strings'
import { Input, CloseButton, useStyleConfig, chakra } from '@chakra-ui/react'
import { shortSize } from '@rentivo/gatsby-core/src/utils/transformers'

const PretendLocationFilterInput = ({
  onBlur,
  size,
  onFocus,
  realInput,
  pretendInputValue,
  setPretendInputValue,
  onPretendFocus,
  placeholder,
}) => {
  const [showRealInput, setShowRealInput] = useState(false)

  const handleOnFocus = () => {
    if (showRealInput === false) setShowRealInput(true)
    onFocus()
  }

  return (
    <StyledPretendLocationFilterGooglePlacesInput className="pretend-input">
      <Input
        value={pretendInputValue}
        placeholder={placeholder}
        onChange={e => setPretendInputValue(e.target.value)}
        onFocus={handleOnFocus}
        onBlur={onBlur}
        size={shortSize(size)}
      />
      {showRealInput && realInput}
    </StyledPretendLocationFilterGooglePlacesInput>
  )
}

const GooglePlaces = ({
  className,
  size,
  runQuery,
  setFilterFrom,
  title,
  setFilter,
  filter,
  filterOptions,
  setMapMoved,
  onUpdateSuggests,
  onSuggestSelect,
  onFocus,
  onBlur,
  googlePlaces = {},
  styleProps = {},
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const geoRef = useRef(null)
  const extraProps = {}
  const type = filterOptions.type
  let biasCountries = get(filterOptions, 'bias.countries', []).map(c =>
    c.toLowerCase()
  )
  biasCountries = biasCountries.length ? biasCountries : null
  let biasTypes = get(filterOptions, 'bias.types', [])
  biasTypes = biasTypes && biasTypes.length ? biasTypes : null
  const biasRadius = get(filterOptions, 'bias.radius', null)
  if (biasCountries) extraProps.country = biasCountries
  if (biasTypes) extraProps.types = biasTypes
  if (biasRadius) extraProps.radius = biasRadius

  const defaultValue = get(filterOptions, 'defaultValue', null)
  const value = get(filter, 'value', defaultValue)
  const description = get(value, '0', null)
  const placeholder = formatMessage(messages.placeholder)
  const [pretendInputValue, setPretendInputValue] = useState(description)
  const [isRealInputMounted, setIsRealInputMounted] = useState(false)

  const onClear = () => {
    geoRef.current.clear()
    setFilter(null, runQuery, setFilterFrom)
  }

  const onSuggestSelectFunc = result => {
    if (result && result.description) {
      const viewport = result.gmaps.geometry.viewport
      //const bounds = getBounds(viewport.getNorthEast().lat(), viewport.getNorthEast().lng(), viewport.getSouthWest().lat(), viewport.getSouthWest().lng());
      const suggestionLabel = result.description

      //

      setFilter(
        [
          suggestionLabel,
          [
            [viewport.getSouthWest().lng(), viewport.getSouthWest().lat()],
            [viewport.getNorthEast().lng(), viewport.getNorthEast().lat()],
          ],
        ],
        runQuery,
        setFilterFrom
      )
      onSuggestSelect(result)
    }
  }

  useEffect(() => {
    setPretendInputValue(description)

    if (geoRef.current && !description) {
      geoRef.current.clear()
    }
  }, [description])

  useEffect(() => {
    if (!isRealInputMounted && geoRef.current) {
      geoRef.current.focus()
      setIsRealInputMounted(true)
    }
  }, [geoRef.current])

  const inputStyles = useStyleConfig('Input', { size: shortSize(size) })
  const StyledLocationFilterGooglePlaces = useMemo(
    () =>
      chakra('div', {
        baseStyle: {
          '.geosuggest': {
            position: 'relative',
            width: '100%',
            textAlign: 'left',
            '.ant-input': {
              borderRadius: 'md',
            },
            '&__input': {
              ...inputStyles.field,
              bg: 'white',
            },
            '&__suggests': {
              borderRadius: 'md',
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              maxH: '25em',
              p: 0,
              mt: 1,
              bg: 'white',
              overflowX: 'hidden',
              overflowY: 'auto',
              listStyle: 'none',
              zIndex: 20,
              transition: 'max-height 0.2s ease-in-out',
              boxShadow: 'lg',
              color: 'text',
              fontSize: 'md',
              '&--hidden': {
                maxH: 0,
                overflow: 'hidden',
                borderWidth: 0,
              },
            },
            '&__item': {
              px: 3,
              py: 2,
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'text',
              fontSize: 'md',
              span: {
                color: 'text',
                fontSize: 'md',
              },
              '&:hover, &:active, &:focus': {
                bg: 'gray.200',
              },
              '&__matched-text': {
                fontWeight: 'semibold',
              },
            },
          },
        },
      }),
    [inputStyles]
  )

  return (
    <StyledLocationFilterGooglePlaces
      size={size}
      className={className}
      {...googlePlaces}
      {...styleProps}
    >
      {title !== undefined ? (
        title
      ) : (
        <label>
          <FormattedMessage {...messages.title} />
        </label>
      )}

      <PretendLocationFilterInput
        pretendInputValue={capitalize(pretendInputValue)}
        setPretendInputValue={setPretendInputValue}
        placeholder={placeholder}
        onFocus={onFocus}
        size={size}
        onBlur={onBlur}
        realInput={
          <GoogleApiWrapper>
            <StyledLocationFilterGooglePlacesGeoInput className="geo-input">
              <Geosuggest
                ref={el => (geoRef.current = el)}
                placeholder={placeholder}
                initialValue={capitalize(pretendInputValue)}
                getSuggestLabel={getSuggestLabel}
                skipSuggest={skipSuggest}
                renderSuggestItem={renderSuggestItem}
                onFocus={onFocus}
                onBlur={onBlur}
                onUpdateSuggests={onUpdateSuggests}
                onSuggestNoResults={onSuggestNoResults}
                onSuggestSelect={onSuggestSelectFunc}
                {...extraProps}
              />
              {description && (
                <CloseButton
                  onClick={onClear}
                  className="cancel-btn"
                  size={size === 'lg' ? 'md' : 'sm'}
                />
              )}
            </StyledLocationFilterGooglePlacesGeoInput>
          </GoogleApiWrapper>
        }
      />
    </StyledLocationFilterGooglePlaces>
  )
}

export default GooglePlaces
