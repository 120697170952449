import React from 'react';
import PropTypes from 'prop-types';
import GoogleApiWrapper from '@rentivo/gatsby-core/src/components/generic/GoogleApiWrapper/component';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeSelectGoogleApiKeyConfig, makeSelectLoadGoogle } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  makeSelectGoogleApiKeyConfig(),
  makeSelectLoadGoogle(),
  (googleApiKey, loadGoogle) => ({
    googleApiKey,
    loadGoogle
  })
);

const enhance = compose(
  connect(mapStateToProps)
);

GoogleApiWrapper.propTypes = {
  loading: PropTypes.element,
  onLoad: () => null
};

GoogleApiWrapper.defaultProps = {
  loading: null,
  onLoad: () => null
};

export default enhance(GoogleApiWrapper);


