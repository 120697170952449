export const getIdByEvaluation = (options, evaluation, id = null) => {
  for(const option of options) {
    if(option.value && option.value.id) {
      if(evaluation(option)) {
        id = option.value.id;
        break;
      } else if (option.children && option.children.length) {
        id = getIdByEvaluation(option.children, evaluation, id);
      }
    }
  }
  return id;
};

export const getMenuTreeById = (options, id, tree = []) => {
  for(const option of options) {
    if(option.value && option.value.id) {
      tree = [...tree, id]; // Temp add it...
      if(option.value.id === id) {
        break;
      } else if (option.children && option.children.length) {
        tree.pop();
        tree = getMenuTreeById(option.children, id, [...tree, option.value.id]);
        if(tree.includes(id)) break;
      } else {
        // Pop it
        tree.pop();
      }
    }
  }
  return tree;
};

export const getInitialMenusById = (options, id, menus= []) => {
  const tree = getMenuTreeById(options, id);
  tree.forEach(id => {
    options.forEach(option => {
      if(option.value.id === id && option.children && option.children.length) {
        menus.push({
          ...option,
          name: option.label,
          options: option.children
        })
      }
    });
  });

  return menus;
};

export const getInitialValueFromMenus = (menus, id) => {
  let value = null;
  const lastMenuItem = menus[menus.length - 1];
  if(lastMenuItem.options && lastMenuItem.options.length) {
    lastMenuItem.options.forEach(option => {
      if(option.value.id === id) {
        value = option.value;
      }
    });
  }
  return value;
};

export const findRelatedOption = (options, id) => {
  if(!id) return null;
  for (let i = 0; i < options.length; i++) {
    if (options[i]?.value?.id === id) {
      return options[i];
    } else if(options[i].options && options[i].options.length) {
      return findRelatedOption(options[i].children, id);
    }
  }
  return null;
};

export const injectNewOptionsToMenus = (menus, options) => {
  for (let i = 0; i < menus.length; i++) {
    const menuId = menus[i]?.value?.id;
    const relatedOption = findRelatedOption(options, menuId);
    if(!menuId) continue;

    if(!relatedOption) continue;

    menus[i].options = (relatedOption.children && relatedOption.children.length) ? sortOptions(relatedOption.children) : sortOptions(menus[i].options);
  }

  return menus;
};

export const countTotalChildren = (options) => {
  let count = 0;
  for (let i = 0; i < options.length; i++) {
    count = count + 1;
    if(options[i].children && options[i].children.length) {
      count = count + countTotalChildren(options[i].children);
    }

    if(options[i].options && options[i].options.length) {
      count = count + countTotalChildren(options[i].options);
    }
  }
  return count;
};

export const sortOptions = (options) => {
  return options.sort(function(a, b) {
    return a?.value?.order.localeCompare(b?.value?.order, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
};
