import { defineMessages } from 'react-intl';

export const scope = 'components.ui.PopoverButton';

export default defineMessages({
  saveAndCloseButtonLabel: {
    id: `${scope}.saveAndCloseButton.label`,
    defaultMessage: 'Save & close'
  },
  okButtonLabel: {
    id: `${scope}.okButton.label`,
    defaultMessage: 'Ok'
  },
  closeButtonLabel: {
    id: `${scope}.closeButton.label`,
    defaultMessage: 'Cancel'
  }
});
