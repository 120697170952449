import get from 'lodash/get';
import turfBbox from '@turf/bbox';
import { lineString, point } from '@turf/helpers';
import turfCentroid from '@turf/centroid';
import turfDistance from '@turf/distance';
import { separateNumberFromString } from '@rentivo/gatsby-core/src/utils/numbers';

export const getBounds = (neLat, neLng, swLat, swLng, buffer = null) => {

  let bufferAmount = 0;
  let bufferBy = 'miles';

  if(buffer) {
    const bufferObj = separateNumberFromString(buffer);
    bufferAmount = bufferObj.number;
    bufferBy = bufferObj.string;

    const line = lineString([[parseFloat(swLng), parseFloat(swLat)], [parseFloat(neLng), parseFloat(neLat)]]);
    const centroid = turfCentroid(line);
    const center = get(centroid, 'geometry.coordinates', null);
    const corner = point([swLng, swLat]);
    const distance = turfDistance(centroid, corner, {units: bufferBy});
    let bufferToReturn = null;

    if(distance && center) {
      bufferToReturn = {
        distance: parseFloat(distance + bufferAmount).toFixed(2),
        center,
        units: bufferBy
      };
    }

    return bufferToReturn;
  }

  const minLng = Math.min(neLng, swLng) - bufferAmount;
  const minLat = Math.min(neLat, swLat) - bufferAmount;
  const maxLng = Math.max(neLng, swLng) + bufferAmount;
  const maxLat = Math.max(neLat, swLat) + bufferAmount;

  return [minLng, minLat, maxLng, maxLat];
};

export const getFuzzyLatLng = (lat, lng, radiusMeters = 50) => {
  const sideOfSquareInCircle = Math.sqrt(radiusMeters * radiusMeters * 2);

  const earth = 6378.137,  //radius of the earth in kilometer
    pi = Math.PI,
    cos = Math.cos,
    m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

  const negativeMeters = -Math.abs(sideOfSquareInCircle / 2);
  const positiveMeters = Math.abs(sideOfSquareInCircle / 2);

  const swLat = lat + (negativeMeters * m);
  const swLng = lng + (negativeMeters * m) / cos(lat * (pi / 180));
  const neLat = lat + (positiveMeters * m);
  const neLng = lng + (positiveMeters * m) / cos(lat * (pi / 180));

  const randomLat = swLat + (Math.random() * (neLat - swLat));
  const randomLng = swLng + (Math.random() * (neLng - swLng));

  return [randomLat, randomLng];
};

export const getBboxFromHits = (hits) => {
  const lineArr = hits.map(d => {
    if(d.location && d.location.lon !== undefined && d.location.lat !== undefined) {
      const lat = Number(d.location.lat);
      const lng = Number(d.location.lon);
      if(lat && lng) {
        const newItem = [lng, lat];
        if(newItem) {
          return newItem;
        }
      }
    }
  });

  if(lineArr.length > 0) {
    if(lineArr.length === 1) {
      lineArr.push(lineArr[0]);
    }

    const cleanedLineArr = lineArr.map((l) => {
      return (l) ? l : false;
    });

    const line = lineString(cleanedLineArr);
    return turfBbox(line);

  } else {
    return false;
  }
};

export const handleLongitudeOutOfBounds = (lon) => {
  while(lon < -180){
    lon +=360;
  }
  while (lon > 180){
    lon -= 360;
  }
  return lon;
};
