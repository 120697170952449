import { chakra } from '@chakra-ui/react';

export const StyledFilter = chakra('div', {
  baseStyle: {
    'label:not(.ant-checkbox-wrapper):not(.chakra-checkbox)': {
      display: 'block',
      fontWeight: 'semibold',
      mb:1
    }
  }
});

export const StyledFilterPopover = chakra('div', {
  baseStyle: {

  }
});
