import { chakra } from '@chakra-ui/react';

export const StyledCounter = chakra('div', {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minW: '124px',
    maxW: '200px',
    'span.count': {
      fontWeight: 'medium'
    }
  }
});

