import React, { useState } from 'react';
import { FILTER_NUMBER_TYPE_SELECT } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import Select from '@rentivo/gatsby-core/src/components/ui/SelectCounter';
import Counter from '@rentivo/gatsby-core/src/components/ui/Counter';
import { calculateGuests } from '@rentivo/gatsby-core/src/components/ui/GuestsPicker/utils';
import { useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/ui/GuestsPicker/messages';

const GuestsPickerComponent = ({options, adults, children, infants, pets, onChange}) => {
  const [internalTracker, setInternalTracker] = useState({adults, children, infants, pets});
  const { formatMessage } = useIntl();
  const { type, min, max, appendString, adults: adultsOptions, children: childrenOptions, infants: infantsOptions, pets: petsOptions } = options;
  const Component = (type === FILTER_NUMBER_TYPE_SELECT) ? Select : Counter;
  const addsToTotal = { adultsCount: adultsOptions.countsToTotal, childrenCount: childrenOptions.countsToTotal, infantsCount: infantsOptions.countsToTotal, petsCount: petsOptions.countsToTotal };
  const items = [];
  if(adultsOptions.active) items.push({ id: 'adults', value: adults, label: (adultsOptions.label) ? adultsOptions.label : formatMessage(messages.adultsLabel), ...adultsOptions });
  if(childrenOptions.active) items.push({ id: 'children', value: children, label: (childrenOptions.label) ? childrenOptions.label : formatMessage(messages.childrenLabel), ...childrenOptions });
  if(infantsOptions.active) items.push({ id: 'infants', value: infants, label: (infantsOptions.label) ? infantsOptions.label : formatMessage(messages.infantsLabel), ...infantsOptions });
  if(petsOptions.active) items.push({ id: 'pets', value: pets, label: (petsOptions.label) ? petsOptions.label : formatMessage(messages.petsLabel), ...petsOptions });

  // Calculate maxed out
  const maxedOut = (calculateGuests(internalTracker, addsToTotal).guests >= max);

  return (
    <>
      {items.map((item, i) => (
        <div className="guests-picker-item" key={i}>
          <label>{item.label}</label>
          <Component
            value={item.value}
            min={item.min && item.min > min ? item.min : min}
            max={item.max && item.max > max ? item.max : max}
            maxedOut={maxedOut && options[item.id].countsToTotal}
            appendString={appendString}
            onChange={(value) => {
              setInternalTracker({...internalTracker, [item.id]: value});
              const toCalculate = {adults, children, infants, pets};
              toCalculate[item.id] = value;
              onChange(calculateGuests(toCalculate, addsToTotal));
            }}
          />
        </div>
      ))}
    </>
  );
};

export default GuestsPickerComponent;
