export const cdnDefaultProps = {
  width: 1024,
  height: undefined,
  aspect: undefined,
  expandAspectChild: true,
  focus: 'center',
  transformation: null,
  alt: '',
  quality: 90,
  atMax: false,
  atMin: false,
  className: '',
  fallback: null,
  useReactImage: false,
  useGatsbyImage: false,
  useProgressiveImage: false,
  useProgressiveImageAspect: false,
  critical: false,
  verticalOffset: 50,
  linkTarget: '_self',
  fadeIn: true,
  ogImage: false,
  twitterImage: false,
  durationFadeIn: 500,
  style: {},
  styleProps: {},
  defaultImage: 'default_eqs33i_SyuOwmusl.jpg',
  useDefaultImage: false
};
