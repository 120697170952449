import { trim } from 'lodash';
import { capitalize } from '@rentivo/gatsby-core/src/utils/strings';
import { LISTING_TYPE_UNSPECIFIED } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import messages, { scope } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/messages';

export const getFriendlyLycanName = (FEATURE_IN_CAPS, onlyEndItem = false, textTransform = 'capitalize') => {
  if(!FEATURE_IN_CAPS) return '';
  if(FEATURE_IN_CAPS === LISTING_TYPE_UNSPECIFIED) return null;
  let friendly = '';
  if(onlyEndItem) {
    const featureItems = FEATURE_IN_CAPS.split('_');
    friendly = (featureItems[featureItems.length - 1] !== undefined) ? featureItems[featureItems.length - 1].toLowerCase() : '';
  } else {
    friendly = FEATURE_IN_CAPS.replace(/_/g, ' ').toLowerCase();
  }

  if(textTransform === 'capitalize') {
    friendly = capitalize(friendly);
  }

  return trim(friendly);
};

export const getMessageString = (message, formatMessage, useFriendlyName = true, translations = {}, lang = 'en', fallback) => {
  if(messages[message]) {
    return formatMessage(messages[message]);
  }

  if(typeof translations === 'object' && translations && translations[lang] && translations[lang][`${scope}.${message}`]) {
    return translations[lang][`${scope}.${message}`];
  }

  if(fallback !== undefined) {
    return fallback;
  }

  if(useFriendlyName) {
    return getFriendlyLycanName(message);
  }

  return message;
};
