import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { stripNonStyles, wpgFontSizeToChakraFontSize } from '@rentivo/gatsby-core/src/components/wpg/utils';

const ParagraphBlock = (props) => {
  const { attrs } = props;
  const {
    className,
    anchor = '',
    attrs: {
      align = 'left',
      content = '',
      dropCap = false
    },
    childProps
  } = props;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_paragraph');
  return (
    <Text
      id={anchor}
      variant="writing"
      textAlign={align}
      fontSize={wpgFontSizeToChakraFontSize(attrs)}
      mb={5}
      {...stripNonStyles(childProps)}
      {...classNameProps}
    >
      <RenderHtml html={content}/>
    </Text>
  );
};

export default ParagraphBlock;
