import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { fetchDefaultData } from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';
import {
  makeSelectSearchCollectionInfoFilters,
  makeSelectSearchFirstLoad,
  makeSelectSearchHits,
  makeSelectSearchLoadedDefaultFilters, selectSearchDataAndHasQueryFromProps, selectSearchMapOpen
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import {
  makeSelectSearchMapOnMoveDefaultConfig,
  makeSelectSearchResultsPerPageConfig,
  makeSelectSearchResultsTotalHitsConfig,
  selectSearchMapIsOpenOnLoadConfig,
  selectSearchQueryConfig,
  selectSearchQueryFunctionsConfig,
  selectSearchResultsIncludeDiscountsConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  withPageContext
} from '@rentivo/gatsby-core/src/context/PageContext';
import { SearchProviderContext } from '@rentivo/gatsby-core/src/context/SearchProviderContext';
import defaultQueryMap from '@rentivo/gatsby-core/src/containers/SearchProvider/maps/defaultQueryMap';
import defaultTagValueMap from '@rentivo/gatsby-core/src/containers/SearchProvider/maps/defaultTagValueMap';

class SearchProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchDefaultData, fetchOnLoad, ...rest } = this.props;
    if(fetchOnLoad === undefined || fetchOnLoad !== false) {
      fetchDefaultData(rest);
    }
  }

  render() {
    const { children, defaultFilterQueryMap, defaultFilterTagValueMap } = this.props;
    const queryMap = { ...defaultQueryMap, ...defaultFilterQueryMap };
    const tagValueMap = { ...defaultTagValueMap, ...defaultFilterTagValueMap };
    
    return (
      <SearchProviderContext.Provider value={{queryMap, tagValueMap}}>
        {children}
      </SearchProviderContext.Provider>
    );
  }
}

const mapStateToProps = createSelector(
  selectSearchQueryConfig,
  selectSearchQueryFunctionsConfig,
  selectSearchResultsIncludeDiscountsConfig,
  makeSelectSearchFirstLoad(),
  makeSelectSearchLoadedDefaultFilters(),
  makeSelectSearchResultsTotalHitsConfig(),
  makeSelectSearchResultsPerPageConfig(),
  makeSelectSearchMapOnMoveDefaultConfig(),
  makeSelectSearchHits(),
  makeSelectSearchCollectionInfoFilters(),
  selectSearchMapIsOpenOnLoadConfig,
  selectSearchMapOpen,
  selectSearchDataAndHasQueryFromProps,
  (queryConfig, functionsConfig, includeDiscounts, firstLoad, loadedDefaultFilters, totalHits, perPage, searchOnMapMove, hits, collectionFilters, mapOpenConfig, mapOpen, { hasUrlSearchQuery }) => ({
    queryConfig,
    functionsConfig,
    includeDiscounts,
    firstLoad,
    loadedDefaultFilters,
    totalHits,
    perPage,
    searchOnMapMove,
    hits,
    collectionFilters,
    hasUrlSearchQuery,
    mapOpen: (mapOpen) ? mapOpen : (mapOpenConfig)
  })
);

const mapDispatchToProps = dispatch => ({
  fetchDefaultData: (options) => dispatch(fetchDefaultData(options)),
});

const enhance = compose(
  withPageContext(),
  connect(mapStateToProps, mapDispatchToProps)
);

SearchProvider.defaultProps = {
  filterOptions: {},
  filterLabels: {},
  defaultFilterQueryMap: {},
  defaultFilterTagValueMap: {},
  totalHits: 10000,
  perPage: 12
};

export default enhance(SearchProvider);
