import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  StyledNestedSelector,
  StyledNestedSelectorMenu,
  StyledNestedSelectorMenuItem,
  StyledNestedSelectorMenuItemArrow,
  StyledNestedSelectorMenuItemLink,
  StyledNestedSelectorMenuScroll, StyledNestedSelectorSlider
} from '@rentivo/gatsby-core/src/components/ui/NestedCollectionSelector/styles';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {
  countTotalChildren,
  getInitialMenusById,
  getInitialValueFromMenus,
  injectNewOptionsToMenus, sortOptions
} from '@rentivo/gatsby-core/src/components/ui/NestedCollectionSelector/utils';
import { Spinner, Box } from '@chakra-ui/react';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
const NestedSelectorMenuItem = ({loading, menu, menuValue, label, title, value, children, nextMenu, prevMenu, currentValue, setCurrentValue, ...rest}) => {

  const isActive = (currentValue && value && currentValue.id === value.id);
  const handleOnClick = () => {
    if(value && !title) {
      const useValue = isEqual(value, currentValue) ? null : value;
      setCurrentValue(useValue);
    }
  };

  return (
    <StyledNestedSelectorMenuItem fontWeight={(title) ? 'semibold' : 'normal'} borderBottom={(title) ? '1px solid' : 'none'} borderColor={(title) ? 'gray.100' : 'transparent'}>
      {loading && <Spinner position="absolute" top="8px" right="5px" size="sm" color="gray.400" />}
      {prevMenu && <StyledNestedSelectorMenuItemArrow borderRight="1px solid" borderColor="gray.100" onClick={() => prevMenu()}><FontAwesomeIcon icon={faArrowLeft} /></StyledNestedSelectorMenuItemArrow>}
      <StyledNestedSelectorMenuItemLink
        attrs={{clickable: (value), active: isActive}}
        bg={isActive ? 'primary.200' : 'transparent'}
        fontWeight={isActive ? 'medium' : 'normal'}
        _hover={{
          bg: isActive ? 'primary.200' : (value) ? 'gray.200' : 'transparent',
          cursor: (value) ? 'pointer' : 'default'
        }}
        onClick={handleOnClick}
      >
        {label}
      </StyledNestedSelectorMenuItemLink>
      {children && children.length && nextMenu && (
        <StyledNestedSelectorMenuItemArrow borderLeft="1px solid" borderColor="gray.100" onClick={() => nextMenu(label, children, menuValue)}>
          <FontAwesomeIcon icon={faArrowRight} />
        </StyledNestedSelectorMenuItemArrow>
      )}
    </StyledNestedSelectorMenuItem>
  );
};

const NestedSelectorMenu = ({loading, menuItemsCount, menuIndex, prevMenu, menu, nextMenu, style, currentValue, setCurrentValue}) => {
  const { options, name } = menu;
  if(!options || !options.length) return null;
  return (
    <StyledNestedSelectorMenu style={style}>
      <NestedSelectorMenuItem loading={loading} value={null} title={true} label={name} prevMenu={(menuIndex > 1) ? prevMenu : null}/>
      <StyledNestedSelectorMenuScroll>
        {options.map(option => (
          <NestedSelectorMenuItem
            {...option}
            menuValue={option.value}
            key={option.value.id}
            nextMenu={nextMenu}
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
          />
        ))}
      </StyledNestedSelectorMenuScroll>
    </StyledNestedSelectorMenu>
  );
};

const NestedSelectorComponent = forwardRef(({
  width,
  options,
  optionsCount,
  optionsLoading,
  title,
  onChange,
  onNext,
  onPrev,
  initialValueId,
  animationSpeed,
  style,
  ...rest
}, ref) => {

  //console.log(options, optionsCount);

  const prevOptionsCount = useRef(optionsCount);
  const initialValueIdRef = useRef(initialValueId);
  const firstMenu = {name: (title) ? title : 'Start here', options};
  let defaultMenus = [firstMenu];
  const initialMenusRef = useRef((initialValueId) ? getInitialMenusById(options, initialValueId, defaultMenus) : defaultMenus);
  const initialValueRef = useRef((initialValueId) ? getInitialValueFromMenus(initialMenusRef.current, initialValueId) : null);
  const [menus, setMenus] = useState(initialMenusRef.current);
  const [menuItemsCount, setMenuItemsCount] = useState(countTotalChildren(menus));
  const [currentValue, setInternalCurrentValue] = useState(initialValueRef.current);
  const [translateX, setTranslateX] = useState((menus.length * width) - width);

  useEffect(() => {
    if(initialValueId !== initialValueIdRef.current) {
      initialValueIdRef.current = initialValueId;
      if(!initialValueId && currentValue) {
        setInternalCurrentValue(null);
      }
    }
  }, [initialValueId]);

  useEffect(() => {
    if(prevOptionsCount.current !== optionsCount) {
      const newMenus = injectNewOptionsToMenus(menus, options);
      setMenuItemsCount(countTotalChildren(newMenus));
      setMenus(newMenus);
      prevOptionsCount.current = optionsCount;
    }
  }, [optionsCount, options]);

  const nextMenu = (name, options, value) => {
    const newMenus = [...menus, { name, options: sortOptions(options), value }];
    setMenus(newMenus);
    setTranslateX((newMenus.length * width) - width);
    onNext(name, options, value);
  };

  const prevMenu = () => {
    if(menus.length > 1) {
      menus.pop();
      setTranslateX((menus.length * width) - width);
      onPrev(menus.length);
      setTimeout(() => {
        setMenus([...menus]);
      }, animationSpeed);
    }
  };

  const setCurrentValue = (value) => {
    setInternalCurrentValue(value);
    onChange(value);
  };

  return (
    <StyledNestedSelector ref={ref} style={{width: width, ...style}}>
      {((!menus.length && optionsLoading) || menus[0] && !menus[0].options.length && optionsLoading) && <Box position="relative" minH="150px"><Loader/></Box>}
      <StyledNestedSelectorSlider attrs={{animationSpeed}} style={{width: width * menus.length, transform: `translate(-${translateX}px, 0)`}}>
        {menus.map((menu, i) =>
          <NestedSelectorMenu
            key={`nested-selector-${menu.name + i}`}
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
            style={{width}}
            menuIndex={menus.length}
            nextMenu={nextMenu}
            prevMenu={prevMenu}
            menu={menu}
            menuItemsCount={menuItemsCount}
            loading={optionsLoading}
          />
        )}
      </StyledNestedSelectorSlider>
    </StyledNestedSelector>
  );
});

export default NestedSelectorComponent;
