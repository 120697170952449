export const getSuggestLabel = (suggest) => {
  return suggest.description;
};

export const onSuggestNoResults = (userInput) => null;

export const onUpdateSuggests = (suggests, activeSuggest) => null;

export const renderSuggestItem = null;

export const skipSuggest = (suggest) => false;

export const getGeoshape = (bounds) => {
  if(bounds && Array.isArray(bounds) && bounds.length) {
    let valid = true;
    let shape = [];

    bounds[0].forEach((b) => {
      if(!b || b.length !== 2) {
        valid = false;
      } else {
        shape.push({"lat": b[1], "lon": b[0]});
      }
    });
    return (valid) ? shape : false;
  } else {
    return false;
  }
};

export const isBoundingBox = (bounds) => (bounds && bounds.length === 4);
