import { defineMessages } from 'react-intl';

export const scope = 'barcelona.templates.Blog';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Latest posts{pageNumber, plural, =0 {} other {, page {pageNumber}}}'
  },
  seoTitle: {
    id: `${scope}.seoTitle`,
    defaultMessage: 'Latest posts from the blog{pageNumber, plural, =0 {} other { - page {pageNumber}}}'
  },
  seoMetaDescription: {
    id: `${scope}.seoMetaDescription`,
    defaultMessage: 'We are proud to present our travel blog{pageNumber, plural, =0 {} other {, page {pageNumber}}}, packed with travel guides and useful information for your next holiday'
  }
});
