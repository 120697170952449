import React from 'react';
import { newlinesToBr } from '@rentivo/gatsby-core/src/utils/strings';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Block } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { Box, Heading, Text, Divider } from '@chakra-ui/react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { HEADING_LEVELS } from '@rentivo/gatsby-core/src/components/wpg/constants';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const SectionTitleBlock = ({className, attrs, childProps}) => {
  const {
    textAlign,
    subtitle,
    title,
    titleClass,
    subtitleClass,
    level = 2,
    divider = false,
    dividerClass
  } = attrs || {};

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_section-title');
  const { options: to, ...titleClassNameProps } = useClassNameProps(titleClass, 'wpg-b_section-title--heading');
  const { options: sto, ...subtitleClassNameProps } = useClassNameProps(subtitleClass, 'wpg-b_section-title--sub-heading');
  const { options: dvo, ...dividerClassNameProps } = useClassNameProps(dividerClass, 'wpg-b_section-title--divider');

  const headingAs = HEADING_LEVELS[level];

  return (
    <Block
      attrs={attrs}
      mb={8}
      {...childProps}
      {...classNameProps}
    >
      {(title || subtitle) && (
        <Box
          textAlign={textAlign}
        >
          {title && <Heading variant="writing" mb={(subtitle) ? 1 : 0} textAlign={textAlign} as={headingAs} {...stripNonStyles(childProps)} {...titleClassNameProps}><RenderHtml html={newlinesToBr(title)}/></Heading>}
          {subtitle && <Text color="textLight" variant="writing" mb={0} textAlign={textAlign} {...stripNonStyles(childProps)} {...subtitleClassNameProps}><RenderHtml html={newlinesToBr(subtitle)}/></Text>}
        </Box>
      )}

      {divider && <Divider orientation="horizontal" mb={8} mt={5} {...dividerClassNameProps} />}
    </Block>
  );
};

export default SectionTitleBlock;
