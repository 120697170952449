import React from 'react';
import { useIntl } from 'react-intl';
import LocaleLink from '@rentivo/gatsby-core/src/components/navigation/LocaleLink';
import { LOGO_TYPE_DARK, LOGO_TYPE_LIGHT } from '@rentivo/gatsby-core/src/components/generic/Logo/constants';
import messages from '@rentivo/gatsby-core/src/components/generic/Logo/messages';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import { chakra } from '@chakra-ui/react';

const LogoComponent = ({type, isMobile, text, useImage, imageWidth, imageHeight, defaultImage, imageDefault, imageDefaultMobile, imageLight, imageLightMobile, imageDark, imageDarkMobile}) => {

  //console.log({type, isMobile, text, useImage, imageWidth, imageHeight, defaultImage, imageDefault, imageDefaultMobile, imageLight, imageLightMobile, imageDark, imageDarkMobile});

  const { formatMessage } = useIntl();
  let imageUri = (isMobile) ? imageDefaultMobile : imageDefault;

  if(!imageUri) imageUri = defaultImage;

  switch (type) {
    case LOGO_TYPE_LIGHT:
      imageUri = (isMobile) ? imageLightMobile : imageLight;
      break;
    case LOGO_TYPE_DARK:
      imageUri = (isMobile) ? imageDarkMobile : imageDark;
      break;
  }

  return (
    <div className="logo">
        {useImage ? (
          <CdnImage critical={true} href={formatMessage(messages.url)} uri={imageUri} alt={text} width={imageWidth} height={imageHeight} aspect={false} className="logo"/>
        ) : (
          <LocaleLink to={formatMessage(messages.url)} className="logo" width={imageWidth}><span className="logo-text">{text}</span></LocaleLink>
        )}
    </div>
  );
};
export default LogoComponent;
