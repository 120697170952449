import PropTypes from 'prop-types';
import LocaleToggle from '@rentivo/gatsby-core/src/components/generic/LocaleToggle/component';
import {
  LOCALE_TOGGLE_SIZE_DEFAULT,
  LOCALE_TOGGLE_SIZE_LARGE,
  LOCALE_TOGGLE_SIZE_SMALL,
  LOCALE_TOGGLE_TYPE_BUTTON,
  LOCALE_TOGGLE_TYPE_TEXT
} from '@rentivo/gatsby-core/src/components/generic/LocaleToggle/constants';
import { MENU_THEME_LIGHT } from '@rentivo/gatsby-core/src/components/navigation/Menu/constants';

LocaleToggle.propTypes = {
  onToggle: PropTypes.func,
  values: PropTypes.array,
  value: PropTypes.string,
  messages: PropTypes.object,
  theme: PropTypes.string,
  applyLocaleIcons: PropTypes.bool,
  type: PropTypes.oneOf([LOCALE_TOGGLE_TYPE_BUTTON, LOCALE_TOGGLE_TYPE_TEXT]),
  size: PropTypes.oneOf([LOCALE_TOGGLE_SIZE_SMALL, LOCALE_TOGGLE_SIZE_DEFAULT, LOCALE_TOGGLE_SIZE_LARGE])
};

LocaleToggle.defaultProps = {
  applyLocaleIcons: true,
  messages: {},
  theme: MENU_THEME_LIGHT,
  type: LOCALE_TOGGLE_TYPE_BUTTON,
  size: LOCALE_TOGGLE_SIZE_DEFAULT
};

export default LocaleToggle;
