import React from 'react';
import { Box, Flex, useStyleConfig, Container } from '@chakra-ui/react';
import { useProps } from '@rentivo/gatsby-core/src/hooks/useProps';
import WPGBlocks from '@rentivo/gatsby-core/src/components/wpg/Blocks';

export const LEFT_LOGO = 'LEFT_LOGO';
export const CENTRAL_LOGO = 'CENTRAL_LOGO';

const defaultProps = {
  fluid: false,
  blocks: []
};

const TopBar = (p) => {
  const props = useProps(defaultProps, p);
  const { fluid, blocks } = props;
  const { outer, container } = useStyleConfig('TopBar', props);

  if(fluid) {
    container.maxW = 'container.full';
  }

  return (
    <Box
      position="relative"
      className="topbar"
      css={{
        '.wpg-blocks': {
          width: '100%'
        }
      }}
      {...outer}
    >
      <Container
        as={Flex}
        h={outer.height ? outer.height : 'auto'}
        align="center"
        className="topbar-container"
        {...container}
      >
        <WPGBlocks blocks={blocks} />
      </Container>
    </Box>
  );
};

export default TopBar;