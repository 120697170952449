import { defineMessages } from 'react-intl';

export const scope = 'containers.CurrencyToggle';

export default defineMessages({
  THB: {
    id: `${scope}.THB`,
    defaultMessage: 'THB (฿)',
  },
  GBP: {
    id: `${scope}.GBP`,
    defaultMessage: 'GBP (£)',
  },
  USD: {
    id: `${scope}.USD`,
    defaultMessage: 'USD ($)',
  },
  EUR: {
    id: `${scope}.EUR`,
    defaultMessage: 'EUR (€)',
  },
  CAD: {
    id: `${scope}.CAD`,
    defaultMessage: 'CAD ($)'
  }
});
