import { window } from 'browser-monads';

export const windowGlobal = typeof window !== 'undefined' && window;

export const scrollToRef = (ref, topOffset = 200, threshold = 200) => {
  const rect = ref.current.getBoundingClientRect();
  if(rect && rect.top) {
    const top = rect.top - topOffset;
    if(top > threshold) {
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};
