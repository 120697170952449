import PropTypes from 'prop-types';

export const SIZE_DEFAULT = 'md';
export const SIZE_SMALL = 'sm';
export const SIZE_LARGE = 'lg';

export const POS_LEFT = 'left';
export const POS_RIGHT = 'right';

export const BUTTON_TYPE_PRIMARY = 'primary';
export const BUTTON_TYPE_SECONDARY = 'secondary';
export const BUTTON_TYPE_DANGER = 'danger';
export const BUTTON_TYPE_DEFAULT = 'default';
export const BUTTON_TYPE_WHITE = 'white';

export const BUTTON_SHAPE_DEFAULT = 'default';
export const BUTTON_SHAPE_CIRCLE = 'circle';

export const PROP_TYPE_SIZE = PropTypes.oneOfType([SIZE_SMALL, SIZE_DEFAULT, SIZE_LARGE]);
export const PROP_TYPE_BUTTON_TYPE = PropTypes.oneOfType([BUTTON_TYPE_PRIMARY, BUTTON_TYPE_SECONDARY, BUTTON_TYPE_DANGER, BUTTON_TYPE_DEFAULT, BUTTON_TYPE_WHITE]);
export const PROP_TYPE_BUTTON_ICON_POS = PropTypes.oneOfType([POS_LEFT, POS_RIGHT]);
export const PROP_TYPE_BUTTON_SHAPE = PropTypes.oneOfType([BUTTON_SHAPE_DEFAULT, BUTTON_SHAPE_CIRCLE]);
