import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
  selectCurrentExchangeMap, selectCurrentCurrency
} from '@rentivo/gatsby-core/src/containers/CurrencyProvider/selectors';
import { changeCurrency } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/actions';
import { getCurrenciesFromExchangeMap } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/utils';
import CurrencyToggleComponent from '@rentivo/gatsby-core/src/containers/CurrencyProvider/components/CurrencyToggle/component';
import { selectSupportedCurrenciesConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  (state) => state,
  selectCurrentCurrency,
  selectCurrentExchangeMap,
  selectSupportedCurrenciesConfig,
  (state, currency, currencyExchangeMap, supportedCurrencies) => {
    return {
      currency,
      currenciesList: getCurrenciesFromExchangeMap(currency, supportedCurrencies, currencyExchangeMap)
    };
  }
);

const mapDispatchToProps = dispatch => ({
  changeCurrency: (currency) => dispatch(changeCurrency(currency))
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(CurrencyToggleComponent);

// TODO: Save the current currency in localStorage...
// TODO: Same for language?
