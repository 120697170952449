export const query = ({filterOptions, value}) => {
  if(!value) return null;

  return {
    "multi_match": {
      "query": value,
      "fields": [
        "name^2",
        "headline^2"
      ],
      "type": "best_fields",
      "operator": "and",
      "fuzziness": 0
    }
  }

};

export const defaultQuery = query;
