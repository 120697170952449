import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters.LosFilter';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Show full price'
  },
  tooltipOff: {
    id: `${scope}.tooltipOff`,
    defaultMessage: 'Turn on real time pricing & see the full property price for your dates & guests'
  },
  tooltipOn: {
    id: `${scope}.tooltipOn`,
    defaultMessage: 'Turn off real time pricing'
  },
  enabled: {
    id: `${scope}.enabled`,
    defaultMessage: 'Yes'
  },
  disabled: {
    id: `${scope}.disabled`,
    defaultMessage: 'No'
  }
});
