import { defineMessages } from 'react-intl';

export const SORT_NONE = 'SORT_NONE';
export const SORT_RELEVANCE_ASC = 'RELEVANCE_ASC';
export const SORT_NIGHTLY_PRICE_ASC = 'NIGHTLY_PRICE_ASC';
export const SORT_NIGHTLY_PRICE_DESC = 'NIGHTLY_PRICE_DESC';
export const SORT_SLEEPS_ASC = 'SLEEPS_ASC';
export const SORT_SLEEPS_DESC = 'SLEEPS_DESC';
export const SORT_CREATED_AT_ASC = 'CREATED_AT_ASC';
export const SORT_CREATED_AT_DESC = 'CREATED_AT_DESC';

export const scope = 'containers.SearchProvider.results.SortResults';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Sort by'
  },
  [SORT_RELEVANCE_ASC]: {
    id: `${scope}.${SORT_RELEVANCE_ASC}`,
    defaultMessage: 'Relevance'
  },
  [SORT_NIGHTLY_PRICE_ASC]: {
    id: `${scope}.${SORT_NIGHTLY_PRICE_ASC}`,
    defaultMessage: 'Price (Low→High)'
  },
  [SORT_NIGHTLY_PRICE_DESC]: {
    id: `${scope}.${SORT_NIGHTLY_PRICE_DESC}`,
    defaultMessage: 'Price (High→Low)'
  },
  [SORT_SLEEPS_ASC]: {
    id: `${scope}.${SORT_SLEEPS_ASC}`,
    defaultMessage: 'Sleeps (Low→High)'
  },
  [SORT_SLEEPS_DESC]: {
    id: `${scope}.${SORT_SLEEPS_DESC}`,
    defaultMessage: 'Sleeps (High→Low)'
  },
  [SORT_CREATED_AT_DESC]: {
    id: `${scope}.${SORT_CREATED_AT_DESC}`,
    defaultMessage: 'Recently Added'
  },
  [SORT_CREATED_AT_ASC]: {
    id: `${scope}.${SORT_CREATED_AT_ASC}`,
    defaultMessage: 'Oldest'
  },
});
