import { debounce } from 'lodash'
import { selectSearchFiltersConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig'
import {
  INVALIDATE_DATA,
  RECEIVE_DATA,
  REMOVE_FILTER,
  REQUEST_DATA,
  SET_DEFAULT_FILTERS,
  SET_INITIAL_QUERY,
  SET_FILTER,
  SET_FILTER_LABELS,
  SET_QUERY,
  SET_INITIAL_AGGS,
  SET_INITIAL_SCRIPTS,
  SET_INITIAL_SORT,
  SET_INITIAL_FUNCTIONS,
  SET_SIZE,
  SET_INITIAL_SETTINGS,
  SET_SEARCH_ON_MAP_MOVE,
  SET_COLLECTION,
  SET_FIRST_LOAD,
  CLEAR_FILTERS,
  SET_MAP_OPEN,
  SEARCHBAR_SEARCH,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/constants'
import { getDefaultFiltersFromUrlAndCollection } from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/urlParams'
import { addIdToObjects } from '@rentivo/gatsby-core/src/utils/objects'
import {
  prepareInitialAggs,
  prepareInitialFunctions,
  prepareInitialObjects,
  prepareInitialQuery,
  prepareInitialSort,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/query'
import defaultQueryMap from '@rentivo/gatsby-core/src/containers/SearchProvider/maps/defaultQueryMap'
import defaultTagValueMap from '@rentivo/gatsby-core/src/containers/SearchProvider/maps/defaultTagValueMap'

export function invalidateData(error = null) {
  return {
    type: INVALIDATE_DATA,
    error,
  }
}

export function requestData(query) {
  return {
    type: REQUEST_DATA,
    query,
  }
}

export function receiveData(data) {
  return {
    type: RECEIVE_DATA,
    data,
  }
}

export function fetchDefaultData({
  queryConfig,
  functionsConfig,
  includeDiscounts,
  firstLoad,
  hits,
  mapOpen,
  collectionFilters,
  hasUrlSearchQuery,
  totalHits,
  perPage,
  filterOptions,
  filterLabels,
  defaultFilterQueryMap,
  defaultFilterTagValueMap,
  initialFilterQueryMap,
  searchOnMapMove,
}) {
  return async (dispatch, getState) => {
    if (filterOptions) filterOptions = addIdToObjects(filterOptions)

    const reduxState = getState()

    const filtersConfig = {
      ...selectSearchFiltersConfig(reduxState),
      ...filterOptions,
    }

    // Add initial query, which could include aggs & more (to run when no filters exist).
    // TODO: Clean this up into one function, we don't need to run so many loops...
    const initialQuery = prepareInitialQuery(
      filtersConfig,
      initialFilterQueryMap,
      reduxState
    )
    const initialAggs = prepareInitialObjects(
      'aggs',
      filtersConfig,
      initialFilterQueryMap,
      reduxState
    )
    //const defaultScripts = (includeDiscounts) ? { discounts: { script: { id: 'lycan_discounts_lookup' } } } : {};
    const initialScripts = prepareInitialObjects(
      'scripts',
      filtersConfig,
      initialFilterQueryMap,
      reduxState
    )
    const initialSort = prepareInitialSort(
      filtersConfig,
      initialFilterQueryMap,
      reduxState
    )
    const initialFunctions = prepareInitialFunctions(
      filtersConfig,
      initialFilterQueryMap,
      functionsConfig,
      reduxState
    )

    await dispatch(
      setInitialSettings({
        totalHits,
        perPage,
        initialQuery,
        initialAggs,
        initialScripts,
        initialSort,
        initialFunctions,
        searchOnMapMove,
        mapOpen,
      })
    )

    // Get all filters in URL and add all filters & filterLabels to redux...
    const queryMap = { ...defaultQueryMap, ...defaultFilterQueryMap } // Combine options from SearchProvider props
    const tagValueMap = { ...defaultTagValueMap, ...defaultFilterTagValueMap }

    const defaultFilters = getDefaultFiltersFromUrlAndCollection({
      reduxState,
      filtersConfig,
      queryMap,
      tagValueMap,
      collectionFilters,
    })

    await dispatch(setDefaultFilters(defaultFilters))
    await dispatch(setFilterLabels(filterLabels))

    //  || hits.length === 0

    if (!firstLoad || hasUrlSearchQuery || hits.length === 0)
      dispatch(startSetQueryDebounced())
    dispatch(setFirstLoad(true))
  }
}

export const startSetFilter = ({
  queryMap,
  tagValueMap,
  filterOptions,
  value,
  tagValue,
  query,
  runQuery,
  from,
}) => {
  return async (dispatch, getState) => {
    const reduxState = getState()

    await dispatch(
      setFilter({
        reduxState,
        queryMap,
        tagValueMap,
        filterOptions,
        value,
        tagValue,
        query,
        runQuery,
        from,
      })
    )

    if (runQuery) dispatch(startSetQueryDebounced())
  }
}

export const startRemoveFilter = ({ queryMap, tagValueMap, urlParam }) => {
  return async (dispatch, getState) => {
    const reduxState = getState()
    const filtersConfig = selectSearchFiltersConfig(reduxState)

    await dispatch(
      removeFilter({ queryMap, tagValueMap, urlParam, reduxState })
    )
    dispatch(startSetQueryDebounced())
  }
}

const startSetQueryFunction = debounce(
  dispatch => setTimeout(() => dispatch(setQuery()), 25),
  25
)
const startSetQueryDebounced = () => startSetQueryFunction

export function setFilter({
  reduxState,
  queryMap,
  tagValueMap,
  filterOptions,
  value,
  tagValue,
  query,
  runQuery = true,
  from = 'search',
  applyUrlParam = true,
}) {
  return {
    type: SET_FILTER,
    applyUrlParam,
    queryMap,
    tagValueMap,
    reduxState,
    runQuery,
    from,
    filter: {
      ...filterOptions,
      value,
      tagValue,
      query,
    },
  }
}

export function setFirstLoad(firstLoad) {
  return {
    type: SET_FIRST_LOAD,
    firstLoad,
  }
}

export function removeFilter({ queryMap, tagValueMap, urlParam, reduxState }) {
  return {
    type: REMOVE_FILTER,
    urlParam,
    reduxState,
    queryMap,
    tagValueMap,
  }
}

export function setFilterLabels(filterLabels) {
  return {
    type: SET_FILTER_LABELS,
    filterLabels,
  }
}

export function setDefaultFilters(filters) {
  return {
    type: SET_DEFAULT_FILTERS,
    filters,
  }
}

export function setQuery() {
  return {
    type: SET_QUERY,
  }
}

export function setSize(size) {
  return {
    type: SET_SIZE,
    size,
  }
}

/*
export function setFrom(from) {
  return {
    type: SET_SIZE,
    from
  };
}

export function setPage(page) {
  return {
    type: SET_PAGE,
    page
  };
}*/

export function setInitialSettings(settings) {
  return {
    type: SET_INITIAL_SETTINGS,
    settings,
  }
}

export function setInitialQuery(initialQuery) {
  return {
    type: SET_INITIAL_QUERY,
    initialQuery,
  }
}

export function setInitialAggs(initialAggs) {
  return {
    type: SET_INITIAL_AGGS,
    initialAggs,
  }
}

export function setInitialScripts(initialScripts) {
  return {
    type: SET_INITIAL_SCRIPTS,
    initialScripts,
  }
}

export function setInitialSort(initialSort) {
  return {
    type: SET_INITIAL_SORT,
    initialSort,
  }
}

export function setInitialFunctions(initialFunctions) {
  return {
    type: SET_INITIAL_FUNCTIONS,
    initialFunctions,
  }
}

export function setSearchOnMapMove(searchOnMapMove) {
  return {
    type: SET_SEARCH_ON_MAP_MOVE,
    searchOnMapMove,
  }
}

export function setCollection(collection) {
  return {
    type: SET_COLLECTION,
    collection,
  }
}

export function clearFilters() {
  return {
    type: CLEAR_FILTERS,
  }
}

export function searchbarSearch() {
  return {
    type: SEARCHBAR_SEARCH,
  }
}

export function setMapOpen(mapOpen = true) {
  return {
    type: SET_MAP_OPEN,
    mapOpen,
  }
}
