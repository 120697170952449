import React, { useState, useEffect, useCallback, useRef } from 'react';
import { window } from 'browser-monads';
import NestedCollectionSelector from '@rentivo/gatsby-core/src/components/ui/NestedCollectionSelector';
import get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/messages';
import {
  urlParamValueParser,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/urlParams';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import {
  useCollections
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/ElasticCollections/api';
import {
  injectNewOptions
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/ElasticCollections/utils';
import { COLLECTION_SELECTOR_DESKTOP_WIDTH } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/ElasticCollections/constants';
import { StyledFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/styles';
import { Text } from '@chakra-ui/react';
import useDeepCompareEffect from '@rentivo/gatsby-core/src/hooks/useDeepCompareEffect';
import { countTotalChildren } from '@rentivo/gatsby-core/src/components/ui/NestedCollectionSelector/utils';

const CollectionsComponent = ({button, className, size, scrollTo, isMobileOnly, runQuery: runQueryProps, setFilterFrom, title, setFilter, filter, filterOptions, setMapMoved, onUpdateSuggests, onSuggestSelect, onFocus, onBlur, styleProps = {}, ...rest}) => {
  const { formatMessage } = useIntl();
  const mountedRef = useRef(false);
  const widthOfNestedSelector = (isMobileOnly) ? window.innerWidth - 48 : COLLECTION_SELECTOR_DESKTOP_WIDTH;
  const prevParentId = useRef(null);
  const [parentId, setParentId] = useState(undefined);
  const [runQuery, setRunQuery] = useState(false);
  const [options, setOptions] = useState([]);
  const [waitingToLoad, setWaitingToLoad] = useState(true);
  const [optionsCount, setOptionsCount] = useState(countTotalChildren(options));
  const [nestedSelectorWidth] = useState(widthOfNestedSelector);
  const { data: collections, loading: isLoading } = useCollections(parentId, runQuery);
  const defaultValue = get(filterOptions, 'defaultValue', null);
  const value = get(filter, 'value', defaultValue);
  const placeholder = formatMessage(messages.placeholder);
  const currentValue = useRef(value);
  const [initialValueId, setInitialValueId] = useState(null);
  //const [initialValueId, setInitialValueId] = useState(value ? getCurrentCollectionID(options, value, filterOptions) : null);
  const containerRef = useRef(null);
  const loading = (collections === undefined && isLoading === true) ? false : (isLoading);

  useEffect(() => {
    if(currentValue.current !== value) {
      currentValue.current = value;

      // TODO: Check whether value exists in collection?
      //const valueIdInCollection = getCurrentCollectionID(options, value, filterOptions);
      //setInitialValueId(valueIdInCollection);
    }
  }, [value]);

  useDeepCompareEffect(() => {
    if(mountedRef.current === false && collections && collections.length) {
      setOptions(collections);
      mountedRef.current = true;
      setWaitingToLoad(false);
    }

    if(mountedRef.current && options.length && parentId && collections.length && parentId !== prevParentId) {
      prevParentId.current = parentId;
      const newOptions = injectNewOptions(options, parentId, collections);
      setOptions(newOptions);
      setOptionsCount(countTotalChildren(newOptions));
      setWaitingToLoad(false);
    }
  }, [collections]);

  const handleOnChange = useCallback((value) => {
    if(value && value.destination && value.destination.name && value.destination.boundingBox && value.destination.boundingBox.coordinates) {
      const val = urlParamValueParser(`${value.destination.name}~${value.destination.boundingBox.coordinates}`, filterOptions);
      setFilter(val, runQuery, setFilterFrom);
    } else if(value && value.filters) {
      const locationFilters = value.filters.filter(f => f.filterKey === 'location');
      if(locationFilters && locationFilters.length) {
        const locationFilter = locationFilters[0];
        const val = urlParamValueParser(locationFilter.filterValue, filterOptions);
        setFilter(val, runQuery, setFilterFrom);
      }
    } else {
      setFilter(null, runQuery, setFilterFrom);
    }
  }, [value, runQuery, setFilterFrom]);

  const handleOnNext = useCallback((name, options, nextMenuValue) => {
    if(nextMenuValue && nextMenuValue.databaseId) {
      setParentId(nextMenuValue.databaseId);
    }
  }, []);

  const handleOnPrev = useCallback((newMenuIndex) => {

  }, []);

  const handleMouseHover = useCallback(() => {
    if(parentId === undefined) {
      setParentId(null);
    }
    if(!runQuery) {
      setRunQuery(true);
    }
  }, [parentId, setParentId, runQuery, setRunQuery]);

  return (
    <StyledFilter ref={containerRef} className={className} {...styleProps}>
      {title !== undefined ? title : <Text as="label"><FormattedMessage {...messages.title}/></Text>}
      <PopoverButton
        onMouseOver={handleMouseHover}
        scrollTo={scrollTo}
        title={<FormattedMessage {...messages.title}/>}
        component={NestedCollectionSelector}
        isVisible={false}
        waitingToLoad={waitingToLoad}
        onVisibleChange={() => null}
        componentProps={{
          key: `collections-${options.length ? 1 : 0}`,
          optionsLoading: loading,
          optionsCount,
          width: nestedSelectorWidth,
          options,
          onChange: handleOnChange,
          onNext: handleOnNext,
          onPrev: handleOnPrev,
          title: placeholder,
          initialValueId
        }}
        buttonLabel={(value && value.length) ? value[0] : placeholder}
        button={{size, ...button}}
        popoverWidth={COLLECTION_SELECTOR_DESKTOP_WIDTH}
      />
    </StyledFilter>
  );
};

export default CollectionsComponent;
