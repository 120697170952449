import React from 'react';
import { useIntl } from 'react-intl';

export const TagComponent = ({value}) => {
  const { formatDate } = useIntl();
  if(!value) return null;
  value = value.map(v => formatDate(v, {month: 'short', day: '2-digit', timeZone: 'UTC'}));
  //return value.join(' → ');
  return value.join('/');
};

export const tagValue = ({value}) => {
  return <TagComponent value={value}/>;
};

export const defaultTagValue = tagValue;
