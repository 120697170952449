import React from 'react';
import LycanFormattedMessage from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';
import { FIELD_LYCAN_MESSAGE_MAP } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/TagsFlagsFilter/constants';

export const tagValue = ({value}) => {
  value = (Array.isArray(value)) ? value : [value]; // make it an array
  return (
    <span>
      {value.map((v, i) => <><LycanFormattedMessage key={v} message={FIELD_LYCAN_MESSAGE_MAP[v] ? FIELD_LYCAN_MESSAGE_MAP[v] : v}/>{value.length !== i + 1 && (<>, </>)}</>)}
    </span>
  );
};

export const defaultTagValue = tagValue;
