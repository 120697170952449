import React from 'react';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton/component';
import messages from '@rentivo/gatsby-core/src/components/ui/PopoverButton/messages';
import { FormattedMessage } from 'react-intl';
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext';

export const defaultButtonProps = {
  block: false,
  type: 'default',
  size: 'md',
  style: {},
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display:'inline-block',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  forceButtonIcon: false,
  iconStyle: { fontSize: 13, position: 'absolute', right: 10, top: '50%', marginTop: '-6.5px' }
};

/*
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    text-align: left;
    padding-left: 16px;
    padding-right: 32px;
 */

PopoverButton.defaultProps = {
  component: null,
  componentProps: {},
  title: null,
  buttonLabel: 'Button',
  visible: undefined,
  onVisibleChange: undefined,
  onMouseOver: () => null,
  scrollTo: false,
  scrollOffset: 200,
  popoverWidth: 280,
  popoverHeight: undefined,
  popoverShowTitle: true,
  scrollThreshold: 200,
  useModal: false,
  actionLoading: false,
  showCloseButton: true,
  waitingToLoad: false,
  showOkButton: false,
  closeButtonLabel: <FormattedMessage {...messages.saveAndCloseButtonLabel}/>,
  okButtonLabel: <FormattedMessage {...messages.okButtonLabel}/>,
  onClose: () => null,
  onOk: () => null
};

export default withResponsive(PopoverButton);
