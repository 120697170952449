import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export const addCurrentYearToDate = (startDate, endDate, type = 'startDate') => {

  const now = dayjs();
  const currentYear = now.format('YYYY');

  const startDateParts = startDate.split('-');
  const endDateParts = endDate.split('-');

  const isStartDateFull = (startDateParts.length === 4);
  const isEndDateFull = (endDateParts.length === 4);


  let startDateDa = (!isStartDateFull) ? dayjs(`${currentYear}-${startDate}`) : dayjs(startDate);
  let endDateDa = (!isEndDateFull) ? dayjs(`${currentYear}-${endDate}`) : dayjs(endDate);

  // Make sure end date is always after start date, if their years cross over.
  if(startDateDa.isSameOrAfter(endDateDa)) {
    endDateDa = endDateDa.add(1, 'year');
  }

  if(type === 'startDate') {
    return (now.isSameOrAfter(endDateDa)) ? startDateDa.add(1, 'year').format('YYYY-MM-DD') : startDateDa.format('YYYY-MM-DD');
  }

  if(type === 'endDate') {
    return (now.isSameOrAfter(endDateDa)) ? endDateDa.add(1, 'year').format('YYYY-MM-DD') : endDateDa.format('YYYY-MM-DD');
  }

  return (type === 'startDate') ? startDateDa.format('YYYY-MM-DD') : endDateDa.format('YYYY-MM-DD');
};
