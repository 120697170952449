import React, { useRef, useState, useEffect } from 'react';
import { StyledCounter } from '@rentivo/gatsby-core/src/components/ui/Counter/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { IconButton } from '@chakra-ui/react';

const CounterComponent = ({max, min, value, appendString, prependString, maxedOut, applyWaitInMs, onChange}) => {
  const mounted = useRef();
  const [innerValue, setInnerValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if(prevValue !== value && innerValue !== value || value === 0) {
        setInnerValue(value);
        setPrevValue(value);
      }
    }
  }, [value]);

  const handleIncrement = () => {
    const newCount = innerValue + 1;
    setInnerValue(newCount);
    onChange(newCount);
  };

  const handleDecrement = () => {
    const newCount = innerValue - 1;
    setInnerValue(newCount);
    onChange(newCount);
  };

  return (
    <StyledCounter className="counter">
      <IconButton aria-label="Decrease by 1" icon={<FontAwesomeIcon icon={faMinus}/>} onClick={handleDecrement} isDisabled={innerValue <= min}/>
      <span className="count">
        {prependString && <span className="pre-count">{prependString}</span>}
        {innerValue}
        {appendString && <span className="post-count">{appendString}</span>}
      </span>
      <IconButton aria-label="Increase by 1" icon={<FontAwesomeIcon icon={faPlus}/>} onClick={handleIncrement} isDisabled={innerValue >= max || maxedOut} />
    </StyledCounter>
  );
};

export default CounterComponent;
