import React from 'react';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import { Block, WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { Flex, Box, SimpleGrid } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

const MediaTextBlock = ({innerBlocks, attrs, className}) => {
  let {
    href = '',
    imageFill = false,
    isStackedOnMobile = true,
    mediaAlt,
    mediaPosition = 'left',
    mediaWidth = 50,
    linkTarget = '_self',
    mediaUrl
  } = attrs;

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_media-text');

  return (
    <Block attrs={attrs} {...classNameProps}>

      <Flex
        direction={{base: (isStackedOnMobile) ? 'column' : 'row', lg: 'row'}}
      >
        <Box
          w={{base: (isStackedOnMobile) ? '100%' : `${mediaWidth}%`, lg: `${mediaWidth}%`}}
          order={{base: 0, lg: mediaPosition === 'left' ? 0 : 1}}
          layerStyle={imageFill ? 'coverImage' : undefined}
          minH={{base: (imageFill) ? '250px' : 'none', lg: (imageFill) ? '250px' : 'none'}}
        >
          <CdnImage href={href} linkTarget={linkTarget} uri={mediaUrl} alt={mediaAlt} quality={100}/>
        </Box>

        <Flex
          position="relative"
          display={{base: 'block', lg: 'flex'}}
          align="center"
          w={{base: (isStackedOnMobile) ? '100%' : `${100 - mediaWidth}%`, lg: `${100 - mediaWidth}%`}}
          order={{base: 1, lg: mediaPosition === 'left' ? 1 : 0}}
        >
          <Box w="100%" pt={{base: 6, lg: 6}} pb={{base: 6, lg: 6}} pl={{base: 6, lg: mediaPosition === 'left' ? 12 : 6}} pr={{base: 6, lg: mediaPosition === 'left' ? 6 : 12}}>
            {innerBlocks.map((block, i) => <WPGBlock key={i} block={block}/>)}
          </Box>
        </Flex>
      </Flex>
    </Block>
  );

};

export default MediaTextBlock;
