import { chakra } from '@chakra-ui/react';

export const StyledFlagIcon = chakra('span', {
  baseStyle: {
    w: '16px',
    h: '12px',
    position: 'relative',
    display: 'inline-block',
    bg: 'gray.200',
    overflow: 'hidden',
    'img': {
      position: 'absolute',
      w: '16px'
    }
  }
});
