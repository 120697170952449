import React from 'react';
import Menu from '@rentivo/gatsby-core/src/components/navigation/Menu/component';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeSelectSiteLogoTextConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  MENU_MODE_HORIZONTAL,
  MENU_THEME_LIGHT,
  SUB_MENU_TRIGGER_CLICK
} from '@rentivo/gatsby-core/src/components/navigation/Menu/constants';

const mapStateToProps = createSelector(
  makeSelectSiteLogoTextConfig(),
  (text) => ({
    text
  })
);

const enhance = compose(
  connect(mapStateToProps)
);

Menu.defaultProps = {
  menuItems: [],
  theme: MENU_THEME_LIGHT,
  mode: MENU_MODE_HORIZONTAL,
  subMenuTrigger: SUB_MENU_TRIGGER_CLICK
};

Menu.propTypes = {

};

export default enhance(Menu);


