import InView from '@rentivo/gatsby-core/src/components/generic/InView/component';

InView.defaultProps = {
  verticalOffset: 300,
  triggerOnce: true,
  initialInView: false,
  el: 'div',
  className: '',
  fallback: null
};

export default InView;
