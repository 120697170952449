import React from 'react';
import { useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityInFilter/messages';
import { getFriendlyLycanName } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';
import { addCurrentYearToDate } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityInFilter/utils';

export const TagComponent = ({value}) => {
  const { formatDate, formatMessage } = useIntl();
  if(!value) return null;

  let dates = value.filter((v, i) => i < 2);
  const meta = value.filter((v, i) => i >= 2);

  const startDate = addCurrentYearToDate(dates[0], dates[1], 'startDate');
  const endDate = addCurrentYearToDate(dates[0], dates[1], 'endDate');

  dates = [startDate, endDate];

  dates = dates.map(v => formatDate(v, {month: 'short', day: '2-digit', timeZone: 'UTC'}));
  dates = dates.join(' → ');

  const label = (meta[0] && typeof meta[0] === 'string') ? meta[0] : (meta[1] && typeof meta[1] === 'string') ? meta[1] : null;
  const transformedLabel = (label) ? `AVAILABILITY_IN_${label.toUpperCase()}` : null;

  return (transformedLabel) ? messages[transformedLabel] ? formatMessage(messages[transformedLabel]) : getFriendlyLycanName(label.toUpperCase()) : dates;
};

export const tagValue = ({value}) => {
  return <TagComponent value={value}/>;
};

export const defaultTagValue = tagValue;
