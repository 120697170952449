import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import AvailabilityFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilterPopover/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { defaultButtonProps } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/props';

const enhance = compose(
  withFilter('availability'),
);

AvailabilityFilterPopover.defaultProps = {
  className: 'filter-popover',
  runQuery: true,
  setFilterFrom: 'search',
  scrollTo: false,
  button: defaultButtonProps
};

AvailabilityFilterPopover.propTypes = {
  className: PropTypes.string,
  setFilterFrom: PropTypes.string,
  filterComponent: PropTypes.func,
  runQuery: PropTypes.bool,
  scrollTo: PropTypes.bool,
  button: PropTypes.object
};

export default enhance(AvailabilityFilterPopover);
