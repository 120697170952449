import React from 'react';
import { WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';

const BlockBlock = ({innerBlocks}) => {
  if(!innerBlocks || !innerBlocks.length) return null;
  return (
    <>
      {innerBlocks.map((block, i) => <WPGBlock key={i} block={block}/>)}
    </>
  );
};

export default BlockBlock;
