export const shouldShowPageHeader = (showPageHeader, blocks) => {
  if(!blocks || !blocks.length) return true;
  const firstBlock = blocks[0];
  const doNotShow = (
    firstBlock.name === 'lazyblock/hero-gallery' ||
    firstBlock.name === 'core/cover' ||
    (
      firstBlock.name === 'core/heading' &&
      firstBlock.attributes &&
      firstBlock.attributes.level === 1
    )
  );

  return showPageHeader && !doNotShow;
};

export const shouldAddContainer = (blocks) => {
  if(!blocks || !blocks.length) return true;
  const blocksWithContainers = blocks.filter(b =>
    b.name === 'lazyblock/hero-gallery' ||
    b.name === 'core/cover' ||
    b.name === 'core/columns' ||
    b.name === 'lazyblock/row-block' ||
    b.name === 'lazyblock/row-block' ||
    b.name === 'lazyblock/inner-row-block'
  );

  return (!blocksWithContainers.length);
};
