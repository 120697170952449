/*
 * LocaleToggle Messages
 */
import { defineMessages } from 'react-intl';

export const scope = 'containers.LocaleToggle';

export default defineMessages({
  en_GB: {
    id: `${scope}.en_GB`,
    defaultMessage: 'EN',
  },
  en_US: {
    id: `${scope}.en_US`,
    defaultMessage: 'US',
  },
  it_IT: {
    id: `${scope}.it_IT`,
    defaultMessage: 'IT',
  },
  de_DE: {
    id: `${scope}.de_DE`,
    defaultMessage: 'DE',
  },
  es_ES: {
    id: `${scope}.es_ES`,
    defaultMessage: 'ES',
  },
});
