import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters.AvailabilityFilterPopover';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Check-in & Check-out'
  },
  label: {
    id: `${scope}.label`,
    defaultMessage: 'Dates'
  },
  setLabel: {
    id: `${scope}.set.label`,
    defaultMessage: 'Dates: {value}'
  },
});
