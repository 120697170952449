import React from 'react';
import { Text } from '@chakra-ui/react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

const CodeBlock = ({innerHTML, className}) => {
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_code');
  return (
    <Text as="span" {...classNameProps}>
      <RenderHtml html={innerHTML}/>
    </Text>
  );
};

export default CodeBlock;

