import get from 'lodash/get';

export const getSearchEndpoint = (url, app, multi = false) => {
  const endpoint = (multi) ? '_msearch' : '_search';
  return `${url}/${app}/${endpoint}`;
};

export const mapHits = (hits) => {
  return hits && hits.length ? hits.map(hit => ({id: hit._id, ...hit._source})) : [];
};

export const collectionExtractor = (data) => {
  const hits = get(data, 'data.hits.hits', []);
  return mapHits(hits);
};

export const documentExtractor = (data) => {
  const hit = get(data, 'data.hits.hits.0', null);
  if(hit === null) {
    return null;
  } else {
    return mapHits([hit])[0];
  }
};
