import { makeSelectGetExchangedCurrency } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/selectors';

export const tagValue = ({value, reduxState}) => {
  const getExchangedCurrency = makeSelectGetExchangedCurrency(reduxState);

  value = [
    getExchangedCurrency(value[0], 0),
    getExchangedCurrency(value[1], 0)
  ];

  return `${value.join(',')}`;
};

export const defaultTagValue = tagValue;
