export const isSingleCurrencyConfig = (currencyExchangeMap) => {
  return currencyExchangeMap.length === 1;
};

export const getCurrenciesFromExchangeMap = (currency, supportedCurrencies, currencyExchangeMap) => {
  const list = [];
  if (!currencyExchangeMap) return list;
  if (currencyExchangeMap[currency] === undefined) return list;

  supportedCurrencies.forEach(c => {
    if(currencyExchangeMap[c]) list.push(c);
  });

  return list;
};

export const getExchangeRate = (currencyExchangeMap, sourceCurrency, targetCurrency) => {
  if (sourceCurrency === targetCurrency) {
    return 1;
  }

  if (currencyExchangeMap[sourceCurrency] === undefined) {
    return null;
  }

  let sourceMap = currencyExchangeMap[sourceCurrency];

  if (sourceMap[targetCurrency] === undefined) {
    return null;
  }

  return parseFloat(sourceMap[targetCurrency]);
};

export const exchangeCurrencyAmount = (currencyExchangeMap, sourceCurrency, targetCurrency, value, decimalPlace = 2) => {
  return (value * getExchangeRate(currencyExchangeMap, sourceCurrency, targetCurrency)).toFixed(decimalPlace);
};
