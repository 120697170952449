import { defineMessages } from 'react-intl';

export const scope = 'components.search.SearchBar';

export default defineMessages({
  buttonLabel: {
    id: `${scope}.button.label`,
    defaultMessage: 'Search'
  }
});
