import React from 'react';
import { WPG_MENU_TYPE_VERTICAL } from '@rentivo/gatsby-core/src/components/wpg/lazy/Menu/constants';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Block } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { Stack } from '@chakra-ui/react';
import { LocaleLink } from '@rentivo/gatsby-core/index';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const MenuBlock = ({attrs, childProps, className}) => {
  const {
    menuType = WPG_MENU_TYPE_VERTICAL,
    items
  } = attrs || {};

  if(!items) return null;

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_menu');

  const sanitizedItems = JSON.parse(decodeURIComponent(items));
  if(!sanitizedItems || !sanitizedItems.length) return null;

  return (
    <Block attrs={attrs} pl={0} pr={0} {...classNameProps}>
      <Stack spacing={menuType === WPG_MENU_TYPE_VERTICAL ? 2 : 4} direction={menuType === WPG_MENU_TYPE_VERTICAL ? 'column' : 'row'}>
        {sanitizedItems.map((item, i) => (
          <LocaleLink
            key={'Menu Item ' + i}
            rel={(item.noFollow) ? 'nofollow noreferrer' : ''}
            to={item.url}
            target={(item.newTab) ? '_blank' : '_self'}
            fontWeight="medium"
            {...stripNonStyles(childProps)}
          >
            {item.label}
          </LocaleLink>
        ))}
      </Stack>
    </Block>
  );
};

export default MenuBlock;

// TODO: Fix locale link styling... when nofollow or target.
