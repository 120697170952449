import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { HEADING_LEVELS } from '@rentivo/gatsby-core/src/components/wpg/constants';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const HeadingBlock = (props) => {

  const {
    className,
    attrs: {
      anchor = '',
      textAlign = 'left',
      content = '',
      level = 1
    },
    childProps,
  } = props;

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_heading');
  const headingAs = HEADING_LEVELS[level];

  return (
    <Heading
      id={anchor}
      as={headingAs}
      variant="writing"
      textAlign={textAlign}
      mb={3}
      {...stripNonStyles(childProps)}
      {...classNameProps}
    >
      <RenderHtml html={content}/>
    </Heading>
  );
};

export default HeadingBlock;
