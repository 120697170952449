import React from 'react';
import { chakra } from '@chakra-ui/react';
import { removePx } from '@rentivo/gatsby-core/src/utils/strings';

const AspectRatio = ({ratio, width, expandChild, children, ...rest}) => {
  if(!ratio) return children;

  /*
  css={{
        '> *': {
          overflow: 'hidden',
          position: 'absolute',
          inset: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }
      }}
   */ // TODO: Seems to create an issye with WPG Card zooming.

  return (
    <chakra.div
      position="relative"
      maxW={(width) ? `${removePx(width)}px` : '100%'}
      w="100%"
      _before={{
        content: '""',
        display: 'block',
        height: '0px',
        paddingBottom: `calc(100% / (${ratio}))`
      }}
      {...rest}
    >
      <chakra.div layerStyle="filled">{children}</chakra.div>

    </chakra.div>
  );

  /*

  return (
    <chakra.div
      w={(width) ? `${removePx(width)}px` : '100%'}
      display="block"
      position="relative"
      h={0}
      pb={`calc(100% / (${ratio}))`}
    >
      <chakra.div layerStyle="filled">{children}</chakra.div>
    </chakra.div>
  );*/
};

AspectRatio.defaultProps = {
  ratio: '16/9',
  expandChild: true
};

export default AspectRatio;
