import React, { useRef } from 'react';
import { window } from 'browser-monads';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  CloseButton,
  IconButton,
  useDisclosure
} from '@chakra-ui/react';

const BurgerDrawComponent = ({children, theme, width}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef(null);

  return (
    <span>
      <IconButton
        variant={`menu${theme === 'dark' ? 'Dark' : 'Light'}`}
        aria-label="Open navigation menu"
        ref={buttonRef}
        onClick={onOpen}
      >
        <FontAwesomeIcon icon={faBars}/>
      </IconButton>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={buttonRef}
      >
        <DrawerOverlay>
          <DrawerContent width={width} h={window.innerHeight}>
            <DrawerCloseButton as={CloseButton} position="absolute" top="0.75rem" right="1.25rem" />
            <DrawerBody pb={0} pl={0} pr={0} pt={2}>
              {children}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </span>
  )
};

export default BurgerDrawComponent;

/*
<StyledDraw
  width={width}
  placement={placement}
  closable={false}
  onClose={() => setActive(false)}
  visible={active}
  drawerStyle={{background: bgColor}}
  bodyStyle={{...drawBodyStyle, background: bgColor}}
  getContainer={container}
>
  {children}
</StyledDraw>
 */

/*
<Burger
  onClick={() => setActive(!active)}
  active={active}
  burger="3dy"
  marginTop={`0`}
  marginLeft={`0`}
  scale={0.66}
  color={theme['text-color']}
/>
 */
