import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import LocationFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { query } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/query';
import { tagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/tag';
import { onUpdateSuggests } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/utils';

const enhance = compose(
  withFilter('location', query, tagValue),
);

LocationFilter.defaultProps = {
  className: 'filter',
  onFocus: () => null,
  onBlur: () => null,
  onUpdateSuggests: onUpdateSuggests,
  onSuggestSelect: () => null,
  runQuery: true,
  setFilterFrom: 'search',
  size: 'default',
  scrollTo: false
};

LocationFilter.propTypes = {
  onFocus: PropTypes.func,
  setFilterFrom: PropTypes.string,
  onBlur: PropTypes.func,
  onUpdateSuggests: PropTypes.func,
  onSuggestSelect: PropTypes.func,
  className: PropTypes.string,
  button: PropTypes.object,
  size: PropTypes.string,
  runQuery: PropTypes.bool,
  scrollTo: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ])
};

export default enhance(LocationFilter);
