import React, { useRef } from 'react';
import SelectCounter from '@rentivo/gatsby-core/src/components/ui/SelectCounter';
import { parseValueAndQueryFormat } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/NumberFilter/utils';
import { FILTER_NUMBER_TYPE_SELECT } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import { debounce } from 'lodash';
const debounceWait = 250;

const NumberFilterComponent = ({filterOptions, filter, setFilter, component, min, max}) => {
  const Component = (filterOptions.type && filterOptions.type === FILTER_NUMBER_TYPE_SELECT) ? SelectCounter : component;
  const onChange = (value) => setFilter(value);
  const debouncedOnChange = useRef(debounce(data => onChange(data), debounceWait)).current;

  return (
    <>
      <Component
        value={filter ? parseValueAndQueryFormat(filter.value, 'value') : filterOptions.defaultValue}
        min={filterOptions.min ? filterOptions.min : min}
        max={filterOptions.max ? filterOptions.max : max}
        appendString={filterOptions.queryFormat === 'gte' ? '+' : ''}
        onChange={debouncedOnChange}
      />
    </>
  );
};

export default NumberFilterComponent;
