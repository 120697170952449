import React from 'react';
import { chakra } from '@chakra-ui/react';

export const StyledNestedSelector = chakra('div', {
  baseStyle: {
    overflow: 'hidden'
  }
});

export const StyledNestedSelectorSlider = chakra('div', {
  baseStyle: {
    transition: 'transform 250ms ease'
  }
});

export const StyledNestedSelectorMenu = chakra('div', {
  baseStyle: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    float: 'left'
  }
});

export const StyledNestedSelectorMenuScroll = chakra('div', {
  baseStyle: {
    maxHeight: { base: '100%', md: '240px' },
    overflowY: 'auto'
  }
});

export const StyledNestedSelectorMenuItem = chakra('div', {
  baseStyle: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export const StyledNestedSelectorMenuItemLink = chakra('span', {
  baseStyle: {
    display: 'block',
    lineHeight: '40px',
    py: 0,
    px: 4,
    flexGrow: 1
  }
});

export const StyledNestedSelectorMenuItemArrow = chakra('span', {
  baseStyle: {
    display: 'flex',
    w: '40px',
    h: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      bgColor: 'gray.200',
      cursor: 'pointer'
    }
  }
});
