export default {
  cdnId: 'wpg-featurette',
  //srcSet: true,
  //useProgressiveImage: true,
  //useProgressiveImageAspect: true,
  quality: 100,
  original: true,
  fadeIn: false,
  critical: true
};
