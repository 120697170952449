import get from 'lodash/get';

export const selectGuestsFromFilters = (filters) => {
  const {
    adults: { filterOptions: adultsOptions, filter: adultsFilter },
    children: { filterOptions: childrenOptions, filter: childrenFilter },
    infants: { filterOptions: infantsOptions, filter: infantsFilter },
    pets: { filterOptions: petsOptions, filter: petsFilter }
  } = filters || {};

  // Get defaults
  const adultsDefaultValue = get(adultsOptions, 'defaultValue', 0);
  const childrenDefaultValue = get(childrenOptions, 'defaultValue', 0);
  const infantsDefaultValue = get(infantsOptions, 'defaultValue', 0);
  const petsDefaultValue = get(petsOptions, 'defaultValue', 0);

  // Now look for value, if not, set default
  const adults = (adultsFilter) ? parseInt(adultsFilter.value) : parseInt(adultsDefaultValue);
  const children = (childrenFilter) ? parseInt(childrenFilter.value) : parseInt(childrenDefaultValue);
  const infants = (infantsFilter) ? parseInt(infantsFilter.value) : parseInt(infantsDefaultValue);
  const pets = (petsFilter) ? parseInt(petsFilter.value) : parseInt(petsDefaultValue);

  return {
    adults,
    children,
    infants,
    pets
  }
};

/*
export const selectGuestsFromFilter = (filterOptions, filter) => {
  const { addonsOptions } = filterOptions || {};
  const { addons = [] } = filter || {};

  // Get defaults & urlParams
  const adultsUrlParam = get(addonsOptions, 'adults.urlParam', 'adults');
  const adultsDefaultValue = get(addonsOptions, 'adults.defaultValue', 0);

  const childrenUrlParam = get(addonsOptions, 'children.urlParam', 'children');
  const childrenDefaultValue = get(addonsOptions, 'children.defaultValue', 0);

  const infantsUrlParam = get(addonsOptions, 'infants.urlParam', 'infants');
  const infantsDefaultValue = get(addonsOptions, 'infants.defaultValue', 0);

  const petsUrlParam = get(addonsOptions, 'pets.urlParam', 'pets');
  const petsDefaultValue = get(addonsOptions, 'pets.defaultValue', 0);

  // Now look in addons for each item
  const adultsInAddons = addons.filter(a => adultsUrlParam === a.urlParam);
  const adults = (adultsInAddons.length) ? parseInt(adultsInAddons[0].value) : adultsDefaultValue;

  const childrenInAddons = addons.filter(a => childrenUrlParam === a.urlParam);
  const children = (childrenInAddons.length) ? parseInt(childrenInAddons[0].value) : childrenDefaultValue;

  const infantsInAddons = addons.filter(a => infantsUrlParam === a.urlParam);
  const infants = (infantsInAddons.length) ? parseInt(infantsInAddons[0].value) : infantsDefaultValue;

  const petsInAddons = addons.filter(a => petsUrlParam === a.urlParam);
  const pets = (petsInAddons.length) ? parseInt(petsInAddons[0].value) : petsDefaultValue;

  return {
    adults,
    children,
    infants,
    pets
  }
};
 */

export const selectOptionsPerGuest = (filterOptions) => {
  const { addonsOptions } = filterOptions || {};

  return {
    adultsCount: get(addonsOptions, 'adults.countsToTotal', true),
    showAdults: get(addonsOptions, 'adults.active', true),
    childrenCount: get(addonsOptions, 'children.countsToTotal', true),
    showChildren: get(addonsOptions, 'children.active', true),
    infantsCount: get(addonsOptions, 'infants.countsToTotal', false),
    showInfants: get(addonsOptions, 'infants.active', true),
    petsCount: get(addonsOptions, 'pets.countsToTotal', false),
    showPets: get(addonsOptions, 'pets.active', true)
  }
};
