import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { selectDefaultLang, selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { useSelector } from 'react-redux';
import { useElasticSearch } from '@rentivo/gatsby-core/src/hooks/useElasticSearch';
import { parseCollections } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/ElasticCollections/utils';
import { selectSearchCollectionsRootCollectionDatabaseIdConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const useCollections = (parentId, runQuery = false) => {
  const { formatMessage } = useIntl();
  const lang = useSelector(state => selectLang(state));
  const defaultLang = useSelector(state => selectDefaultLang(state));
  const rootCollectionDatabaseIdConfig = useSelector(state => selectSearchCollectionsRootCollectionDatabaseIdConfig(state));
  parentId = parentId ? parentId : rootCollectionDatabaseIdConfig ? rootCollectionDatabaseIdConfig : parentId;

  const mustNot = [];
  const must = [
    { "term": { "language.slug": defaultLang } },
    { "term": { "collection.inNestedSelector": true } }
  ];

  if(parentId) {
    must.push({
      "term": {
        "relationshipParentDatabaseId": parentId
      }
      /*
      "nested": {
        "path": "relationships.elementParents",
        "query": {
          "bool": {
            "must": [
              {
                "term": {
                  "relationships.elementParents.databaseId": parentId
                }
              }
            ]
          }
        }
      }
       */
    });
  } else {
    mustNot.push({
      "nested": {
        "path": "relationships.elementParents",
        "query": {
          "exists": {
            "field": "relationships.elementParents"
          }
        }
      }
    });
  }

  const results = useElasticSearch({key: `collectionsByParent-${parentId ? parentId : 0}`, defaultData: [], index: 'collections', query: {
      size: 64,
      query: {
        "bool": {
          "must": must,
          "must_not": mustNot
        }
      }
    }, runQuery: (parentId !== undefined && runQuery)});

  return {
    ...results,
    data: results && results.data ? parseCollections(results.data, lang, formatMessage) : []
  };
};

