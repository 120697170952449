import unified from 'unified';
import rehypeReact from 'rehype-react';
import rehypeRaw from 'rehype-raw';
import htmlParser from 'rehype-parse';
import React from 'react';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import { LocaleLink } from '@rentivo/gatsby-core/index';
import { UnorderedList, OrderedList, ListItem, Text, Heading } from '@chakra-ui/react';
import { wpgFontSizeToChakraFontSize } from '@rentivo/gatsby-core/src/components/wpg/utils';

export const compileHtml = (html, options = {}) => {
  const processor = unified()
    .use(htmlParser, { emitParseErrors: true, duplicateAttribute: false, fragment: true })
    .use(rehypeRaw)
    .use(rehypeReact, {
      createElement: React.createElement,
      Fragment: React.Fragment,
      components: RehypeComponentsList(options)
    });

  const ast = processor.runSync(processor.parse(html));

  return {
    ast,
    contents: processor.stringify(ast)
  };
};

const textComponents = () => {
  const compsObj = {};
  const comps = ['i', 'u', 'abbr', 'cite', 'del', 'em', 'ins', 'kbd', 'mark', 's', 'samp', 'sub', 'sup', 'p'];
  let i = 0, len = comps.length;
  while (i < len) {
    const as = comps[i];
    compsObj[as] = ({children, fontSize, ...rest}) => {
      return (
        <Text {...rest} variant="writing" fontSize={wpgFontSizeToChakraFontSize(rest)} as={as} mb={as === 'p' ? 4 : 0}>{as === 'cite' ? '— ' : ''}{children}</Text>
      );
    };
    i++;
  }

  return {
    ...compsObj,
    highlight: ({children, ...rest}) => <Text {...rest} as="span" layerStyle="highlight">{children}</Text>
  };
};

const headingComponents = ({content = false}) => {
  const compsObj = {};
  const comps = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  let i = 0, len = comps.length;
  while (i < len) {
    const as = comps[i];
    compsObj[as] = ({children, fontSize, ...rest}) => {
      return (
        <Heading {...rest} as={as} mt={content ? 1 : 0} mb={content ? 4 : 0} variant="writing">{children}</Heading>
      );
    };
    i++;
  }

  return {
    ...compsObj,
    highlight: ({children, ...rest}) => <Text {...rest} as="span" layerStyle="highlight">{children}</Text>
  };
};

export const RehypeComponentsList = (options = {}) => ({
  a: ({href, children, ...rest}) => {
    return <LocaleLink to={href} {...rest}>{children}</LocaleLink>;
  },
  ol: ({children, ...rest}) => {
    return <OrderedList {...rest}>{children}</OrderedList>
  },
  ul: ({children, ...rest}) => {
    return <UnorderedList {...rest}>{children}</UnorderedList>
  },
  li: ({children, ...rest}) => {
    return <ListItem {...rest}>{children}</ListItem>
  },
  /*
  // TODO: This adds <div> wrapper to everything. Probably shouldn't add.
  div: ({children, style = {}, ...rest}) => {
    const { content = false } = options || {};
    if(content) {
      if(style.width) delete style.width;
      if(style.width) delete style.height;
    }
    return <div style={style} {...rest} >{children}</div>
  },*/
  img: props => {
    const extraProps = {};
    let { src, width, height, alt } = props;
    const { width: optionsWidth, height: optionsHeight, aspect, quality = 90, critical = false, original = false, fadeIn = true, content = false, classname = '' } = options || {};

    if(!src) return null;
    if(props.className && props.className.includes('option-raw')) {
      return <img {...props}/>;
    }

    if(!content) {
      if(optionsWidth && optionsHeight) {
        extraProps.aspect = `${optionsWidth} / ${optionsHeight}`;
      } else if(aspect) {
        extraProps.aspect = aspect;
      } else {
        if(width) extraProps.width = width;
        if(height) extraProps.height = height;
        extraProps.aspect = false;
      }
    } else {
      extraProps.width = undefined;
      extraProps.height = undefined;
    }

    return (
      <CdnImage {...props} {...extraProps} className={classname} uri={src} quality={quality} original={original} alt={alt ? alt : 'Rendered Image'} critical={critical} fadeIn={fadeIn}/>
    );
  },
  ...textComponents(options),
  ...headingComponents(options)
});
