import loadable from '@loadable/component';
import CommonBlocks from '@rentivo/gatsby-core/src/components/wpg/common';
import FormatBlocks from '@rentivo/gatsby-core/src/components/wpg/format';
import LayoutBlocks from '@rentivo/gatsby-core/src/components/wpg/layout';
import LazyBlocks from '@rentivo/gatsby-core/src/components/wpg/lazy';
const DefaultHtmlBlock = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/format/Html'));

export function GetTheBlock(name) {
  switch (name) {
    //case 'core/audio': return CommonBlocks.Audio;
    //case 'core/file': return CommonBlocks.File;
    case 'core/cover': return CommonBlocks.Cover;
    case 'core/spacer': return CommonBlocks.Spacer;
    case 'core/separator': return CommonBlocks.Separator;
    case 'core/media-text': return CommonBlocks.MediaText;
    case 'core/gallery': return CommonBlocks.Gallery;
    case 'core/heading': return CommonBlocks.Heading;
    case 'core/image': return CommonBlocks.Image;
    case 'core/list': return CommonBlocks.List;
    case 'core/paragraph': return CommonBlocks.Paragraph;
    case 'core/quote': return CommonBlocks.Quote;
    case 'core/video': return CommonBlocks.Video;
    case 'core/code': return FormatBlocks.Code;
    case 'core/html': return FormatBlocks.Html;
    case 'core/preformatted': return FormatBlocks.Preformatted;
    case 'core/pullquote': return FormatBlocks.Pullquote;
    case 'core/columns': return LayoutBlocks.Columns;
    case 'lazyblock/search-bar': return LazyBlocks.SearchBar;
    case 'lazyblock/hero-gallery': return LazyBlocks.HeroGallery;
    case 'lazyblock/hero-video': return LazyBlocks.HeroVideo;
    case 'lazyblock/card': return LazyBlocks.Card;
    case 'lazyblock/row-block': return LazyBlocks.RowBlock;
    case 'lazyblock/inner-row-block': return LazyBlocks.InnerRowBlock;
    case 'lazyblock/featurette': return LazyBlocks.Featurette;
    case 'lazyblock/section-title': return LazyBlocks.SectionTitle;
    case 'lazyblock/featured-properties': return LazyBlocks.FeaturedProperties;
    case 'lazyblock/recent-blog-posts': return LazyBlocks.RecentBlogPosts;
    case 'lazyblock/featured-posts': return LazyBlocks.FeaturedPosts;
    case 'lazyblock/newsletter': return LazyBlocks.Newsletter;
    case 'lazyblock/menu': return LazyBlocks.Menu;
    case 'lazyblock/theme-buttons': return LazyBlocks.ThemeButtons;
    case 'lazyblock/enquiry-form': return LazyBlocks.EnquiryForm;
    case 'lazyblock/box': return LazyBlocks.Box;
    case 'lazyblock/update-bar': return LazyBlocks.UpdateBar;
    case 'core/block': return CommonBlocks.Block;
    default:
      return DefaultHtmlBlock;
    //default: return null;
  }
}

export default {
  ...CommonBlocks,
  ...FormatBlocks
}
