import React from 'react';
import { Box } from '@chakra-ui/react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { quoteCss } from '@rentivo/gatsby-core/src/components/wpg/common/Quote';

const PullquoteBlock = ({attrs, className}) => {
  const { value } = attrs;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_pullquote');
  return (
    <Box
      {...classNameProps}
      as="blockquote"
      layerStyle="boxed"
      pl={6}
      pr={6}
      pt={3}
      pb={3}
      borderRadius="md"
      css={quoteCss}
    >
      <RenderHtml html={value}/>
    </Box>
  );
};

export default PullquoteBlock;
