import { numericStringToNumber, stringContains, uriDecode } from '@rentivo/gatsby-core/src/utils/strings';

export const stripNonStyles = (props) => {
  const {
    insideColumns,
    templateColumns,
    rowAlign,
    innerRowAlign,
    ...rest
  } = props;
  return rest;
};

export const sanitizeValue = (propKey, propValue) => {
  if(typeof propValue === 'object') return propValue;
  propValue = numericStringToNumber(propValue);
  if(propKey === 'content') {
    if (propValue === 'none' || !propValue) {
      return '""';
    }
  } else if(propKey === 'true') {
    return true;
  } else if(propKey === 'false') {
    return false;
  } else {
    return stringContains(propValue , '%') ? uriDecode(propValue) : propValue;
  }
};

export const sanitizeObjKey = (propKey) => {
  if(propKey === 'option') {
    return 'options';
  } else {
    return propKey;
  }
};

export const sanitizeProp = (prop) => {
  const items = prop.split(':');
  if(items.length < 2) return null;
  const key = items[0];
  const value = items.slice(1).join(':');

  if(stringContains(value , '[')) {
    let valueObj = {};
    const valueValues = value.replace('[', '').replace(']', '').split(',');
    let i = 0, len = valueValues.length;
    while (i < len) {
      const vKeyValues = valueValues[i].split(':');
      if(vKeyValues.length === 2) {
        const [vKey, vValue] = vKeyValues;
        valueObj[vKey] = sanitizeValue(vKey, vValue);
      }
      i++;
    }
    return {
      key,
      value: valueObj
    }
  } else {
    return {
      key,
      value: sanitizeValue(key, value)
    }
  }
};

export const wpgFontSizeToChakraFontSize = ({style, customFontSize}) => {
  if(customFontSize) return `${customFontSize}px`;
  if(!style?.typography?.fontSize) {
    return 'md';
  } else {
    const fontSize = style?.typography?.fontSize;
    if(!fontSize) return 'md';
    if(fontSize === 'default') return 'md';
    if(fontSize === 'medium') return 'lg';
    if(fontSize === 'large') return 'xl';
    if(fontSize === 'huge') return '3xl';
    return fontSize;
  }
};
