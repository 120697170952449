import { selectSearchResultsPerPageConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const query = ({value, reduxState}) => {
  if(!value || value === 1) return null;
  value = parseInt(value);
  const perPage = selectSearchResultsPerPageConfig(reduxState);
  const from = (((value * perPage) - perPage)).toFixed(0);

  return {
    "from" : parseInt(from)
  };
};

export const defaultQuery = query;
