import React, { useState, useRef, useCallback, useMemo } from 'react';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import { ComputerOrTabletView, MobileOnlyView } from '@rentivo/gatsby-core/src/context/ResponsiveContext';
import { scrollToRef } from '@rentivo/gatsby-core/src/utils/window';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { window } from 'browser-monads';
import { defaultButtonProps } from '@rentivo/gatsby-core/src/components/ui/PopoverButton/index';

import {
  Popover,
  Portal,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton
} from '@chakra-ui/react';
import { useOnClickOutside } from '@rentivo/gatsby-core/src/hooks/useOnClickOutside';
import { useControllableState } from '@chakra-ui/hooks';
import { removePx } from '@rentivo/gatsby-core/src/utils/strings';

const PopoverButtonComponent = ({
  isMobileOnly,
  onMouseOver: onMouseOverHook,
  isComputerOrTablet,
  component: Component,
  componentProps,
  onClose,
  onOk,
  title,
  scrollTo,
  scrollOffset,
  scrollThreshold,
  useModal,
  popover,
  button: bProps,
  drawer,
  popoverWidth,
  popoverHeight,
  popoverShowTitle,
  buttonLabel,
  actionLoading,
  modal,
  visible,
  onVisibleChange,
  closeButtonLabel,
  okButtonLabel,
  showCloseButton,
  showOkButton,
  waitingToLoad
}) => {
  bProps = (bProps) ? { ...defaultButtonProps, ...bProps } : defaultButtonProps;
  const { forceButtonIcon, iconStyle, ...button } = bProps;
  const isLoadableComponent = (Component && typeof Component.load === 'function');
  const [componentLoaded, setComponentLoaded] = useState(!isLoadableComponent);
  const [loading, setLoading] = useState(false);
  const [isVisible, handleVisibleChange] = useControllableState({
    value: visible,
    onChange: onVisibleChange,
    defaultValue: false
  });

  const showBeVisible = (isVisible && !waitingToLoad);

  const popoverRef = useRef(null);
  const popoverHeaderRef = useRef(null);
  const popoverFooterRef = useRef(null);
  const popoverButtonRef = useRef(null);
  const anchorRef = useRef(null);

  const handleOnClose = useCallback(() => {
    onClose();
    handleVisibleChange(false);
  }, [onClose, handleVisibleChange]);

  useOnClickOutside([popoverRef, popoverButtonRef, popoverFooterRef, popoverHeaderRef], () => handleVisibleChange(false));

  const buttonProps = useMemo(() => ({
    onMouseOver: () => {
      onMouseOverHook();
      if(!componentLoaded && isLoadableComponent) {
        Component.load().then(() => {
          setComponentLoaded(true);
        });
      }
    },
    ...button,
    onClick: () => {
      if(isComputerOrTablet && scrollTo) scrollToRef(anchorRef, scrollOffset, scrollThreshold);
      if(!isLoadableComponent || componentLoaded) {
        return handleVisibleChange(true);
      }
      setLoading(true);
      Component.load().then(() => {
        setLoading(false);
        handleVisibleChange(true)
      });
    },
    rightIcon: (!isMobileOnly || forceButtonIcon) ? <FontAwesomeIcon icon={faChevronDown} style={iconStyle} rotation={(isVisible) ? 180 : undefined}/> : undefined
  }), [onMouseOverHook, isVisible, componentLoaded, isLoadableComponent, Component, isComputerOrTablet, scrollTo, anchorRef.current, scrollOffset, scrollThreshold, handleVisibleChange, button.size, button.rightIcon, button.width]);


  return (
    <div ref={anchorRef}>
      <MobileOnlyView>
        <Button {...buttonProps} isLoading={loading} onMouseOver={onMouseOverHook} onClick={() => {
          onMouseOverHook();
          handleVisibleChange(!isVisible);
        }}>{buttonLabel}</Button>
        <Drawer
          placement="bottom"
          isOpen={isVisible}
          size="full"
          onClose={() => handleVisibleChange(false)}
        >
          <DrawerOverlay>
            <DrawerContent h={`${window.innerHeight}px`}>
              <DrawerCloseButton as={CloseButton} position="absolute" top="0.75rem" right="1.25rem" />
              <DrawerHeader>{title ? title : buttonLabel}</DrawerHeader>
              <DrawerBody>
                <Component {...componentProps}/>
              </DrawerBody>
              {(showOkButton || showCloseButton) && (
                <DrawerFooter
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonGroup size="sm">
                    {showCloseButton && (<Button type="primary" onClick={handleOnClose}>{closeButtonLabel}</Button>)}
                    {showOkButton && (<Button type="primary" isLoading={actionLoading} onClick={onOk}>{okButtonLabel}</Button>)}
                  </ButtonGroup>
                </DrawerFooter>
              )}
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </MobileOnlyView>
      <ComputerOrTabletView>
        {useModal ? (
          <>
            <Button {...buttonProps} rightIcon={undefined} isLoading={loading} onClick={() => {
              onMouseOverHook();
              handleVisibleChange(!isVisible);
            }}>{buttonLabel}</Button>
            <Modal
              isOpen={isVisible}
              onClose={() => handleVisibleChange(false)}
              size="xl"
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title ? title : buttonLabel}</ModalHeader>
                <ModalCloseButton as={CloseButton} top="1rem" right="1rem" />
                <ModalBody>
                  <Component {...componentProps}/>
                </ModalBody>

                {(showOkButton || showCloseButton) && (
                  <ModalFooter
                    d="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonGroup size="sm">
                      {showCloseButton && (<Button type="primary" onClick={handleOnClose}>{closeButtonLabel}</Button>)}
                      {showOkButton && (<Button type="primary" isLoading={actionLoading || (modal && modal.confirmLoading)} onClick={onOk}>{okButtonLabel}</Button>)}
                    </ButtonGroup>
                  </ModalFooter>
                )}
              </ModalContent>
            </Modal>
          </>
        ) : (
          <Popover
            returnFocusOnClose={false}
            closeOnBlur={false}
            isOpen={showBeVisible}
            isLazy={true}
          >
            <PopoverTrigger>
              <Button ref={isVisible ? popoverButtonRef : null} {...buttonProps} isLoading={loading} onClick={() => handleVisibleChange(!isVisible)}>{buttonLabel}</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent width={`calc(${removePx(popoverWidth)}px + 1.5rem)`}>
                <PopoverCloseButton size="md" zIndex={1} onClick={() => handleVisibleChange(false)}/>
                {popoverShowTitle && (
                  <PopoverHeader fontWeight="semibold" ref={popoverHeaderRef}>
                    {title ? title : buttonLabel}
                  </PopoverHeader>
                )}
                <PopoverBody ref={popoverRef} width={`calc(${removePx(popoverWidth)}px + 1.5rem)`} height={popoverHeight ? `calc(${removePx(popoverHeight)}px + 1rem)` : undefined}>
                  <Component {...componentProps}/>
                  </PopoverBody>
                  {(showOkButton || showCloseButton) && (
                    <PopoverFooter>
                      <Box
                        d="flex"
                        alignItems="center"
                        justifyContent="center"
                        ref={popoverFooterRef}
                      >
                        <ButtonGroup size="sm">
                          {showCloseButton && (<Button type="primary" size="sm" onClick={handleOnClose}>{closeButtonLabel}</Button>)}
                          {showOkButton && (<Button type="primary" size="sm" isLoading={actionLoading} onClick={onOk}>{okButtonLabel}</Button>)}
                        </ButtonGroup>
                      </Box>
                    </PopoverFooter>
                  )}
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </ComputerOrTabletView>
    </div>
  );
};

//<PopoverArrow />

export default PopoverButtonComponent;

/*
<Popover
  returnFocusOnClose={false}
  isOpen={isVisible}
  onClose={() => handleVisibleChange(false)}
  closeOnBlur={false}
>
  <PopoverTrigger>
    <Button {...buttonProps} isLoading={loading} onClick={() => handleVisibleChange(true)}>{buttonLabel}</Button>
  </PopoverTrigger>
  <Portal>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody>
        <Component {...componentProps}/>
      </PopoverBody>
    </PopoverContent>
  </Portal>
</Popover>


<Popover
  content={<ConditionalWrapper condition={showCloseButton} wrapper={closeButtonWrapper}><Component {...componentProps}/></ConditionalWrapper>}
  visible={isVisible}
  onVisibleChange={v => handleVisibleChange(v)}
  {...popover}
>
  <Button {...buttonProps} isLoading={loading}>{buttonLabel}</Button>
</Popover>
 */


//{...drawer}

/*
<Button {...buttonProps} rightIcon={undefined} isLoading={loading}>{buttonLabel}</Button>
            <Modal
              title={title ? title : buttonLabel}
              visible={isVisible}
              onOk={onOk}
              onCancel={() => handleVisibleChange(false)}
              cancelText={closeButtonLabel}
              okText={okButtonLabel}
              confirmLoading={actionLoading}
              {...modal}
            >
              <Component {...componentProps}/>
            </Modal>
 */
