import { CDN_IMAGEKIT } from '@rentivo/gatsby-core/src/containers/CdnProvider/constants';
import { trimLastSlash } from '@rentivo/gatsby-core/src/utils/strings';
import { cdnDefaultProps } from '@rentivo/gatsby-core/src/components/ui/CdnImage/constants';

export const getTransformationPath = ({ provider, ...rest }) => {
  if(provider === CDN_IMAGEKIT) {
    return getImageKitTransformationPath(rest);
  } else {
    console.log('Please choose a supported CDN in your site config');
    return null;
  }
};

export const getImageKitTransformationPath = ({ urlEndpoint, uri, width, cropForce, height, aspect, focus, transformation, quality, atMax, atMin, defaultImage, useDefaultImage, original }) => {
  const isSvg = (uri && typeof uri === 'string' && uri.length && uri.includes('.svg'));
  defaultImage = defaultImage !== undefined ? defaultImage : cdnDefaultProps.defaultImage;

  const items = [];
  let tr = '';
  let otherOptions = '';

  if(width && !isSvg) {
    items.push(`w-${parseInt(width)}`);
  }

  if(height && !isSvg) {
    items.push(`h-${parseInt(height)}`);
  }

  if(aspect) {
    aspect = aspect.split('/');
    if(aspect.length === 2) items.push(`ar-${parseInt(aspect[0])}-${parseInt(aspect[1])}`);
  }

  if(focus) {
    items.push(`fo-${focus}`);
  }

  if(quality) {
    items.push(`q-${quality}`);
  }

  if(items.length) {
    tr = (transformation) ? `tr:n-${transformation}` : `tr:${items.join(',')}`;
  }

  if(atMax) {
    otherOptions = otherOptions + ',c-at_max';
  }

  if(atMin) {
    otherOptions = otherOptions + ',c-at_least';
  }

  if(useDefaultImage) {
    otherOptions = otherOptions + `,di-${defaultImage}`;
  }

  if(cropForce) {
    otherOptions = otherOptions + `,c-force`;
  }

  if(original) {
    otherOptions = otherOptions + `,orig-true`;
  }

  tr = (tr) ? `${tr}${otherOptions}/` : ``;
  return `${trimLastSlash(urlEndpoint)}/${tr}${uri}`;
};

export const srcSetBreakpoints = {
  mobile: {
    width: 640,
    aspect: '1/1'
  },
  mobileWide: {
    width: 768,
    aspect: '1/1'
  },
  tablet: {
    width: 992,
    aspect: '4/3'
  },
  laptop: {
    width: 1366,
    aspect: null
  },
  desktop: {
    width: 1600,
    aspect: null
  }
};

export const getImageKitTransformationSrcSet = (params, maxImageUrl) => {
  const { useProgressiveImageAspect = false, width: maxWidth, aspect } = params;

  const srcSetArray = [];
  for (const [breakpointName, breakpointObj] of Object.entries(srcSetBreakpoints)) {
    if(breakpointObj.width <= maxWidth) {
      const path = getImageKitTransformationPath({
        ...params,
        width: breakpointObj.width,
        height: undefined,
        aspect: (useProgressiveImageAspect && breakpointObj.aspect) ? breakpointObj.aspect : undefined
      });
      srcSetArray.push(`${path} ${breakpointObj.width}w`);
    } else {
      const path = getImageKitTransformationPath({...params, width: maxWidth});
      srcSetArray.push(`${path} ${maxWidth}w`);
      break;
    }
  }

  const srcSet = srcSetArray.join(', ');
  const sizes = `(max-width: 4000px) 100vw, ${maxWidth}px`;

  return {
    sizes,
    srcSet
  };
};
