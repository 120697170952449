import React from 'react';
import { Box } from '@chakra-ui/react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

export const quoteCss = {
  'p': {
    fontFamily: 'Georgia, Times, "Times New Roman", serif',
    fontSize: '1.5em',
    fontStyle: 'italic',
    marginBottom: '0.5em'
  },
  'cite': {
    fontSize: '1em',
    display: 'block',
    opacity: 0.66,
  }
};

const QuoteBlock = ({attrs, className}) => {
  const { value } = attrs;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_quote');
  return (
    <Box
      mb={4}
      as="blockquote"
      position="relative"
      borderLeftStyle="solid"
      borderLeftWidth="2px"
      borderLeftColor="secondary.500"
      padding="1em 2em"
      _before={{
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '-4px',
        height: '2em',
        backgroundColor: '#ffffff',
        width: '5px',
        marginTop: '-1em',
      }}
      _after={{
        content: "'\"'",
        position: 'absolute',
        top: '50%',
        left: '-0.5em',
        color: 'secondary.500',
        fontStyle: 'normal',
        lineHeight: '1em',
        fontSize: '3em',
        textAlign: 'center',
        textIndent: '-2px',
        fontFamily: 'Georgia, Times, "Times New Roman", serif',
        width: '1em',
        marginTop: '-11px'
      }}
      css={quoteCss}
      {...classNameProps}
    >
      <RenderHtml html={value}/>
    </Box>
  );
};

export default QuoteBlock;
