import { defineMessages } from 'react-intl';

export const scope = 'components.generic.Logo';

export default defineMessages({
  url: {
    id: `${scope}.url`,
    defaultMessage: '/'
  }
});
