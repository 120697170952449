import { CHANGE_CURRENCY, SET_CURRENCY_EXCHANGE_MAP } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/constants';

export function changeCurrency(currency) {
  return {
    type: CHANGE_CURRENCY,
    currency,
  };
}

export function setCurrencyExchangeRates(currencyExchangeMap) {
  return {
    type: SET_CURRENCY_EXCHANGE_MAP,
    currencyExchangeMap
  };
}
