import { dataFieldFlags, dataFieldTags } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/TagsFlagsFilter/constants';
import { findItems } from '@rentivo/gatsby-core/src/utils/arrays';

export const query = ({filterOptions, value}) => {
  if(!value || value.length === 0) return null;

  value = (Array.isArray(value)) ? value : [value];
  const query = {"bool": {"should": []}};

  const flags = findItems(filterOptions.options, (item) => value.includes(item.value) && item.type === 'flag');
  const tags = findItems(filterOptions.options, (item) => value.includes(item.value) && item.type === 'tag');

  const prefixSearch = filterOptions.prefixSearch;
  const prefixField = filterOptions.prefixField;

  

  flags.map((flag) => {
    query.bool.should.push({
      "term": {
        [`${dataFieldFlags}.${flag.value}`]: true
      }
    });
  });

  if(tags.length) {
    let should = [];
    if(prefixField && prefixSearch) {
      should = tags.map(tag => {
        return {
          "prefix": {
            [`${prefixField}.keyword`]: {
              "value": tag.value
            }
          }
        }
      });

      query.bool.should = should;
      
    } else {
      should = tags.map(tag => {
        return {
          "term": {
            "tags.value.keyword": tag.value
          }
        }
      });

      query.bool.should.push({
        "nested": {
          "path": dataFieldTags,
          "query": {
            "bool": {
              "should": should
            }
          }
        }
      });
    }
    
  
  }

  return query;
};

export const defaultQuery = query;
