import { useMemo } from "react";
import {
  selectBlocksFromPageQuery,
  selectBlocksFromHubQuery,
  selectBlocksFromPostQuery,
  selectFooterBlocksFromPageContext,
  selectUpdateBarBlocksFromPageContext,
  selectTopBarBlocksFromPageContext,
} from "@rentivo/gatsby-theme-barcelona/src/selectors/blocks";
import {
  shouldAddContainer,
  shouldShowPageHeader,
} from "@rentivo/gatsby-theme-barcelona/src/templates/Page/utils";
import { useIntl } from "react-intl";
import blogMessages from "@rentivo/gatsby-theme-barcelona/src/templates/Blog/messages";

export const useMenusAndBlocksData = ({ pageContext }) => {
  return useMemo(() => {
    const menus = pageContext?.menus ?? {};
    const footer = selectFooterBlocksFromPageContext(pageContext);
    const updateBar = selectUpdateBarBlocksFromPageContext(pageContext);
    const topBar = selectTopBarBlocksFromPageContext(pageContext);

    return {
      menuItems: {
        primaryMenu: menus.PRIMARY ? menus.PRIMARY : [],
        secondaryMenu: menus.SECONDARY ? menus.SECONDARY : [],
      },
      footer,
      updateBar,
      topBar,
    };
  }, []);
};

export const usePageData = (props) => {
  const menusAndBlocks = useMenusAndBlocksData(props);

  return useMemo(() => {
    const { data } = props;
    const showPageHeader = data?.wpPage?.pageOptions?.showPageHeader;
    const blocks = selectBlocksFromPageQuery(data);
    const isPageHeader = shouldShowPageHeader(showPageHeader, blocks);
    const isContained = shouldAddContainer(blocks);

    return {
      blocks,
      isPageHeader,
      isContained,
      menusAndBlocks,
    };
  }, [props.data]);
};

export const useHubData = (props) => {
  const menusAndBlocks = useMenusAndBlocksData(props);

  return useMemo(() => {
    const { data } = props;
    const showPageHeader = data?.wpHub?.pageOptions?.showPageHeader;
    const blocks = selectBlocksFromHubQuery(data);
    const isPageHeader = shouldShowPageHeader(showPageHeader, blocks);
    const isContained = shouldAddContainer(blocks);

    return {
      blocks,
      isPageHeader,
      isContained,
      menusAndBlocks,
    };
  }, [props.data]);
};

export const usePostsData = (props) => {
  const { formatMessage } = useIntl();
  const menusAndBlocks = useMenusAndBlocksData(props);

  return useMemo(() => {
    const { taxonomies, showFeatured, pageContext, title, seo, data } = props;
    const { lang } = pageContext;
    const {
      allWpCategory,
      allWpPost: { nodes: posts },
    } = data || {};

    const translatedCat = {
      nodes:
        allWpCategory && allWpCategory.nodes
          ? allWpCategory.nodes
              .filter((c) => c.ancestors === null)
              .map(({ translations, ...tax }) => {
                const [translatedTax] = translations.filter(
                  (t) => t.language.slug === lang
                );
                return translatedTax ? translatedTax : tax;
              })
          : [],
    };

    const values = { pageNumber: pageContext.pageNumber };
    const useTitle = !title
      ? formatMessage(blogMessages.pageTitle, values)
      : title;
    const useSeo = !seo
      ? {
          seoTitle: formatMessage(blogMessages.seoTitle, values),
          seoMetaDescription: formatMessage(
            blogMessages.seoMetaDescription,
            values
          ),
        }
      : seo;

    return {
      posts,
      taxonomies: taxonomies ? taxonomies : translatedCat,
      menusAndBlocks,
      useTitle,
      useSeo,
      useSeoImage:
        showFeatured &&
        posts.length &&
        posts[0] &&
        posts[0].featuredImage &&
        posts[0].featuredImage.node &&
        posts[0].featuredImage.node.sourceURL
          ? posts[0].featuredImage.node.sourceURL
          : null,
    };
  }, [props.data]);
};

export const usePostData = (props) => {
  const { data } = props;
  const menusAndBlocks = useMenusAndBlocksData(props);

  return useMemo(() => {
    const blocks = selectBlocksFromPostQuery(data);
    const isFreeForm =
      blocks &&
      blocks.length &&
      blocks[0].blockType &&
      blocks[0].blockType.name === "core/freeform";

    const showPageHeader = data?.wpPost?.pageOptions?.showPageHeader;
    const isPageHeader = shouldShowPageHeader(showPageHeader, blocks);
    const isContained = shouldAddContainer(blocks);
    return {
      menusAndBlocks,
      blocks,
      isPageHeader,
      isContained,
      isFreeForm,
    };
  }, [props.data]);
};
