export const numberFilterQuery = ({filterOptions: { queryFormat }, value }, field) => {
  //if(value === undefined) return null;

  const parsed = parseValueAndQueryFormat(value);
  value = (parsed && parsed.value) ? parsed.value : value;
  queryFormat = (parsed && parsed.queryFormat) ? parsed.queryFormat : queryFormat;

  let type = (queryFormat === 'exact') ? 'term' : 'range';
  let queryType = (queryFormat === 'exact') ? 'value' : queryFormat;

  let query = {
    [type]: {
      [field]: {
        [queryType]: value
      }
    }
  };

  if(queryFormat === '~') {
    query = {
      "range" : {
        [field]: {
          "gte": value,
          "lte": value + 1
        }
      }
    };
  }

  return query;
};

export const parseValueAndQueryFormat = (valueAndQuery, type = 'all') => {
  if(valueAndQuery === undefined) return null;
  valueAndQuery = `${valueAndQuery}`;
  const matched = valueAndQuery.match(/\d+/);
  const actualCollectionValue = (matched.length > 0) ? matched[0] : 0;
  let q = (actualCollectionValue > 0) ? valueAndQuery.replace(actualCollectionValue,'').trim() : 'exact';
  if(q === '+') q = 'gte';
  if(q === '-') q = 'lte';
  if(q === '~') q = '~';

  if(type === 'queryFormat') {
    return q;
  } else if(type === 'value') {
    return parseInt(actualCollectionValue);
  } else {
    return {
      value: parseInt(actualCollectionValue),
      queryFormat: q
    }
  }
};

export const buildValueAndQueryFormat = (value, queryFormat) => {
  if(queryFormat === 'gte') queryFormat = '+';
  if(queryFormat === 'lte') queryFormat = '-';
  if(queryFormat === '+' || queryFormat === '~' || queryFormat === '-') {
    return value + queryFormat;
  } else {
    return value;
  }
};
