import React from 'react';
import cdnProps from '@rentivo/gatsby-core/src/components/wpg/lazy/Featurette/cdn';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Flex, Heading, Text, Stack } from '@chakra-ui/react';
import { HEADING_LEVELS } from '@rentivo/gatsby-core/src/components/wpg/constants';
import { removePx } from '@rentivo/gatsby-core/src/utils/strings';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';

const FeaturetteBlock = ({attrs, className}) => {
  let {
    icon: imageRaw,
    iconType = 'none',
    subtitle,
    title,
    titleClass = '',
    subtitleClass = '',
    iconClass = '',
    layout = 'top',
    iconSize = 100,
    titleLevel = 4,
  } = attrs || {};

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_featurette');
  const { options: to, ...titleClassNameProps } = useClassNameProps(titleClass, 'wpg-b_featurette_title');
  const { options: sto, ...subtitleClassNameProps } = useClassNameProps(subtitleClass, 'wpg-b_featurette_subtitle');
  const { options: ito, className: iconClassName, ...iconClassNameProps } = useClassNameProps(iconClass, 'wpg-b_featurette_icon');

  const isTopLayout = (layout === 'top' || layout === 'topLeft' || layout === 'topRight');
  const isTopBottomOnlyLayout = (layout === 'bottom' || layout === 'top');
  const isTopBottomLayout = (layout === 'bottom' || isTopLayout);
  const isTopOrLeftLayout = (isTopLayout || layout === 'left');
  const isRightLayout = (layout === 'right' || layout === 'topRight');

  const headingAs = HEADING_LEVELS[titleLevel];

  let image = null;
  if(imageRaw) image = JSON.parse(decodeURIComponent(imageRaw));

  let aspect;
  let atMax = true;
  if(iconType === 'roundedSquare' || iconType === 'circle') {
    aspect = '1/1';
    atMax = false;
  }

  return (
    <Flex
      textAlign={isTopBottomOnlyLayout ? 'center' : isRightLayout ? 'right' : 'left'}
      direction={isTopBottomLayout ? 'column' : 'row'}
      align="center"
      {...classNameProps}
    >
      {image && (
        <Flex
          w={`${removePx(iconSize)}px`}
          maxH={`${1.5 * removePx(iconSize)}px`}
          order={isTopOrLeftLayout ? 0 : 1}
          mb={(isTopLayout) ? 4 : 0}
          mt={(layout === 'bottom') ? 4 : 0}
          mr={(layout === 'left') ? 4 : 0}
          ml={(layout === 'right') ? 4 : 0}
          alignSelf={isTopBottomOnlyLayout ? 'center' : isRightLayout ? 'flex-end' : 'flex-start'}
          css={{
            img: {
              borderRadius: (iconType === 'roundedSquare') ? '1rem' : (iconType === 'circle') ? '50%' : 0
            }
          }}
        >
          <CdnImage
            alt={title ? title : 'Featurette'}
            uri={image.url}
            {...cdnProps}
            width={iconSize}
            aspect={aspect}
            atMax={atMax}
            className={iconClassName}
            styleProps={iconClassNameProps}
            style={{objectFit: 'contain'}}
            {...ito}
          />
        </Flex>
      )}

      {(title || subtitle) && (
        <Flex
          order={isTopOrLeftLayout ? 1 : 0}
          w="100%"
          align="center"
          justify={isTopBottomOnlyLayout ? 'center' : isRightLayout ? 'flex-end' : 'flex-start'}
        >
          <Stack spacing={2}>
            {title && <Heading as={headingAs} variant="writing" {...titleClassNameProps}><RenderHtml html={title}/></Heading>}
            {subtitle && <Text color="textLight" variant="writing" {...subtitleClassNameProps}><RenderHtml html={subtitle}/></Text>}
          </Stack>
        </Flex>
      )}
    </Flex>
  );

};

export default FeaturetteBlock;
