import loadable from '@loadable/component';
import Card from '@rentivo/gatsby-core/src/components/wpg/lazy/Card';
import RowBlock from '@rentivo/gatsby-core/src/components/wpg/lazy/RowBlock';
import InnerRowBlock from '@rentivo/gatsby-core/src/components/wpg/lazy/InnerRowBlock';
import Featurette from '@rentivo/gatsby-core/src/components/wpg/lazy/Featurette';
import SectionTitle from '@rentivo/gatsby-core/src/components/wpg/lazy/SectionTitle';
import Menu from '@rentivo/gatsby-core/src/components/wpg/lazy/Menu';
import ThemeButtons from '@rentivo/gatsby-core/src/components/wpg/lazy/ThemeButtons';
import HeroGallery from '@rentivo/gatsby-core/src/components/wpg/lazy/HeroGallery';
import HeroVideo from '@rentivo/gatsby-core/src/components/wpg/lazy/HeroVideo';
import Box from '@rentivo/gatsby-core/src/components/wpg/lazy/Box';
import UpdateBar from '@rentivo/gatsby-core/src/components/wpg/lazy/UpdateBar';
import SearchBar from '@rentivo/gatsby-core/src/components/wpg/lazy/SearchBar';

//const HeroGallery = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/HeroGallery'));
//const Card = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/Card'));
//const RowBlock = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/RowBlock'));
//const InnerRowBlock = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/InnerRowBlock'));
//const Featurette = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/Featurette'));
//const SectionTitle = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/SectionTitle'));
//const Menu = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/Menu'));
//const ThemeButtons = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/ThemeButtons'));

//const SearchBar = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/SearchBar'));
const FeaturedProperties = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/FeaturedProperties'));
const FeaturedPosts = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/FeaturedPosts'));
const RecentBlogPosts = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/RecentBlogPosts'));
const Newsletter = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/Newsletter'));
const EnquiryForm = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/lazy/EnquiryForm'));


export default {
  SearchBar,
  HeroGallery,
  Card,
  RowBlock,
  Featurette,
  SectionTitle,
  FeaturedProperties,
  RecentBlogPosts,
  FeaturedPosts,
  Newsletter,
  Menu,
  ThemeButtons,
  InnerRowBlock,
  EnquiryForm,
  Box,
  UpdateBar,
  HeroVideo
};
