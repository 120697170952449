import { defineMessages } from 'react-intl';

export const scope = 'components.ui.GuestsPicker';

export default defineMessages({
  adultsLabel: {
    id: `${scope}.adults.label`,
    defaultMessage: 'Adults'
  },
  childrenLabel: {
    id: `${scope}.children.label`,
    defaultMessage: 'Children'
  },
  infantsLabel: {
    id: `${scope}.infants.label`,
    defaultMessage: 'Infants'
  },
  petsLabel: {
    id: `${scope}.pets.label`,
    defaultMessage: 'Pets'
  }
});
