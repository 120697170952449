export const DEFAULT_FILTER_OPTIONS = [
  {
    "urlValue": "children",
    "value": "SUITABILITY_CHILDREN_WELCOME"
  },
  {
    "urlValue": "elderly",
    "value": "SUITABILITY_ACCESSIBILITY_ELDERLY_GREAT"
  },
  {
    "urlValue": "pet_friendly",
    "value": "SUITABILITY_PETS_ALLOWED"
  },
  {
    "urlValue": "smoking",
    "value": "SUITABILITY_SMOKING_ALLOWED"
  },
  {
    "urlValue": "wheelchairs",
    "value": "SUITABILITY_ACCESSIBILITY_WHEELCHAIR_GREAT"
  }
];

export const nestedField = 'features';
export const dataField = 'features.type.keyword';
