import React from 'react';
import messages from '@rentivo/gatsby-core/src/containers/LanguageProvider/components/LocaleToggle/messages';
import LocaleToggle from '@rentivo/gatsby-core/src/components/generic/LocaleToggle';

const LocaleToggleComponent = ({onLocaleToggle, locale, languages, localesOnly, ...rest }) => {
  if(!languages || languages.length < 2) return null;

  return (
    <LocaleToggle
      value={locale}
      values={localesOnly}
      messages={messages}
      onToggle={onLocaleToggle}
      applyLocaleIcons={true}
      {...rest}
    />
  );
};

export default LocaleToggleComponent;
