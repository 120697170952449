import React from 'react'
import { GetTheBlock } from '@rentivo/gatsby-core/src/components/wpg/index';
import classNames from 'classnames';
import { Container } from '@chakra-ui/react';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const WPGBlocks = ({ blocks, mapToBlock, childProps = {} }) => {
  if(!blocks || !blocks.length) return null;
  return (
    <div className="wpg-blocks">
      {blocks.filter(block => !!block.name).map((block, index) => <WPGBlock key={index} block={block} mapToBlock={mapToBlock} childProps={childProps} />)}
    </div>
  )
};

export const WPGBlock = ({ block, mapToBlock, className, parentAttrs = {}, childProps = {}, color, ...rest }) => {
  const {
    name: blockName,
    attributes: attrs,
    attributesType: attrsType,
    innerBlocks,
    saveContent: innerHTML,
    originalContent: wrappedHTML
  } = block;

  if (!blockName) return null;

  if (mapToBlock) WPGBlock.MapToBlock = mapToBlock;

  let TheBlock = WPGBlock.MapToBlock ? WPGBlock.MapToBlock(blockName) : null;
  if (!TheBlock) TheBlock = GetTheBlock(blockName);

  if (!TheBlock) return null;

  const { className: attrClassName } = attrs;

  return (
    <TheBlock
      blockName={blockName}
      className={classNames(className, attrClassName, `wpg-block`)}
      attrs={attrs}
      attrsType={attrsType}
      innerBlocks={innerBlocks}
      innerHTML={innerHTML}
      wrappedHTML={wrappedHTML}
      parentAttrs={parentAttrs}
      childProps={childProps}
      {...rest}
    />
  );
};

export const Block = ({children, attrs, ...rest}) => {
  const { align = '', anchor = '' } = attrs || {};
  let props = {};
  switch (align) {
    case 'wide':
      props.maxW = 'container.xl';
      //props.width = 'calc(100% - 2rem)';
      break;
    case 'full':
      props.maxW = 'container.full';
      props.pl = 0;
      props.pr = 0;
      //props.width = '100%';
      break;
    default:
      props.maxW = 'container.lg';
      //props.width = 'calc(100% - 2rem)';
  }

  //console.log(stripNonStyles(rest));

  return (
    <Container id={anchor} {...props} {...stripNonStyles(rest)}>
      {children}
    </Container>
  )
};

export default WPGBlocks;
