import React from 'react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Block } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { Box } from '@chakra-ui/react';

const HtmlBlock = ({ innerHTML, attrs, className }) => {
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_html');
  return (
    <Box {...classNameProps}>
      <RenderHtml html={innerHTML}/>
    </Box>
  )
};

export default HtmlBlock;
