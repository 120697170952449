import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  makeSelectSearchFilters, makeSelectWithFilters, selectSearchFilters
} from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { startSetFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';
import { withStore } from '@rentivo/gatsby-core/src/context/StoreContext';
import { defaultTagValueTransformer } from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/tags';
import {
  selectSearchFilterConfigByKey,
  selectSearchFiltersConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils';
import { withSearchProviderContext } from '@rentivo/gatsby-core/src/context/SearchProviderContext';

/* TODO: Make this ref the object key and not URL param...*/
const withFilter = (filterConfigKey, setFilterFunction, filterTagValueFunction) => WrappedComponent => {

  const withFilterComponent = (props) => {

    let { startSetFilter, filterOptions, allFilters, setFilter, filter, filters = {}, store: { getState } } = props;
    if(!filterOptions || !filterOptions.active) return null;

    const reduxState = Object.freeze(getState());

    setFilter = (value, runQuery = true, from = 'search') => {
      const query = (setFilterFunction) ? setFilterFunction({filterOptions, allFilters, value, reduxState}) : null;
      const tagValue = (filterTagValueFunction) ? filterTagValueFunction({filterOptions, allFilters, value, reduxState}) : defaultTagValueTransformer(value);
      startSetFilter({filterOptions, value, tagValue, query, runQuery, from});
    };

    const filterProps = {
      setFilter,
      filterOptions,
      filter
    };

    let newProps = {
      ...props,
      ...filterProps,
      filters
    };

    newProps.filters[filterConfigKey] = filterProps;

    // More than one filter, remove the parent level values
    if(Object.keys(filters).length > 1) {
      delete newProps['setFilter'];
      delete newProps['filterOptions'];
      delete newProps['filter'];
    }

    // Clean up other props
    delete newProps['startSetFilter'];

    return <WrappedComponent {...newProps} />;
  };

  const mapStateToProps = () => createDeepEqualSelector(
    selectSearchFilters,
    selectSearchFiltersConfig,
    (filters, filtersConfig) => {
      const isArray = (Array.isArray(filterConfigKey) && filterConfigKey.length > 1);
      const filtersMatchingUrlParam = isArray ? filters.filter(f =>  filterConfigKey.includes(f.id)) : filters.filter(f => f.id === filterConfigKey);
      const filter = (isArray) ? filtersMatchingUrlParam : filtersMatchingUrlParam[0];
      const filterOptions = selectSearchFilterConfigByKey(filtersConfig, filterConfigKey);

      return {
        filterOptions,
        filter,
        allFilters: filters
      };
    });

  /*
  const mapStateToProps = () => createSelector(
    makeSelectSearchFiltersConfig(filterConfigKey),
    makeSelectSearchFilters(filterConfigKey),
    (filterOptions, filter) => ({
      filterOptions,
      filter
    })
  );*/

  //const mapStateToProps = makeSelectWithFilters(filterConfigKey);

  const mapDispatchToProps = (dispatch, { queryMap, tagValueMap }) => {
    return {
      startSetFilter: (options) => dispatch(startSetFilter({ queryMap, tagValueMap, ...options }))
    };
  };

  const enhance = compose(
    withStore,
    withSearchProviderContext,
    connect(mapStateToProps, mapDispatchToProps, null),
  );

  return enhance(withFilterComponent);

};

export default withFilter;
