import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

export const TagComponent = ({value}) => {
  const { formatMessage } = useIntl();

  return value === 'yes' ? formatMessage(messages.enabled) : formatMessage(messages.disabled);
};

export const tagValue = ({value}) => {
  return <TagComponent value={value}/>;
};

export const defaultTagValue = tagValue;
