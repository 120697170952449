import React from 'react';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import { Block } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Stack } from '@chakra-ui/react';

const ButtonComponent = (props) => {
  const { link, label, themeButtonType: type = 'default', themeButtonSize: size = 'medium', className = '', ...rest } = props;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_button');
  return (
    <Button
      {...rest}
      type={type}
      size={size}
      to={link}
      {...classNameProps}
    >
      {label}
    </Button>
  );
};

const ThemeButtonsBlock = ({attrs, className}) => {
  let { buttons, alignButtons = 'left', buttonsClassName = '' } = attrs;
  buttons = (buttons) ? JSON.parse(decodeURIComponent(buttons)) : [];
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_buttons');
  const { options: buttonsOptionsStack, ...buttonsStackClassNameProps } = useClassNameProps(buttonsClassName, 'wpg-b_buttons-stack');

  return (
    <Block attrs={attrs} pl={0} pr={0} {...classNameProps}>
      <Stack direction="row" spacing={2} mb={2} justify={alignButtons === 'center' ? 'center' : alignButtons === 'right' ? 'flex-end' : 'flex-start'} {...buttonsStackClassNameProps}>
        {buttons.map((button, i) => <ButtonComponent {...button} key={`wpg-b_button__${i}`}/>)}
      </Stack>
    </Block>
  );
};

export default ThemeButtonsBlock;
