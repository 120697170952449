import PropTypes from 'prop-types';
import NestedCollectionSelector from '@rentivo/gatsby-core/src/components/ui/NestedCollectionSelector/component';

NestedCollectionSelector.defaultProps = {
  width: 320,
  options: [],
  title: 'Select your choice below',
  onChange: () => null,
  onNext: () => null,
  onPrev: () => null,
  initialValueId: null,
  animationSpeed: 120
};

NestedCollectionSelector.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  initialValueId: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

export default NestedCollectionSelector;
