import loadable from '@loadable/component';
import Code from '@rentivo/gatsby-core/src/components/wpg/format/Code';
import Html from '@rentivo/gatsby-core/src/components/wpg/format/Html';
import Pullquote from '@rentivo/gatsby-core/src/components/wpg/format/Pullquote';
import Preformatted from '@rentivo/gatsby-core/src/components/wpg/format/Preformatted';

/*
const Code = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/format/Code'));
const Html = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/format/Html'));
const Preformatted = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/format/Preformatted'));
const Pullquote = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/format/Pullquote'));
*/

export default {
  Code,
  Html,
  Preformatted,
  Pullquote
}
