import React from 'react';
import { getMessageString } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';
import { useIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { selectTranslationsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { useSelector } from 'react-redux';
import { selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';

const selector = createSelector(
  selectTranslationsConfig,
  selectLang,
  (translations, lang) => ({translations, lang})
);

const LycanFormattedMessage = ({ message, fallback = null }) => {
  const { translations, lang } = useSelector(selector);
  const { formatMessage } = useIntl();
  return getMessageString(message, formatMessage, true, translations, lang, fallback);
};

export const useLycanFormattedMessage = (options) => {
  const { useFriendlyName = true } = options || {};
  const { translations, lang } = useSelector(selector);
  const { formatMessage } = useIntl();
  return (message, fallback) => getMessageString(message, formatMessage, useFriendlyName, translations, lang, fallback);
};

export default LycanFormattedMessage;
