import React from 'react';
import { FIELD_LYCAN_MESSAGE_MAP } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/DiscountsFilter/constants';
import { useIntl } from 'react-intl';
import {
  getMessageString
} from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';

export const TagComponent = ({value}) => {
  const { formatMessage } = useIntl();
  value = (Array.isArray(value)) ? value : [value]; // make it an array
  value = value.map(v => getMessageString(FIELD_LYCAN_MESSAGE_MAP[v], formatMessage)).join(', ');
  return value;
};

export const tagValue = ({value}) => {
  return <TagComponent value={value}/>;
};



export const defaultTagValue = tagValue;
