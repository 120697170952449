export const WPG_BUTTON_TYPE_PRIMARY = 'primary';
export const WPG_BUTTON_TYPE_SECONDARY = 'secondary';
export const WPG_BUTTON_TYPE_DEFAULT = 'default';
export const WPG_BUTTON_TYPE_WHITE = 'white';
export const WPG_BUTTON_TYPE_GHOST_PRIMARY = 'ghostPrimary';
export const WPG_BUTTON_TYPE_GHOST_SECONDARY = 'ghostSecondary';
export const WPG_BUTTON_TYPE_GHOST_DEFAULT = 'ghostDefault';
export const WPG_BUTTON_TYPE_GHOST_WHITE = 'ghostWhite';

export const WPG_BUTTON_TYPE_PROPS = {
  [WPG_BUTTON_TYPE_PRIMARY]: {
    type: 'primary'
  },
  [WPG_BUTTON_TYPE_SECONDARY]: {
    type: 'secondary'
  },
  [WPG_BUTTON_TYPE_DEFAULT]: {
    type: 'default'
  },
  [WPG_BUTTON_TYPE_WHITE]: {
    type: 'white'
  },
  [WPG_BUTTON_TYPE_GHOST_PRIMARY]: {
    type: 'primary',
    ghost: true
  },
  [WPG_BUTTON_TYPE_GHOST_SECONDARY]: {
    type: 'secondary',
    ghost: true
  },
  [WPG_BUTTON_TYPE_GHOST_DEFAULT]: {
    type: 'default',
    ghost: true
  },
  [WPG_BUTTON_TYPE_GHOST_WHITE]: {
    type: 'white',
    ghost: true
  }
};

export const WPG_ALIGN_FULL = 'full';
export const WPG_ALIGN_WIDE = 'wide';

export const HEADING_LEVELS = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6'
};
