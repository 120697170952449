import React from 'react';
//import { InView } from 'react-intersection-observer';
import { useInView } from 'react-intersection-observer';

const InViewComponent = ({verticalOffset, triggerOnce, children, el: El, fallback, className, initialInView}) => {
  const y = (Number.isInteger(verticalOffset)) ? `${verticalOffset}px` : verticalOffset;
  const [ref, inView, entry] = useInView({
    rootMargin: `${y} 0px`,
    triggerOnce,
    initialInView
  });

  //const isInView = entry ? inView : true;

  //console.log({isInView, entry, inView, initialInView, className});

  return <El ref={ref} className={className}>{inView ? children : fallback}</El>;
};

export default InViewComponent;


/*
<InView triggerOnce>
  {({ inView, ref }) => (
    <div ref={ref} style={{ width: '100%' }}>
      {inView && (
        <p>In View!</p>
      )}
    </div>
  )}
</InView>
 */
