import React, { useCallback } from 'react';
import { Box, Flex, useStyleConfig, Container, chakra, HStack } from '@chakra-ui/react';
import { useProps } from '@rentivo/gatsby-core/src/hooks/useProps';
import themeLogo from '@rentivo/gatsby-theme-barcelona/src/images/theme-logo.png';
import Logo from '@rentivo/gatsby-core/src/components/generic/Logo';
import CurrencyToggle from '@rentivo/gatsby-core/src/containers/CurrencyProvider/components/CurrencyToggle';
import { LOCALE_TOGGLE_TYPE_TEXT } from '@rentivo/gatsby-core/src/components/generic/LocaleToggle/constants';
import LocaleToggle from '@rentivo/gatsby-core/src/containers/LanguageProvider/components/LocaleToggle';
import Menu from '@rentivo/gatsby-core/src/components/navigation/Menu';
import BurgerDraw from '@rentivo/gatsby-core/src/components/navigation/BurgerDraw';
import { MENU_MODE_VERTICAL } from '@rentivo/gatsby-core/src/components/navigation/Menu/constants';

export const LEFT_LOGO = 'LEFT_LOGO';
export const CENTRAL_LOGO = 'CENTRAL_LOGO';

const defaultProps = {
  fluid: false,
  Logo: null,
  showCurrencyToggle: true,
  showLanguageToggle: true,
  primaryMenu: [],
  secondaryMenu: [],
  mobileMenu: [],
  PrimaryMenu: null,
  SecondaryMenu: null,
  MobileMenu: null
};

const HeaderNavbar = (p) => {
  const props = useProps(defaultProps, p);

  let { primaryMenu, secondaryMenu, showCurrencyToggle, showLanguageToggle, fluid, overrideLayout, publicNav } = props;
  let { mobileMenu } = props;
  mobileMenu = (mobileMenu.length === 0) ? [...primaryMenu, ...secondaryMenu] : mobileMenu;

  let { logo, header, container, type, layout = LEFT_LOGO } = useStyleConfig('HeaderNavbar', props);

  if (overrideLayout) {
    layout = overrideLayout;
  }

  if (publicNav) {
    showLanguageToggle = false;
    showCurrencyToggle = false;
  }

  if(fluid) {
    container.maxW = 'container.full';
  }

  const NavEnd = useCallback(({theme = type, ...props}) => {
    if(!showCurrencyToggle && !showLanguageToggle) return null;
    return (
      <HStack
        {...props}
        spacing={1}
        css={{
          '> *:not(style) ~ *:not(style)': {
            marginInlineStart: '0!important'
          }
        }}
      >
        {showCurrencyToggle && <CurrencyToggle theme={theme} type={LOCALE_TOGGLE_TYPE_TEXT}/>}
        {showLanguageToggle && <LocaleToggle theme={theme} themetype={LOCALE_TOGGLE_TYPE_TEXT}/>}
      </HStack>
    );
  }, [showCurrencyToggle, showLanguageToggle, type]);

  if(layout === CENTRAL_LOGO) {
    return (
      <Box
        position="relative"
        zIndex={1000}
        {...header}
      >
        <Container
          as={Flex}
          h={header.height}
          align="center"
          className="header-navbar-container central-logo"
          justifyContent="center"
          {...container}
        >
          {(primaryMenu.length > 0 && !publicNav) && (
            <chakra.div mr="auto" zIndex={1} display={{base: 'none', lg: 'flex'}}>
              <Menu theme={type} menuItems={primaryMenu} />
            </chakra.div>
          )}

          <Flex
            mx="auto"
            w="100%"
            position={{base: 'relative', lg: 'absolute'}}
            justify={{base: 'flex-start', lg: 'center'}}
          >
            <Logo type={type} defaultImage={themeLogo} {...logo} />
          </Flex>

          {(secondaryMenu.length > 0 && !publicNav) && (
            <chakra.div display={{base: 'none', lg: 'flex'}}>
              <Menu theme={type} menuItems={secondaryMenu} />
            </chakra.div>
          )}

          <chakra.div ml={2}>
            <NavEnd display={{base: 'none', lg: 'block'}} />

            <chakra.div
              display={{base: 'block', lg: 'none'}}
            >
              <BurgerDraw theme={type}>
                <NavEnd ml="0.5rem" theme="light"/>
                {(mobileMenu.length > 0) && <Menu theme="light" menuItems={mobileMenu} mode={MENU_MODE_VERTICAL} />}
              </BurgerDraw>
            </chakra.div>
          </chakra.div>


        </Container>
      </Box>
    );
  } else {
    return (
      <Box
        position="relative"
        zIndex={1000}
        {...header}
      >
        <Container
          as={Flex}
          h={header.height}
          align="center"
          className="header-navbar-container"
          {...container}
        >
          <chakra.div
            mr={6}
          >
            <Logo type={type} defaultImage={themeLogo} {...logo} />
          </chakra.div>

          {(primaryMenu.length > 0 || secondaryMenu.length > 0) && (
            <Flex
              flexGrow="1"
              justify="space-between"
              display={{base: 'none', lg: 'flex'}}
            >
              {(primaryMenu.length > 0) && <Menu theme={type} menuItems={primaryMenu} />}
              {(secondaryMenu.length > 0) && <Menu theme={type} menuItems={secondaryMenu} />}
            </Flex>
          )}

          <NavEnd ml={1} display={{base: 'none', lg: 'block'}} />

          <chakra.div
            ml="auto"
            display={{base: 'block', lg: 'none'}}
          >
            <BurgerDraw theme={type}>
              <NavEnd ml="0.5rem" theme="light"/>
              {(mobileMenu.length > 0) && <Menu theme="light" menuItems={mobileMenu} mode={MENU_MODE_VERTICAL} />}
            </BurgerDraw>
          </chakra.div>

        </Container>
      </Box>
    );
  }
};

export default HeaderNavbar;
