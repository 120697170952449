import BurgerDraw from '@rentivo/gatsby-core/src/components/navigation/BurgerDraw/component';
import { withTheme } from '@emotion/react';

BurgerDraw.defaultProps ={
  width: 300,
  theme: 'light',
  placement: 'right'
};

export default withTheme(BurgerDraw);
