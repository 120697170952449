import React, { useEffect, useState, useRef } from 'react';
import { window } from 'browser-monads';

const GoogleApiWrapperComponent = ({onLoad, loadGoogle, loading, children}) => {
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    loadGoogle((loaded) => {
      if(isMountedRef.current) {
        onLoad();
        setGoogleLoaded((loaded));
      }
    });

    return () => isMountedRef.current = false;
  }, []);

  if(googleLoaded || window.google) {
    return children;
  } else {
    return loading;
  }

};

export default GoogleApiWrapperComponent;

/*
<Helmet>
        <script
          key="google-maps-api"
          src={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&amp;libraries=places`}
        />
      </Helmet>


<Helmet>
  <script
    key="google-maps-api"
    src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBpUyxFM1ORsk7scdoW54MtLiq1pfFYFMw&libraries=places`}
    async
    defer
  />
</Helmet>
 */
