import React from 'react';
import LocaleToggle from '@rentivo/gatsby-core/src/components/generic/LocaleToggle';
import messages from '@rentivo/gatsby-core/src/containers/CurrencyProvider/components/CurrencyToggle/messages';

const CurrencyToggleComponent = ({changeCurrency, theme, currency, currenciesList, ...rest}) => {
  return (
    <LocaleToggle
      value={currency}
      values={currenciesList}
      messages={messages}
      onToggle={changeCurrency}
      applyLocaleIcons={false}
      theme={theme}
      {...rest}
    />
  );
};

export default CurrencyToggleComponent;
