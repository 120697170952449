import React from 'react';
import PropTypes from 'prop-types';
import Logo from '@rentivo/gatsby-core/src/components/generic/Logo/component';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectSiteLogoImageHeightConfig,
  selectSiteLogoImageTypesDarkConfig,
  selectSiteLogoImageTypesDarkMobileConfig,
  selectSiteLogoImageTypesDefaultConfig,
  selectSiteLogoImageTypesDefaultMobileConfig,
  selectSiteLogoImageTypesLightConfig,
  selectSiteLogoImageTypesLightMobileConfig, selectSiteLogoImageWidthConfig,
  selectSiteLogoTextConfig,
  selectSiteLogoUseImageConfig,
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { LOGO_TYPE_DARK, LOGO_TYPE_DEFAULT, LOGO_TYPE_LIGHT } from '@rentivo/gatsby-core/src/components/generic/Logo/constants';
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext';

const mapStateToProps = createSelector(
  selectSiteLogoTextConfig,
  selectSiteLogoUseImageConfig,
  selectSiteLogoImageWidthConfig,
  selectSiteLogoImageHeightConfig,
  selectSiteLogoImageTypesDefaultConfig,
  selectSiteLogoImageTypesDefaultMobileConfig,
  selectSiteLogoImageTypesLightConfig,
  selectSiteLogoImageTypesLightMobileConfig,
  selectSiteLogoImageTypesDarkConfig,
  selectSiteLogoImageTypesDarkMobileConfig,
  (text, useImage, imageWidth, imageHeight, imageDefault, imageDefaultMobile, imageLight, imageLightMobile, imageDark, imageDarkMobile) => ({
    text,
    useImage,
    imageWidth,
    imageHeight,
    imageDefault,
    imageDefaultMobile,
    imageLight,
    imageLightMobile,
    imageDark,
    imageDarkMobile
  })
);

const enhance = compose(
  withResponsive,
  connect(mapStateToProps)
);

Logo.defaultProps = {
  type: LOGO_TYPE_DEFAULT,
  defaultText: 'Rentivo Website'
};

Logo.propTypes = {
  type: PropTypes.oneOf([LOGO_TYPE_DEFAULT, LOGO_TYPE_LIGHT, LOGO_TYPE_DARK]),
  defaultImage: PropTypes.string,
  defaultText: PropTypes.string
};

export default enhance(Logo);


