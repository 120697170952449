export const getChildren = (item) => {
  const { childItems: items } = item;
  return (items && items.nodes && items.nodes.length > 0) ? items.nodes : false;
};

export const isMenuGroup = (item) => {
  const { label, url } = item;
  const hasMenuGroup = get(item, 'extraFields.isMenuGroup', false);
  const children = getChildren(item);
  return ((url === '#' || hasMenuGroup) && children);
};

export const isMenuText = (item) => {
  const { url } = item;
  return (url === '#');
};
