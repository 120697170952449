import {
  REQUEST_ES_DATA,
  RECEIVE_ES_DATA,
  INVALIDATE_ES_DATA,
  CLEAR_ITEM
} from '@rentivo/gatsby-core/src/containers/ESProvider/constants';

export function requestData(key) {
  return {
    type: REQUEST_ES_DATA,
    key
  };
}

export function receiveData(key, data) {
  return {
    type: RECEIVE_ES_DATA,
    key,
    data
  };
}

export function resetData(key, data = null) {
  return {
    type: RECEIVE_ES_DATA,
    key,
    data
  };
}

export function invalidateData(key, error) {
  return {
    type: INVALIDATE_ES_DATA,
    error,
    key
  };
}

export function clearItem(key) {
  return {
    type: CLEAR_ITEM,
    key
  };
}
