import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/messages';
import { getFriendlyLycanName } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';

export const tagValue = ({value}) => {
  if(!value) return null;
  return (
    <span>
      {messages[value] ? <FormattedMessage {...messages[value]}/> : getFriendlyLycanName(value)}
    </span>
  );
};

export const defaultTagValue = tagValue;
