import React from 'react';
import { Block, WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import {
  WPG_ROW_TYPE_DEFAULT,
  WPG_ROW_TYPE_LARGE_PADDING
} from '@rentivo/gatsby-core/src/components/wpg/lazy/RowBlock/constants';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

const RowBlockBlock = ({attrs, innerBlocks, className, childProps}) => {
  const {
    align = '',
    bgColor = '',
    blockId = undefined,
    flush,
    useLightText = false,
    smallerFontSize = false,
    rowType = WPG_ROW_TYPE_DEFAULT,
    useThemeHighlight = false,
    anchor = ''
  } = attrs;

  childProps = { ...childProps, rowAlign: align };

  let extraProps = {};

  if(flush) {
    extraProps = {
      ...extraProps,
      pr: 0,
      pl: 0
    }
  }

  if(rowType === WPG_ROW_TYPE_DEFAULT) {
    extraProps = {
      ...extraProps,
      pt: 6,
      pb: 6
    }
  }

  if(rowType === WPG_ROW_TYPE_LARGE_PADDING) {
    extraProps = {
      ...extraProps,
      pt: { base: 8, md: 16 },
      pb: { base: 8, md: 16 }
    }
  }

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_row-block', { ['normal-text']: (smallerFontSize), 'light-text': (useLightText) });

  if(useLightText) {
    childProps = { ...childProps, color: "white" };
  }

  //console.log(classNameProps);

  return (
    <Block
      id={anchor ? anchor : blockId}
      attrs={attrs}
      bg={useThemeHighlight ? 'gray.100' : (bgColor) ? bgColor : 'white'}
      {...extraProps}
      {...classNameProps}
      //py={20}
    >
      {innerBlocks.map((block, i) => <WPGBlock key={i} block={block} childProps={childProps}/>)}
    </Block>
  );
};

export default RowBlockBlock;
