import React, { useMemo } from 'react';
import { compileHtml } from '@rentivo/gatsby-core/src/components/generic/RenderHtml/utils';
import { decodeHTMLEntities, hasHtml } from '@rentivo/gatsby-core/src/utils/strings';

const RenderHtmlComponent = ({html, options = {}}) => {
  return useMemo(() => {
    // https://www.semicolonworld.com/question/72532/react-app-rendering-html-entities-such-as-ampersand-as-escaped
    html = decodeHTMLEntities(html);

    if(hasHtml(html)) {
      const {contents} = compileHtml(html, options);
      return contents;
    } else {
      return html;
    }

  }, [html]);
};

export default RenderHtmlComponent;
