import React from 'react';
import { compose } from 'redux';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage/component';
import { connect } from 'react-redux';
import { makeSelectCdnImageUrl } from '@rentivo/gatsby-core/src/components/ui/CdnImage/selectors';
import { cdnDefaultProps } from '@rentivo/gatsby-core/src/components/ui/CdnImage/constants';

const enhance = compose(
  connect(makeSelectCdnImageUrl)
);

CdnImage.defaultProps = cdnDefaultProps;
/*
CdnImage.propTypes = {
  ogImage: PropTypes.bool,
  twitterImage: PropTypes.bool,
  uri: PropTypes.string.isRequired,
  alt: PropTypes.string,
  href: PropTypes.string,
  linkTarget: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  atMax: PropTypes.bool,
  atMin: PropTypes.bool,
  aspect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  expandAspectChild: PropTypes.bool,
  quality: PropTypes.number,
  focus: PropTypes.oneOf(['center', 'auto', 'top', 'left', 'bottom', 'right', 'top_left', 'top_right', 'bottom_left', 'bottom_right']),
  fallback: PropTypes.oneOf(PropTypes.string),
  fadeIn: PropTypes.bool,
  durationFadeIn: PropTypes.number,
  verticalOffset: PropTypes.number,
  useReactImage: PropTypes.bool,
  useGatsbyImage: PropTypes.bool,
  useProgressiveImage: PropTypes.bool,
  useProgressiveImageAspect: PropTypes.bool,
  styleProps: PropTypes.object
};*/

export default enhance(CdnImage);
