import { useEffect, useRef } from 'react';
import isEqualLodash from 'lodash/isEqual';

export const useComparatorRef = (value, isEqual, onChange) => {
  const ref = useRef(value);
  useEffect(() => {
    if (!isEqual(value, ref.current)) {
      ref.current = value;
      if (onChange) {
        onChange();
      }
    }
  });
  return ref;
};

const isEqual = (v1, v2) => {
  const bothNull = !v1 && !v2;
  const equal = !!v1 && !!v2 && isEqualLodash(v1, v2);
  return bothNull || equal;
};

export const useIsEqualRef = (value, onChange) => {
  return useComparatorRef(value, isEqual, onChange);
};

export const useIsLodashEqualRef = (value, onChange) => {
  return useComparatorRef(value, isEqualLodash, onChange);
};

export const useIdentifyRef = (value, onChange) => {
  return useComparatorRef(value, (v1, v2) => v1 === v2, onChange);
};

export const mergeRefs = (refs) => {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ref.current = value
      }
    })
  }
};
