import React from 'react';
import { compose } from 'redux';
import GuestsFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilterPopover/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { defaultButtonProps } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/props';

const enhance = compose(
  withFilter('guests'),
);

GuestsFilterPopover.defaultProps = {
  className: 'filter-popover',
  setFilterFrom: 'search',
  filterComponent: null,
  runQuery: true,
  scrollTo: false,
  button: defaultButtonProps
};

export default enhance(GuestsFilterPopover);
