import { chakra, keyframes } from '@chakra-ui/react';

export const StyledLoaderSpinner = chakra('div', {
  baseStyle: {
    position: "absolute",
    inset: "0px",
    color: "primary.500",
    fontSize: "40px",
    bgColor: "rgba(255,255,255,0.2)",
    zIndex: 100,
    bottom: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center"
  }
});

const skeletonLoading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const StyledLoaderBg = chakra('div', {
  baseStyle: {
    position: "absolute",
    inset: "0px",
    backgroundImage: "linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%)",
    backgroundSize: "400% 100%",
    animation: `${skeletonLoading} infinite 1.4s ease`
  }
});
