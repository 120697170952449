import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters.LocationFilter';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Location'
  },
  placeholder: {
    id: `${scope}.placeholder`,
    defaultMessage: 'Where do you want to go?'
  },
});
