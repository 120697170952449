import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilterPopover/messages';
import Component from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter';
import { StyledFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/styles';

const GuestsFilterPopoverComponent = ({filterComponent, title, scrollTo, button, runQuery, setFilterFrom, filterOptions, filter, className, styleProps = {}, ...rest}) => {
  const [visible, setVisible] = useState(false);
  const value = (filter && filter.value) ? filter.value : filterOptions.defaultValue;

  return (
    <StyledFilter className={className} {...styleProps}>
      {title === undefined ? '' : title === true ? <label><FormattedMessage {...messages.title}/></label> : title}
      <PopoverButton
        component={filterComponent ? filterComponent : Component}
        componentProps={{runQuery, setFilterFrom}}
        button={button}
        scrollTo={scrollTo}
        visible={visible}
        onVisibleChange={() => setVisible(!visible)}
        buttonLabel={<FormattedMessage {...messages.setLabel} values={{value}}/>}
      />
    </StyledFilter>
  );
};

export default GuestsFilterPopoverComponent;

