/*
 * Lycan Messages
 *
 */
import { defineMessages } from 'react-intl';

export const scope = 'containers.LycanMessages';

export default defineMessages({
  ACCESSIBILITY_DISABLED_TOILET : {
    id: `${scope}.ACCESSIBILITY_DISABLED_TOILET`,
    defaultMessage: 'Disabled Toilet',
  },
  ACCESSIBILITY_ENTRANCE_HAS_STAIRS : {
    id: `${scope}.ACCESSIBILITY_ENTRANCE_HAS_STAIRS`,
    defaultMessage: 'Entrance Has Stairs',
  },
  ACCESSIBILITY_HOME_STEP_FREE_ACCESS : {
    id: `${scope}.ACCESSIBILITY_HOME_STEP_FREE_ACCESS`,
    defaultMessage: 'Home Step Free Access',
  },
  ACCESSIBILITY_GROUNDFLOOR_BATHROOM : {
    id: `${scope}.ACCESSIBILITY_GROUNDFLOOR_BATHROOM`,
    defaultMessage: 'Groundfloor Bathroom',
  },
  ACCESSIBILITY_GROUNDFLOOR_BEDROOM : {
    id: `${scope}.ACCESSIBILITY_GROUNDFLOOR_BEDROOM`,
    defaultMessage: 'Groundfloor Bedroom',
  },
  ACCESSIBILITY_GROUNDFLOOR_TOILET : {
    id: `${scope}.ACCESSIBILITY_GROUNDFLOOR_TOILET`,
    defaultMessage: 'Groundfloor Toilet',
  },
  ACCESSIBILITY_INSIDE_HAS_STAIRS : {
    id: `${scope}.ACCESSIBILITY_INSIDE_HAS_STAIRS`,
    defaultMessage: 'Inside Has Stairs',
  },
  ACCESSIBILITY_WALKIN_SHOWER : {
    id: `${scope}.ACCESSIBILITY_WALKIN_SHOWER`,
    defaultMessage: 'Walkin Shower',
  },
  ACCESSIBILITY_WHEELCHAIR_LIFT : {
    id: `${scope}.ACCESSIBILITY_WHEELCHAIR_LIFT`,
    defaultMessage: 'Wheelchair Lift',
  },
  ACCESSIBILITY_SINGLE_LEVEL_HOME : {
    id: `${scope}.ACCESSIBILITY_SINGLE_LEVEL_HOME`,
    defaultMessage: 'Single Level Home',
  },
  ACCESSIBILITY_HOME_WIDE_DOORWAY_CLEARANCE : {
    id: `${scope}.ACCESSIBILITY_HOME_WIDE_DOORWAY_CLEARANCE`,
    defaultMessage: 'Home Wide Doorway Clearance',
  },
  ACCESSIBILITY_WIDE_HALLWAY_CLEARANCE : {
    id: `${scope}.ACCESSIBILITY_WIDE_HALLWAY_CLEARANCE`,
    defaultMessage: 'Wide Hallway Clearance',
  },
  ACCESSIBILITY_FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR : {
    id: `${scope}.ACCESSIBILITY_FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR`,
    defaultMessage: 'Flat Smooth Pathway To Front Door',
  },
  ACCESSIBILITY_DISABLED_PARKING_SPOT : {
    id: `${scope}.ACCESSIBILITY_DISABLED_PARKING_SPOT`,
    defaultMessage: 'Disabled Parking Spot',
  },
  ACCESSIBILITY_GRAB_RAILS_IN_SHOWER_AND_TOILET : {
    id: `${scope}.ACCESSIBILITY_GRAB_RAILS_IN_SHOWER_AND_TOILET`,
    defaultMessage: 'Grab Rails In Shower And Toilet',
  },
  ACCESSIBILITY_ROLLIN_SHOWER_WITH_BENCH : {
    id: `${scope}.ACCESSIBILITY_ROLLIN_SHOWER_WITH_BENCH`,
    defaultMessage: 'Rollin Shower With Bench',
  },
  ACCESSIBILITY_COMMON_SPACE_STEP_FREE_ACCESS : {
    id: `${scope}.ACCESSIBILITY_COMMON_SPACE_STEP_FREE_ACCESS`,
    defaultMessage: 'Common Space Step Free Access',
  },
  ACCESSIBILITY_COMMON_SPACE_WIDE_DOORWAY_CLEARANCE : {
    id: `${scope}.ACCESSIBILITY_COMMON_SPACE_WIDE_DOORWAY_CLEARANCE`,
    defaultMessage: 'Common Space Wide Doorway Clearance',
  },
  ATTRACTIONS_ARBORETUM : {
    id: `${scope}.ATTRACTIONS_ARBORETUM`,
    defaultMessage: 'Arboretum',
  },
  ATTRACTIONS_ARCHAEOLOGICAL_SITES : {
    id: `${scope}.ATTRACTIONS_ARCHAEOLOGICAL_SITES`,
    defaultMessage: 'Archaeological Sites',
  },
  ATTRACTIONS_BOTANICAL_GARDEN : {
    id: `${scope}.ATTRACTIONS_BOTANICAL_GARDEN`,
    defaultMessage: 'Botanical Garden',
  },
  ATTRACTIONS_CAVE : {
    id: `${scope}.ATTRACTIONS_CAVE`,
    defaultMessage: 'Cave',
  },
  ATTRACTIONS_FESTIVALS : {
    id: `${scope}.ATTRACTIONS_FESTIVALS`,
    defaultMessage: 'Festivals',
  },
  ATTRACTIONS_HISTORICAL_MONUMENTS : {
    id: `${scope}.ATTRACTIONS_HISTORICAL_MONUMENTS`,
    defaultMessage: 'Historical Monuments',
  },
  ATTRACTIONS_HISTORICAL_RUINS : {
    id: `${scope}.ATTRACTIONS_HISTORICAL_RUINS`,
    defaultMessage: 'Historical Ruins',
  },
  ATTRACTIONS_NUDE_BEACH : {
    id: `${scope}.ATTRACTIONS_NUDE_BEACH`,
    defaultMessage: 'Nude Beach',
  },
  ATTRACTIONS_RAIN_FORESTS : {
    id: `${scope}.ATTRACTIONS_RAIN_FORESTS`,
    defaultMessage: 'Rain Forests',
  },
  ATTRACTIONS_REEF : {
    id: `${scope}.ATTRACTIONS_REEF`,
    defaultMessage: 'Reef',
  },
  ATTRACTIONS_THEME_PARK : {
    id: `${scope}.ATTRACTIONS_THEME_PARK`,
    defaultMessage: 'Theme Park',
  },
  ATTRACTIONS_WATER_THEME_PARK : {
    id: `${scope}.ATTRACTIONS_WATER_THEME_PARK`,
    defaultMessage: 'Water Theme Park',
  },
  ATTRACTIONS_VOLCANO : {
    id: `${scope}.ATTRACTIONS_VOLCANO`,
    defaultMessage: 'Volcano',
  },
  ATTRACTIONS_WATERFALLS : {
    id: `${scope}.ATTRACTIONS_WATERFALLS`,
    defaultMessage: 'Waterfalls',
  },
  ATTRACTIONS_ZOO : {
    id: `${scope}.ATTRACTIONS_ZOO`,
    defaultMessage: 'Zoo',
  },
  CHILDREN_BABY_ANTI_SLIP_BATH_MAT : {
    id: `${scope}.CHILDREN_BABY_ANTI_SLIP_BATH_MAT`,
    defaultMessage: 'Baby Anti Slip Bath Mat',
  },
  CHILDREN_BABY_BATH_TOYS : {
    id: `${scope}.CHILDREN_BABY_BATH_TOYS`,
    defaultMessage: 'Baby Bath Toys',
  },
  CHILDREN_BABY_BATH : {
    id: `${scope}.CHILDREN_BABY_BATH`,
    defaultMessage: 'Baby Bath',
  },
  CHILDREN_BABY_BED_GUARDS : {
    id: `${scope}.CHILDREN_BABY_BED_GUARDS`,
    defaultMessage: 'Baby Bed Guards',
  },
  CHILDREN_BABY_BOOSTER_SEAT : {
    id: `${scope}.CHILDREN_BABY_BOOSTER_SEAT`,
    defaultMessage: 'Baby Booster Seat',
  },
  CHILDREN_BABY_CHANGING_MAT : {
    id: `${scope}.CHILDREN_BABY_CHANGING_MAT`,
    defaultMessage: 'Baby Changing Mat',
  },
  CHILDREN_BABY_CHANGING_TABLE : {
    id: `${scope}.CHILDREN_BABY_CHANGING_TABLE`,
    defaultMessage: 'Baby Changing Table',
  },
  CHILDREN_BABY_COT_LINEN : {
    id: `${scope}.CHILDREN_BABY_COT_LINEN`,
    defaultMessage: 'Baby Cot Linen',
  },
  CHILDREN_BABY_COT : {
    id: `${scope}.CHILDREN_BABY_COT`,
    defaultMessage: 'Baby Cot',
  },
  CHILDREN_FIREPLACE_GUARDS : {
    id: `${scope}.CHILDREN_FIREPLACE_GUARDS`,
    defaultMessage: 'Fireplace Guards',
  },
  CHILDREN_ROOM_DARKENING_SHADES : {
    id: `${scope}.CHILDREN_ROOM_DARKENING_SHADES`,
    defaultMessage: 'Room Darkening Shades',
  },
  CHILDREN_TABLE_CORNER_GUARDS : {
    id: `${scope}.CHILDREN_TABLE_CORNER_GUARDS`,
    defaultMessage: 'Table Corner Guards',
  },
  CHILDREN_WINDOW_GUARDS : {
    id: `${scope}.CHILDREN_WINDOW_GUARDS`,
    defaultMessage: 'Window Guards',
  },
  CHILDREN_BABY_CRECH : {
    id: `${scope}.CHILDREN_BABY_CRECH`,
    defaultMessage: 'Baby Crech',
  },
  CHILDREN_BABY_DEN : {
    id: `${scope}.CHILDREN_BABY_DEN`,
    defaultMessage: 'Baby Den',
  },
  CHILDREN_BABY_DVDS : {
    id: `${scope}.CHILDREN_BABY_DVDS`,
    defaultMessage: 'Baby Dvds',
  },
  CHILDREN_BABY_ELECTRIC_SOCKET_GUARDS : {
    id: `${scope}.CHILDREN_BABY_ELECTRIC_SOCKET_GUARDS`,
    defaultMessage: 'Baby Electric Socket Guards',
  },
  CHILDREN_BABY_HIGHCHAIR : {
    id: `${scope}.CHILDREN_BABY_HIGHCHAIR`,
    defaultMessage: 'Baby Highchair',
  },
  CHILDREN_BABY_INDOOR_TOYS : {
    id: `${scope}.CHILDREN_BABY_INDOOR_TOYS`,
    defaultMessage: 'Baby Indoor Toys',
  },
  CHILDREN_BABY_KIDS_CLUB : {
    id: `${scope}.CHILDREN_BABY_KIDS_CLUB`,
    defaultMessage: 'Baby Kids Club',
  },
  CHILDREN_BABY_KITCHEN_FOOD_BLENDER : {
    id: `${scope}.CHILDREN_BABY_KITCHEN_FOOD_BLENDER`,
    defaultMessage: 'Baby Food Blender',
  },
  CHILDREN_BABY_KITCHEN_PLASTIC_CROCKERY : {
    id: `${scope}.CHILDREN_BABY_KITCHEN_PLASTIC_CROCKERY`,
    defaultMessage: 'Baby Plastic Crockery',
  },
  CHILDREN_BABY_KITCHEN_STERILSER : {
    id: `${scope}.CHILDREN_BABY_KITCHEN_STERILSER`,
    defaultMessage: 'Baby Sterilser',
  },
  CHILDREN_BABY_LOO_SEAT : {
    id: `${scope}.CHILDREN_BABY_LOO_SEAT`,
    defaultMessage: 'Baby Loo Seat',
  },
  CHILDREN_BABY_MONITOR : {
    id: `${scope}.CHILDREN_BABY_MONITOR`,
    defaultMessage: 'Baby Monitor',
  },
  CHILDREN_BABY_NIGHTLIGHT : {
    id: `${scope}.CHILDREN_BABY_NIGHTLIGHT`,
    defaultMessage: 'Baby Nightlight',
  },
  CHILDREN_BABY_PACK : {
    id: `${scope}.CHILDREN_BABY_PACK`,
    defaultMessage: 'Baby Pack',
  },
  CHILDREN_BABY_PACK_N_PLAY_TRAVEL_CRIB : {
    id: `${scope}.CHILDREN_BABY_PACK_N_PLAY_TRAVEL_CRIB`,
    defaultMessage: 'Baby Pack N Play Travel Crib',
  },
  CHILDREN_BABY_SITTING : {
    id: `${scope}.CHILDREN_BABY_SITTING`,
    defaultMessage: 'Baby Sitting',
  },
  CHILDREN_BABY_STAIRGATE : {
    id: `${scope}.CHILDREN_BABY_STAIRGATE`,
    defaultMessage: 'Baby Stairgate',
  },
  COMMUNICATION_COMPUTER : {
    id: `${scope}.COMMUNICATION_COMPUTER`,
    defaultMessage: 'Computer',
  },
  COMMUNICATION_INTERNET : {
    id: `${scope}.COMMUNICATION_INTERNET`,
    defaultMessage: 'Internet',
  },
  COMMUNICATION_TELEPHONE : {
    id: `${scope}.COMMUNICATION_TELEPHONE`,
    defaultMessage: 'Telephone',
  },
  ENTERTAINMENT_AMAZON_ECHO : {
    id: `${scope}.ENTERTAINMENT_AMAZON_ECHO`,
    defaultMessage: 'Amazon Echo',
  },
  ENTERTAINMENT_BOARD_GAMES : {
    id: `${scope}.ENTERTAINMENT_BOARD_GAMES`,
    defaultMessage: 'Board Games',
  },
  ENTERTAINMENT_BOOKS_LIBRARY : {
    id: `${scope}.ENTERTAINMENT_BOOKS_LIBRARY`,
    defaultMessage: 'Books Library',
  },
  ENTERTAINMENT_TV : {
    id: `${scope}.ENTERTAINMENT_TV`,
    defaultMessage: 'Tv',
  },
  ENTERTAINMENT_CABLE_TV : {
    id: `${scope}.ENTERTAINMENT_CABLE_TV`,
    defaultMessage: 'Cable Tv',
  },
  ENTERTAINMENT_HIGH_DEFINITION_TV : {
    id: `${scope}.ENTERTAINMENT_HIGH_DEFINITION_TV`,
    defaultMessage: 'High Definition Tv',
  },
  ENTERTAINMENT_SATELLITE_TV : {
    id: `${scope}.ENTERTAINMENT_SATELLITE_TV`,
    defaultMessage: 'Satellite Tv',
  },
  ENTERTAINMENT_STANDARD_DEFINITION_TV : {
    id: `${scope}.ENTERTAINMENT_STANDARD_DEFINITION_TV`,
    defaultMessage: 'Standard Definition Tv',
  },
  ENTERTAINMENT_CHILDRENS_TOYS : {
    id: `${scope}.ENTERTAINMENT_CHILDRENS_TOYS`,
    defaultMessage: 'S Toys',
  },
  ENTERTAINMENT_DVD_PLAYER : {
    id: `${scope}.ENTERTAINMENT_DVD_PLAYER`,
    defaultMessage: 'Dvd Player',
  },
  ENTERTAINMENT_FOOSBALL : {
    id: `${scope}.ENTERTAINMENT_FOOSBALL`,
    defaultMessage: 'Foosball',
  },
  ENTERTAINMENT_GAMES_CONSOLE : {
    id: `${scope}.ENTERTAINMENT_GAMES_CONSOLE`,
    defaultMessage: 'Games Console',
  },
  ENTERTAINMENT_GAMES_ROOM : {
    id: `${scope}.ENTERTAINMENT_GAMES_ROOM`,
    defaultMessage: 'Games Room',
  },
  ENTERTAINMENT_GOOGLE_HOME : {
    id: `${scope}.ENTERTAINMENT_GOOGLE_HOME`,
    defaultMessage: 'Google Home',
  },
  ENTERTAINMENT_HOME_CINEMA : {
    id: `${scope}.ENTERTAINMENT_HOME_CINEMA`,
    defaultMessage: 'Home Cinema',
  },
  ENTERTAINMENT_MOVIE_VIDEO_LIBRARY : {
    id: `${scope}.ENTERTAINMENT_MOVIE_VIDEO_LIBRARY`,
    defaultMessage: 'Movie Video Library',
  },
  ENTERTAINMENT_MUSIC_LIBRARY : {
    id: `${scope}.ENTERTAINMENT_MUSIC_LIBRARY`,
    defaultMessage: 'Music Library',
  },
  ENTERTAINMENT_MUSIC_PIANO : {
    id: `${scope}.ENTERTAINMENT_MUSIC_PIANO`,
    defaultMessage: 'Music Piano',
  },
  ENTERTAINMENT_RADIO : {
    id: `${scope}.ENTERTAINMENT_RADIO`,
    defaultMessage: 'Radio',
  },
  ENTERTAINMENT_SNOOKER_TABLE : {
    id: `${scope}.ENTERTAINMENT_SNOOKER_TABLE`,
    defaultMessage: 'Snooker Table',
  },
  ENTERTAINMENT_STEREO_SYSTEM : {
    id: `${scope}.ENTERTAINMENT_STEREO_SYSTEM`,
    defaultMessage: 'Stereo System',
  },
  ENTERTAINMENT_VCR_PLAYER : {
    id: `${scope}.ENTERTAINMENT_VCR_PLAYER`,
    defaultMessage: 'Vcr Player',
  },
  ENTERTAINMENT_VIDEO_GAME_LIBRARY : {
    id: `${scope}.ENTERTAINMENT_VIDEO_GAME_LIBRARY`,
    defaultMessage: 'Video Game Library',
  },
  ENTERTAINMENT_TABLE_TENNIS : {
    id: `${scope}.ENTERTAINMENT_TABLE_TENNIS`,
    defaultMessage: 'Table Tennis',
  },
  GENERAL_AIR_CONDITIONING : {
    id: `${scope}.GENERAL_AIR_CONDITIONING`,
    defaultMessage: 'Air Conditioning',
  },
  GENERAL_BED_LINEN : {
    id: `${scope}.GENERAL_BED_LINEN`,
    defaultMessage: 'Bed Linen',
  },
  GENERAL_BICYCLE_STORAGE : {
    id: `${scope}.GENERAL_BICYCLE_STORAGE`,
    defaultMessage: 'Bicycle Storage',
  },
  GENERAL_BUILDING_HAS_DOORMAN : {
    id: `${scope}.GENERAL_BUILDING_HAS_DOORMAN`,
    defaultMessage: 'Building Has Doorman',
  },
  GENERAL_CATERED : {
    id: `${scope}.GENERAL_CATERED`,
    defaultMessage: 'Catered',
  },
  GENERAL_CEILING_FAN : {
    id: `${scope}.GENERAL_CEILING_FAN`,
    defaultMessage: 'Ceiling Fan',
  },
  GENERAL_CELLPHONE_SIGNAL : {
    id: `${scope}.GENERAL_CELLPHONE_SIGNAL`,
    defaultMessage: 'Cellphone Signal',
  },
  GENERAL_CENTRAL_HEATING : {
    id: `${scope}.GENERAL_CENTRAL_HEATING`,
    defaultMessage: 'Central Heating',
  },
  GENERAL_CONCIERGE_SERVICE : {
    id: `${scope}.GENERAL_CONCIERGE_SERVICE`,
    defaultMessage: 'Concierge Service',
  },
  GENERAL_COOK : {
    id: `${scope}.GENERAL_COOK`,
    defaultMessage: 'Cook',
  },
  GENERAL_COURTYARD : {
    id: `${scope}.GENERAL_COURTYARD`,
    defaultMessage: 'Courtyard',
  },
  GENERAL_DINING_ROOM : {
    id: `${scope}.GENERAL_DINING_ROOM`,
    defaultMessage: 'Dining Room',
  },
  GENERAL_ECO_FRIENDLY : {
    id: `${scope}.GENERAL_ECO_FRIENDLY`,
    defaultMessage: 'Eco Friendly',
  },
  GENERAL_ELECTRIC_RADIATORS : {
    id: `${scope}.GENERAL_ELECTRIC_RADIATORS`,
    defaultMessage: 'Electric Radiators',
  },
  GENERAL_ESSENTIALS_EQUIPPED : {
    id: `${scope}.GENERAL_ESSENTIALS_EQUIPPED`,
    defaultMessage: 'Essentials Equipped',
  },
  GENERAL_EXTRA_BED : {
    id: `${scope}.GENERAL_EXTRA_BED`,
    defaultMessage: 'Extra Bed',
  },
  GENERAL_FIREPLACE : {
    id: `${scope}.GENERAL_FIREPLACE`,
    defaultMessage: 'Fireplace',
  },
  GENERAL_FIREWOOD : {
    id: `${scope}.GENERAL_FIREWOOD`,
    defaultMessage: 'Firewood',
  },
  GENERAL_PRIVATE_ENTRANCE : {
    id: `${scope}.GENERAL_PRIVATE_ENTRANCE`,
    defaultMessage: 'Private Entrance',
  },
  GENERAL_FITNESS_ROOM : {
    id: `${scope}.GENERAL_FITNESS_ROOM`,
    defaultMessage: 'Fitness Room',
  },
  GENERAL_FRONT_DESK : {
    id: `${scope}.GENERAL_FRONT_DESK`,
    defaultMessage: 'Front Desk',
  },
  GENERAL_GARDEN_SHARED : {
    id: `${scope}.GENERAL_GARDEN_SHARED`,
    defaultMessage: 'Garden Shared',
  },
  GENERAL_GARDEN : {
    id: `${scope}.GENERAL_GARDEN`,
    defaultMessage: 'Garden',
  },
  GENERAL_HAIRDRYER : {
    id: `${scope}.GENERAL_HAIRDRYER`,
    defaultMessage: 'Hairdryer',
  },
  GENERAL_HELIPAD : {
    id: `${scope}.GENERAL_HELIPAD`,
    defaultMessage: 'Helipad',
  },
  GENERAL_IRON_IRONING_BOARD : {
    id: `${scope}.GENERAL_IRON_IRONING_BOARD`,
    defaultMessage: 'Iron Ironing Board',
  },
  GENERAL_LAUNDRY_FACILITIES : {
    id: `${scope}.GENERAL_LAUNDRY_FACILITIES`,
    defaultMessage: 'Laundry Facilities',
  },
  GENERAL_LIFT_ELEVATOR : {
    id: `${scope}.GENERAL_LIFT_ELEVATOR`,
    defaultMessage: 'Lift Elevator',
  },
  GENERAL_LIVING_ROOM : {
    id: `${scope}.GENERAL_LIVING_ROOM`,
    defaultMessage: 'Living Room',
  },
  GENERAL_LOUNGE_DINER : {
    id: `${scope}.GENERAL_LOUNGE_DINER`,
    defaultMessage: 'Lounge Diner',
  },
  GENERAL_MAID_ROOMS : {
    id: `${scope}.GENERAL_MAID_ROOMS`,
    defaultMessage: 'Maid Rooms',
  },
  GENERAL_ON_SITE_STAFF : {
    id: `${scope}.GENERAL_ON_SITE_STAFF`,
    defaultMessage: 'On Site Staff',
  },
  GENERAL_PARKING : {
    id: `${scope}.GENERAL_PARKING`,
    defaultMessage: 'Parking',
  },
  GENERAL_PERSONAL_WELCOME : {
    id: `${scope}.GENERAL_PERSONAL_WELCOME`,
    defaultMessage: 'Personal Welcome',
  },
  GENERAL_PET_FRIENDLY : {
    id: `${scope}.GENERAL_PET_FRIENDLY`,
    defaultMessage: 'Pet Friendly',
  },
  GENERAL_SECURITY_SAFE : {
    id: `${scope}.GENERAL_SECURITY_SAFE`,
    defaultMessage: 'Security Safe',
  },
  GENERAL_SKI_STORAGE : {
    id: `${scope}.GENERAL_SKI_STORAGE`,
    defaultMessage: 'Ski Storage',
  },
  GENERAL_SOAP_SHAMPOO : {
    id: `${scope}.GENERAL_SOAP_SHAMPOO`,
    defaultMessage: 'Soap Shampoo',
  },
  GENERAL_SUITABLE_FOR_ELDERLY : {
    id: `${scope}.GENERAL_SUITABLE_FOR_ELDERLY`,
    defaultMessage: 'Suitable For Elderly',
  },
  GENERAL_TOWELS : {
    id: `${scope}.GENERAL_TOWELS`,
    defaultMessage: 'Towels',
  },
  GENERAL_TUMBLE_DRYER : {
    id: `${scope}.GENERAL_TUMBLE_DRYER`,
    defaultMessage: 'Tumble Dryer',
  },
  GENERAL_UNDER_FLOOR_HEATING : {
    id: `${scope}.GENERAL_UNDER_FLOOR_HEATING`,
    defaultMessage: 'Under Floor Heating',
  },
  GENERAL_UNIVERSAL_SHAVER_PLUG : {
    id: `${scope}.GENERAL_UNIVERSAL_SHAVER_PLUG`,
    defaultMessage: 'Universal Shaver Plug',
  },
  GENERAL_LAPTOP_FRIENDLY : {
    id: `${scope}.GENERAL_LAPTOP_FRIENDLY`,
    defaultMessage: 'Laptop Friendly',
  },
  GENERAL_WORK_DESK : {
    id: `${scope}.GENERAL_WORK_DESK`,
    defaultMessage: 'Work Desk',
  },
  GENERAL_PRINTER : {
    id: `${scope}.GENERAL_PRINTER`,
    defaultMessage: 'Printer',
  },
  GENERAL_UTILITY_ROOM : {
    id: `${scope}.GENERAL_UTILITY_ROOM`,
    defaultMessage: 'Utility Room',
  },
  GENERAL_ELECTRIC_VEHICLE_CHARGER : {
    id: `${scope}.GENERAL_ELECTRIC_VEHICLE_CHARGER`,
    defaultMessage: 'Electric Vehicle Charger',
  },
  GENERAL_WASHER_DRYER : {
    id: `${scope}.GENERAL_WASHER_DRYER`,
    defaultMessage: 'Washer Dryer',
  },
  GENERAL_WASHING_MACHINE : {
    id: `${scope}.GENERAL_WASHING_MACHINE`,
    defaultMessage: 'Washing Machine',
  },
  GENERAL_WOOD_BURNING_STOVE : {
    id: `${scope}.GENERAL_WOOD_BURNING_STOVE`,
    defaultMessage: 'Wood Burning Stove',
  },
  GENERAL_HANGERS : {
    id: `${scope}.GENERAL_HANGERS`,
    defaultMessage: 'Hangers',
  },
  GENERAL_HOT_WATER : {
    id: `${scope}.GENERAL_HOT_WATER`,
    defaultMessage: 'Hot Water',
  },
  GENERAL_EXTRA_PILLOWS_AND_BLANKETS : {
    id: `${scope}.GENERAL_EXTRA_PILLOWS_AND_BLANKETS`,
    defaultMessage: 'Extra Pillows And Blankets',
  },
  GENERAL_BATHTUB : {
    id: `${scope}.GENERAL_BATHTUB`,
    defaultMessage: 'Bathtub',
  },
  KITCHEN_AGA_COOKER : {
    id: `${scope}.KITCHEN_AGA_COOKER`,
    defaultMessage: 'Aga Cooker',
  },
  KITCHEN_AMERICAN_FRIDGE : {
    id: `${scope}.KITCHEN_AMERICAN_FRIDGE`,
    defaultMessage: 'American Fridge',
  },
  KITCHEN_BLENDER : {
    id: `${scope}.KITCHEN_BLENDER`,
    defaultMessage: 'Blender',
  },
  KITCHEN_CAFETIERE : {
    id: `${scope}.KITCHEN_CAFETIERE`,
    defaultMessage: 'Cafetiere',
  },
  KITCHEN_COFFEE_GRINDER : {
    id: `${scope}.KITCHEN_COFFEE_GRINDER`,
    defaultMessage: 'Coffee Grinder',
  },
  KITCHEN_COFFEE_MACHINE : {
    id: `${scope}.KITCHEN_COFFEE_MACHINE`,
    defaultMessage: 'Coffee Machine',
  },
  KITCHEN_COFFEE_PERCOLATOR : {
    id: `${scope}.KITCHEN_COFFEE_PERCOLATOR`,
    defaultMessage: 'Coffee Percolator',
  },
  KITCHEN_DISHWASHER : {
    id: `${scope}.KITCHEN_DISHWASHER`,
    defaultMessage: 'Dishwasher',
  },
  KITCHEN_ELECTRIC_HOB : {
    id: `${scope}.KITCHEN_ELECTRIC_HOB`,
    defaultMessage: 'Electric Hob',
  },
  KITCHEN_ELECTRIC_KETTLE : {
    id: `${scope}.KITCHEN_ELECTRIC_KETTLE`,
    defaultMessage: 'Electric Kettle',
  },
  KITCHEN_FOOD_PROCESSOR : {
    id: `${scope}.KITCHEN_FOOD_PROCESSOR`,
    defaultMessage: 'Food Processor',
  },
  KITCHEN_FREEZER_STANDALONE : {
    id: `${scope}.KITCHEN_FREEZER_STANDALONE`,
    defaultMessage: 'Freezer Standalone',
  },
  KITCHEN_FRIDGE_FREEZER : {
    id: `${scope}.KITCHEN_FRIDGE_FREEZER`,
    defaultMessage: 'Fridge Freezer',
  },
  KITCHEN_FRIDGE_STANDALONE : {
    id: `${scope}.KITCHEN_FRIDGE_STANDALONE`,
    defaultMessage: 'Fridge Standalone',
  },
  KITCHEN_FULL_CROCKERY_SET : {
    id: `${scope}.KITCHEN_FULL_CROCKERY_SET`,
    defaultMessage: 'Full Crockery Set',
  },
  KITCHEN_FULL_CUTLERY_UTENSIL_KIT : {
    id: `${scope}.KITCHEN_FULL_CUTLERY_UTENSIL_KIT`,
    defaultMessage: 'Full Cutlery Utensil Kit',
  },
  KITCHEN_GAS_HOB : {
    id: `${scope}.KITCHEN_GAS_HOB`,
    defaultMessage: 'Gas Hob',
  },
  KITCHEN_GRIDDLE_GRILL : {
    id: `${scope}.KITCHEN_GRIDDLE_GRILL`,
    defaultMessage: 'Griddle Grill',
  },
  KITCHEN_ICEMAKER : {
    id: `${scope}.KITCHEN_ICEMAKER`,
    defaultMessage: 'Icemaker',
  },
  KITCHEN_INDUCTION_HOB : {
    id: `${scope}.KITCHEN_INDUCTION_HOB`,
    defaultMessage: 'Induction Hob',
  },
  KITCHEN_JUICER : {
    id: `${scope}.KITCHEN_JUICER`,
    defaultMessage: 'Juicer',
  },
  KITCHEN_KITCHEN_DINER : {
    id: `${scope}.KITCHEN_KITCHEN_DINER`,
    defaultMessage: 'Diner',
  },
  KITCHEN_KITCHEN_FULL : {
    id: `${scope}.KITCHEN_KITCHEN_FULL`,
    defaultMessage: 'Full',
  },
  KITCHEN_KITCHENETTE : {
    id: `${scope}.KITCHEN_KITCHENETTE`,
    defaultMessage: 'Kitchenette',
  },
  KITCHEN_MICROWAVE : {
    id: `${scope}.KITCHEN_MICROWAVE`,
    defaultMessage: 'Microwave',
  },
  KITCHEN_OVEN_GRILL : {
    id: `${scope}.KITCHEN_OVEN_GRILL`,
    defaultMessage: 'Oven Grill',
  },
  KITCHEN_OVEN : {
    id: `${scope}.KITCHEN_OVEN`,
    defaultMessage: 'Oven',
  },
  KITCHEN_RANGE_COOKER : {
    id: `${scope}.KITCHEN_RANGE_COOKER`,
    defaultMessage: 'Range Cooker',
  },
  KITCHEN_STONE_PIZZA_OVEN : {
    id: `${scope}.KITCHEN_STONE_PIZZA_OVEN`,
    defaultMessage: 'Stone Pizza Oven',
  },
  KITCHEN_TOASTER : {
    id: `${scope}.KITCHEN_TOASTER`,
    defaultMessage: 'Toaster',
  },
  KITCHEN_WAFFLE_MAKER : {
    id: `${scope}.KITCHEN_WAFFLE_MAKER`,
    defaultMessage: 'Waffle Maker',
  },
  KITCHEN_WASTE_DISPOSAL : {
    id: `${scope}.KITCHEN_WASTE_DISPOSAL`,
    defaultMessage: 'Waste Disposal',
  },
  KITCHEN_WATER_COOLER : {
    id: `${scope}.KITCHEN_WATER_COOLER`,
    defaultMessage: 'Water Cooler',
  },
  KITCHEN_WINE_COOLER : {
    id: `${scope}.KITCHEN_WINE_COOLER`,
    defaultMessage: 'Wine Cooler',
  },
  LOCAL_AIR_HOCKEY : {
    id: `${scope}.LOCAL_AIR_HOCKEY`,
    defaultMessage: 'Air Hockey',
  },
  LOCAL_ANTIQUEING : {
    id: `${scope}.LOCAL_ANTIQUEING`,
    defaultMessage: 'Antiqueing',
  },
  LOCAL_ATM_BANK : {
    id: `${scope}.LOCAL_ATM_BANK`,
    defaultMessage: 'Atm Bank',
  },
  LOCAL_BADMINTON : {
    id: `${scope}.LOCAL_BADMINTON`,
    defaultMessage: 'Badminton',
  },
  LOCAL_BASEBALL_PARK : {
    id: `${scope}.LOCAL_BASEBALL_PARK`,
    defaultMessage: 'Baseball Park',
  },
  LOCAL_BASKETBALL_COURT : {
    id: `${scope}.LOCAL_BASKETBALL_COURT`,
    defaultMessage: 'Basketball Court',
  },
  LOCAL_BEACH_DOG_FRIENDLY : {
    id: `${scope}.LOCAL_BEACH_DOG_FRIENDLY`,
    defaultMessage: 'Beach Dog Friendly',
  },
  LOCAL_BEACH : {
    id: `${scope}.LOCAL_BEACH`,
    defaultMessage: 'Beach',
  },
  LOCAL_BEACHCOMBING : {
    id: `${scope}.LOCAL_BEACHCOMBING`,
    defaultMessage: 'Beachcombing',
  },
  LOCAL_BIKE_RENTALS : {
    id: `${scope}.LOCAL_BIKE_RENTALS`,
    defaultMessage: 'Bike Rentals',
  },
  LOCAL_BIRD_WATCHING : {
    id: `${scope}.LOCAL_BIRD_WATCHING`,
    defaultMessage: 'Bird Watching',
  },
  LOCAL_BOAT_MOORING : {
    id: `${scope}.LOCAL_BOAT_MOORING`,
    defaultMessage: 'Boat Mooring',
  },
  LOCAL_BOAT_RENTALS : {
    id: `${scope}.LOCAL_BOAT_RENTALS`,
    defaultMessage: 'Boat Rentals',
  },
  LOCAL_BOATING : {
    id: `${scope}.LOCAL_BOATING`,
    defaultMessage: 'Boating',
  },
  LOCAL_BODY_BOARD : {
    id: `${scope}.LOCAL_BODY_BOARD`,
    defaultMessage: 'Body Board',
  },
  LOCAL_BOTANICAL_GARDENS : {
    id: `${scope}.LOCAL_BOTANICAL_GARDENS`,
    defaultMessage: 'Botanical Gardens',
  },
  LOCAL_BOWLS_GREEN : {
    id: `${scope}.LOCAL_BOWLS_GREEN`,
    defaultMessage: 'Bowls Green',
  },
  LOCAL_CABLE_CAR : {
    id: `${scope}.LOCAL_CABLE_CAR`,
    defaultMessage: 'Cable Car',
  },
  LOCAL_CANOEING_KAYAKING : {
    id: `${scope}.LOCAL_CANOEING_KAYAKING`,
    defaultMessage: 'Canoeing Kayaking',
  },
  LOCAL_CASINO : {
    id: `${scope}.LOCAL_CASINO`,
    defaultMessage: 'Casino',
  },
  LOCAL_CHILDRENS_PLAYGROUND : {
    id: `${scope}.LOCAL_CHILDRENS_PLAYGROUND`,
    defaultMessage: 'S Playground',
  },
  LOCAL_CHURCH : {
    id: `${scope}.LOCAL_CHURCH`,
    defaultMessage: 'Church',
  },
  LOCAL_CINEMA : {
    id: `${scope}.LOCAL_CINEMA`,
    defaultMessage: 'Cinema',
  },
  LOCAL_CLIMBING : {
    id: `${scope}.LOCAL_CLIMBING`,
    defaultMessage: 'Climbing',
  },
  LOCAL_CONCERT_HALL : {
    id: `${scope}.LOCAL_CONCERT_HALL`,
    defaultMessage: 'Concert Hall',
  },
  LOCAL_CONSERVATION_AREA : {
    id: `${scope}.LOCAL_CONSERVATION_AREA`,
    defaultMessage: 'Conservation Area',
  },
  LOCAL_CRECHE : {
    id: `${scope}.LOCAL_CRECHE`,
    defaultMessage: 'Creche',
  },
  LOCAL_CROSS_COUNTRY_SKIING : {
    id: `${scope}.LOCAL_CROSS_COUNTRY_SKIING`,
    defaultMessage: 'Cross Country Skiing',
  },
  LOCAL_CURLING : {
    id: `${scope}.LOCAL_CURLING`,
    defaultMessage: 'Curling',
  },
  LOCAL_CYCLING : {
    id: `${scope}.LOCAL_CYCLING`,
    defaultMessage: 'Cycling',
  },
  LOCAL_FISHING_FLY : {
    id: `${scope}.LOCAL_FISHING_FLY`,
    defaultMessage: 'Fishing Fly',
  },
  LOCAL_FISHING_FRESHWATER : {
    id: `${scope}.LOCAL_FISHING_FRESHWATER`,
    defaultMessage: 'Fishing Freshwater',
  },
  LOCAL_FISHING_GEAR : {
    id: `${scope}.LOCAL_FISHING_GEAR`,
    defaultMessage: 'Fishing Gear',
  },
  LOCAL_FISHING : {
    id: `${scope}.LOCAL_FISHING`,
    defaultMessage: 'Fishing',
  },
  LOCAL_FLOAT_PLANE_HELICOPTER : {
    id: `${scope}.LOCAL_FLOAT_PLANE_HELICOPTER`,
    defaultMessage: 'Float Plane Helicopter',
  },
  LOCAL_FOOTBALL_STADIUM : {
    id: `${scope}.LOCAL_FOOTBALL_STADIUM`,
    defaultMessage: 'Football Stadium',
  },
  LOCAL_FUNICULAR_RAILWAY : {
    id: `${scope}.LOCAL_FUNICULAR_RAILWAY`,
    defaultMessage: 'Funicular Railway',
  },
  LOCAL_GALLERIES : {
    id: `${scope}.LOCAL_GALLERIES`,
    defaultMessage: 'Galleries',
  },
  LOCAL_GAMBLING : {
    id: `${scope}.LOCAL_GAMBLING`,
    defaultMessage: 'Gambling',
  },
  LOCAL_GO_KARTING : {
    id: `${scope}.LOCAL_GO_KARTING`,
    defaultMessage: 'Go Karting',
  },
  LOCAL_GOLF : {
    id: `${scope}.LOCAL_GOLF`,
    defaultMessage: 'Golf',
  },
  LOCAL_GROCERY_SHOPPING_NEARBY : {
    id: `${scope}.LOCAL_GROCERY_SHOPPING_NEARBY`,
    defaultMessage: 'Grocery Shopping Nearby',
  },
  LOCAL_GYM : {
    id: `${scope}.LOCAL_GYM`,
    defaultMessage: 'Gym',
  },
  LOCAL_HIKING : {
    id: `${scope}.LOCAL_HIKING`,
    defaultMessage: 'Hiking',
  },
  LOCAL_HORSEBACK_RIDING : {
    id: `${scope}.LOCAL_HORSEBACK_RIDING`,
    defaultMessage: 'Horseback Riding',
  },
  LOCAL_HOSPITAL : {
    id: `${scope}.LOCAL_HOSPITAL`,
    defaultMessage: 'Hospital',
  },
  LOCAL_HOT_AIR_BALLOON_RIDES : {
    id: `${scope}.LOCAL_HOT_AIR_BALLOON_RIDES`,
    defaultMessage: 'Hot Air Balloon Rides',
  },
  LOCAL_HOT_SPRINGS : {
    id: `${scope}.LOCAL_HOT_SPRINGS`,
    defaultMessage: 'Hot Springs',
  },
  LOCAL_HUNTING : {
    id: `${scope}.LOCAL_HUNTING`,
    defaultMessage: 'Hunting',
  },
  LOCAL_ICE_CLIMBING : {
    id: `${scope}.LOCAL_ICE_CLIMBING`,
    defaultMessage: 'Ice Climbing',
  },
  LOCAL_ICE_HOCKEY : {
    id: `${scope}.LOCAL_ICE_HOCKEY`,
    defaultMessage: 'Ice Hockey',
  },
  LOCAL_ICE_SKATING : {
    id: `${scope}.LOCAL_ICE_SKATING`,
    defaultMessage: 'Ice Skating',
  },
  LOCAL_INFLATABLE_DINGHY : {
    id: `${scope}.LOCAL_INFLATABLE_DINGHY`,
    defaultMessage: 'Inflatable Dinghy',
  },
  LOCAL_JETSKI_PERSONAL_WATERCRAFT : {
    id: `${scope}.LOCAL_JETSKI_PERSONAL_WATERCRAFT`,
    defaultMessage: 'Jetski Personal Watercraft',
  },
  LOCAL_LAUNDROMAT : {
    id: `${scope}.LOCAL_LAUNDROMAT`,
    defaultMessage: 'Laundromat',
  },
  LOCAL_LIBRARY : {
    id: `${scope}.LOCAL_LIBRARY`,
    defaultMessage: 'Library',
  },
  LOCAL_LIGHTHOUSE : {
    id: `${scope}.LOCAL_LIGHTHOUSE`,
    defaultMessage: 'Lighthouse',
  },
  LOCAL_MARKETS : {
    id: `${scope}.LOCAL_MARKETS`,
    defaultMessage: 'Markets',
  },
  LOCAL_MASSAGE_THERAPIST : {
    id: `${scope}.LOCAL_MASSAGE_THERAPIST`,
    defaultMessage: 'Massage Therapist',
  },
  LOCAL_MEDICAL_SERVICES : {
    id: `${scope}.LOCAL_MEDICAL_SERVICES`,
    defaultMessage: 'Medical Services',
  },
  LOCAL_MINIATURE_GOLF : {
    id: `${scope}.LOCAL_MINIATURE_GOLF`,
    defaultMessage: 'Miniature Golf',
  },
  LOCAL_MOUNTAIN_BIKING : {
    id: `${scope}.LOCAL_MOUNTAIN_BIKING`,
    defaultMessage: 'Mountain Biking',
  },
  LOCAL_MOUNTAINEERING : {
    id: `${scope}.LOCAL_MOUNTAINEERING`,
    defaultMessage: 'Mountaineering',
  },
  LOCAL_MOVIE_THEATRE : {
    id: `${scope}.LOCAL_MOVIE_THEATRE`,
    defaultMessage: 'Movie Theatre',
  },
  LOCAL_MUSEUM : {
    id: `${scope}.LOCAL_MUSEUM`,
    defaultMessage: 'Museum',
  },
  LOCAL_NATIONAL_HERITAGE : {
    id: `${scope}.LOCAL_NATIONAL_HERITAGE`,
    defaultMessage: 'National Heritage',
  },
  LOCAL_NATIONAL_PARK : {
    id: `${scope}.LOCAL_NATIONAL_PARK`,
    defaultMessage: 'National Park',
  },
  LOCAL_NATIONAL_TRUST_PROPERTIES : {
    id: `${scope}.LOCAL_NATIONAL_TRUST_PROPERTIES`,
    defaultMessage: 'National Trust Properties',
  },
  LOCAL_NATURE_RESERVE : {
    id: `${scope}.LOCAL_NATURE_RESERVE`,
    defaultMessage: 'Nature Reserve',
  },
  LOCAL_PADDLE_BOARDING : {
    id: `${scope}.LOCAL_PADDLE_BOARDING`,
    defaultMessage: 'Paddle Boarding',
  },
  LOCAL_PARAGLIDING : {
    id: `${scope}.LOCAL_PARAGLIDING`,
    defaultMessage: 'Paragliding',
  },
  LOCAL_PARASAILING : {
    id: `${scope}.LOCAL_PARASAILING`,
    defaultMessage: 'Parasailing',
  },
  LOCAL_PARK_DOG_FRIENDLY : {
    id: `${scope}.LOCAL_PARK_DOG_FRIENDLY`,
    defaultMessage: 'Park Dog Friendly',
  },
  LOCAL_PARK_GREEN_SPACE : {
    id: `${scope}.LOCAL_PARK_GREEN_SPACE`,
    defaultMessage: 'Park Green Space',
  },
  LOCAL_PARK : {
    id: `${scope}.LOCAL_PARK`,
    defaultMessage: 'Park',
  },
  LOCAL_PLANETARIUM : {
    id: `${scope}.LOCAL_PLANETARIUM`,
    defaultMessage: 'Planetarium',
  },
  LOCAL_POOL_TABLE : {
    id: `${scope}.LOCAL_POOL_TABLE`,
    defaultMessage: 'Pool Table',
  },
  LOCAL_PUBLIC_VILLAS_GARDENS : {
    id: `${scope}.LOCAL_PUBLIC_VILLAS_GARDENS`,
    defaultMessage: 'Public Villas Gardens',
  },
  LOCAL_QUAD_BIKE : {
    id: `${scope}.LOCAL_QUAD_BIKE`,
    defaultMessage: 'Quad Bike',
  },
  LOCAL_RAFTING : {
    id: `${scope}.LOCAL_RAFTING`,
    defaultMessage: 'Rafting',
  },
  LOCAL_RELIGIOUS_CENTRE : {
    id: `${scope}.LOCAL_RELIGIOUS_CENTRE`,
    defaultMessage: 'Religious Centre',
  },
  LOCAL_ROLLER_BLADING : {
    id: `${scope}.LOCAL_ROLLER_BLADING`,
    defaultMessage: 'Roller Blading',
  },
  LOCAL_ROWING_BOATS : {
    id: `${scope}.LOCAL_ROWING_BOATS`,
    defaultMessage: 'Rowing Boats',
  },
  LOCAL_SAILING : {
    id: `${scope}.LOCAL_SAILING`,
    defaultMessage: 'Sailing',
  },
  LOCAL_SCENIC_DRIVES : {
    id: `${scope}.LOCAL_SCENIC_DRIVES`,
    defaultMessage: 'Scenic Drives',
  },
  LOCAL_SCUBA_DIVING : {
    id: `${scope}.LOCAL_SCUBA_DIVING`,
    defaultMessage: 'Scuba Diving',
  },
  LOCAL_SIGHT_SEEING : {
    id: `${scope}.LOCAL_SIGHT_SEEING`,
    defaultMessage: 'Sight Seeing',
  },
  LOCAL_SKI_BOOT_HEATER : {
    id: `${scope}.LOCAL_SKI_BOOT_HEATER`,
    defaultMessage: 'Ski Boot Heater',
  },
  LOCAL_SKI_LIFT_PRIVILEDGES : {
    id: `${scope}.LOCAL_SKI_LIFT_PRIVILEDGES`,
    defaultMessage: 'Ski Lift Priviledges',
  },
  LOCAL_SKIDOO_SNOW_MOBILE : {
    id: `${scope}.LOCAL_SKIDOO_SNOW_MOBILE`,
    defaultMessage: 'Skidoo Snow Mobile',
  },
  LOCAL_SKIDOO : {
    id: `${scope}.LOCAL_SKIDOO`,
    defaultMessage: 'Skidoo',
  },
  LOCAL_SKIING : {
    id: `${scope}.LOCAL_SKIING`,
    defaultMessage: 'Skiing',
  },
  LOCAL_SNORKELLING : {
    id: `${scope}.LOCAL_SNORKELLING`,
    defaultMessage: 'Snorkelling',
  },
  LOCAL_SNOW_BOARDING : {
    id: `${scope}.LOCAL_SNOW_BOARDING`,
    defaultMessage: 'Snow Boarding',
  },
  LOCAL_SPA_WELLNESS_CENTRE : {
    id: `${scope}.LOCAL_SPA_WELLNESS_CENTRE`,
    defaultMessage: 'Spa Wellness Centre',
  },
  LOCAL_SQUASH : {
    id: `${scope}.LOCAL_SQUASH`,
    defaultMessage: 'Squash',
  },
  LOCAL_SURF_BOARD : {
    id: `${scope}.LOCAL_SURF_BOARD`,
    defaultMessage: 'Surf Board',
  },
  LOCAL_SURFING : {
    id: `${scope}.LOCAL_SURFING`,
    defaultMessage: 'Surfing',
  },
  LOCAL_SYNAGOGUE : {
    id: `${scope}.LOCAL_SYNAGOGUE`,
    defaultMessage: 'Synagogue',
  },
  LOCAL_TABLE_TENNIS : {
    id: `${scope}.LOCAL_TABLE_TENNIS`,
    defaultMessage: 'Table Tennis',
  },
  LOCAL_TEMPLE : {
    id: `${scope}.LOCAL_TEMPLE`,
    defaultMessage: 'Temple',
  },
  LOCAL_TENNIS_COURT : {
    id: `${scope}.LOCAL_TENNIS_COURT`,
    defaultMessage: 'Tennis Court',
  },
  LOCAL_TENPIN_BOWLING : {
    id: `${scope}.LOCAL_TENPIN_BOWLING`,
    defaultMessage: 'Tenpin Bowling',
  },
  LOCAL_THEATRE : {
    id: `${scope}.LOCAL_THEATRE`,
    defaultMessage: 'Theatre',
  },
  LOCAL_TOBOGGANING : {
    id: `${scope}.LOCAL_TOBOGGANING`,
    defaultMessage: 'Tobogganing',
  },
  LOCAL_WALKING : {
    id: `${scope}.LOCAL_WALKING`,
    defaultMessage: 'Walking',
  },
  LOCAL_WATER_SKIING : {
    id: `${scope}.LOCAL_WATER_SKIING`,
    defaultMessage: 'Water Skiing',
  },
  LOCAL_WATER_SKIS : {
    id: `${scope}.LOCAL_WATER_SKIS`,
    defaultMessage: 'Water Skis',
  },
  LOCAL_WATER_SPORTS : {
    id: `${scope}.LOCAL_WATER_SPORTS`,
    defaultMessage: 'Water Sports',
  },
  LOCAL_WELLNESS_CENTRE : {
    id: `${scope}.LOCAL_WELLNESS_CENTRE`,
    defaultMessage: 'Wellness Centre',
  },
  LOCAL_WHALE_WATCHING : {
    id: `${scope}.LOCAL_WHALE_WATCHING`,
    defaultMessage: 'Whale Watching',
  },
  LOCAL_WHITE_WATER_RAFTING : {
    id: `${scope}.LOCAL_WHITE_WATER_RAFTING`,
    defaultMessage: 'White Water Rafting',
  },
  LOCAL_WILDLIFE_CENTRE : {
    id: `${scope}.LOCAL_WILDLIFE_CENTRE`,
    defaultMessage: 'Wildlife Centre',
  },
  LOCAL_WILDLIFE_VIEWING : {
    id: `${scope}.LOCAL_WILDLIFE_VIEWING`,
    defaultMessage: 'Wildlife Viewing',
  },
  LOCAL_WIND_SURFING : {
    id: `${scope}.LOCAL_WIND_SURFING`,
    defaultMessage: 'Wind Surfing',
  },
  LOCALITY_BEACH_FRONT : {
    id: `${scope}.LOCALITY_BEACH_FRONT`,
    defaultMessage: 'Beach Front',
  },
  LOCALITY_BEACH_VIEWS : {
    id: `${scope}.LOCALITY_BEACH_VIEWS`,
    defaultMessage: 'Beach Views',
  },
  LOCALITY_BEACH : {
    id: `${scope}.LOCALITY_BEACH`,
    defaultMessage: 'Beach',
  },
  LOCALITY_CITY_CENTRE : {
    id: `${scope}.LOCALITY_CITY_CENTRE`,
    defaultMessage: 'City Centre',
  },
  LOCALITY_CITY_SKYLINE_VIEWS : {
    id: `${scope}.LOCALITY_CITY_SKYLINE_VIEWS`,
    defaultMessage: 'City Skyline Views',
  },
  LOCALITY_CITY : {
    id: `${scope}.LOCALITY_CITY`,
    defaultMessage: 'City',
  },
  LOCALITY_CLOSE_TO_NIGHTLIFE : {
    id: `${scope}.LOCALITY_CLOSE_TO_NIGHTLIFE`,
    defaultMessage: 'Close To Nightlife',
  },
  LOCALITY_CLOSE_TO_SKIING : {
    id: `${scope}.LOCALITY_CLOSE_TO_SKIING`,
    defaultMessage: 'Close To Skiing',
  },
  LOCALITY_COASTAL : {
    id: `${scope}.LOCALITY_COASTAL`,
    defaultMessage: 'Coastal',
  },
  LOCALITY_COUNTRYSIDE : {
    id: `${scope}.LOCALITY_COUNTRYSIDE`,
    defaultMessage: 'Countryside',
  },
  LOCALITY_FOREST : {
    id: `${scope}.LOCALITY_FOREST`,
    defaultMessage: 'Forest',
  },
  LOCALITY_GOLF_COURSE_VIEWS : {
    id: `${scope}.LOCALITY_GOLF_COURSE_VIEWS`,
    defaultMessage: 'Golf Course Views',
  },
  LOCALITY_GOLF_COURSE : {
    id: `${scope}.LOCALITY_GOLF_COURSE`,
    defaultMessage: 'Golf Course',
  },
  LOCALITY_LAKE_FRONT : {
    id: `${scope}.LOCALITY_LAKE_FRONT`,
    defaultMessage: 'Lake Front',
  },
  LOCALITY_LAKE_VIEWS : {
    id: `${scope}.LOCALITY_LAKE_VIEWS`,
    defaultMessage: 'Lake Views',
  },
  LOCALITY_LAKESIDE_TOWN : {
    id: `${scope}.LOCALITY_LAKESIDE_TOWN`,
    defaultMessage: 'Lakeside Town',
  },
  LOCALITY_LOCH_VIEWS : {
    id: `${scope}.LOCALITY_LOCH_VIEWS`,
    defaultMessage: 'Loch Views',
  },
  LOCALITY_MONUMENT_VIEWS : {
    id: `${scope}.LOCALITY_MONUMENT_VIEWS`,
    defaultMessage: 'Monument Views',
  },
  LOCALITY_MOORLAND : {
    id: `${scope}.LOCALITY_MOORLAND`,
    defaultMessage: 'Moorland',
  },
  LOCALITY_MOUNTAIN_RETREAT : {
    id: `${scope}.LOCALITY_MOUNTAIN_RETREAT`,
    defaultMessage: 'Mountain Retreat',
  },
  LOCALITY_MOUNTAIN_VIEWS : {
    id: `${scope}.LOCALITY_MOUNTAIN_VIEWS`,
    defaultMessage: 'Mountain Views',
  },
  LOCALITY_OCEAN_FRONT : {
    id: `${scope}.LOCALITY_OCEAN_FRONT`,
    defaultMessage: 'Ocean Front',
  },
  LOCALITY_OCEAN_VIEWS : {
    id: `${scope}.LOCALITY_OCEAN_VIEWS`,
    defaultMessage: 'Ocean Views',
  },
  LOCALITY_RIVER_VIEWS : {
    id: `${scope}.LOCALITY_RIVER_VIEWS`,
    defaultMessage: 'River Views',
  },
  LOCALITY_RURAL : {
    id: `${scope}.LOCALITY_RURAL`,
    defaultMessage: 'Rural',
  },
  LOCALITY_SEA_WATERFRONT : {
    id: `${scope}.LOCALITY_SEA_WATERFRONT`,
    defaultMessage: 'Sea Waterfront',
  },
  LOCALITY_SEASIDE_TOWN : {
    id: `${scope}.LOCALITY_SEASIDE_TOWN`,
    defaultMessage: 'Seaside Town',
  },
  LOCALITY_SKI_IN_SKI_OUT : {
    id: `${scope}.LOCALITY_SKI_IN_SKI_OUT`,
    defaultMessage: 'Ski In Ski Out',
  },
  LOCALITY_TOWN_CENTRE : {
    id: `${scope}.LOCALITY_TOWN_CENTRE`,
    defaultMessage: 'Town Centre',
  },
  LOCALITY_TOWN : {
    id: `${scope}.LOCALITY_TOWN`,
    defaultMessage: 'Town',
  },
  LOCALITY_TROPICAL : {
    id: `${scope}.LOCALITY_TROPICAL`,
    defaultMessage: 'Tropical',
  },
  LOCALITY_VALLEY_VIEWS : {
    id: `${scope}.LOCALITY_VALLEY_VIEWS`,
    defaultMessage: 'Valley Views',
  },
  LOCALITY_VILLAGE : {
    id: `${scope}.LOCALITY_VILLAGE`,
    defaultMessage: 'Village',
  },
  LOCALITY_WATER_FRONT : {
    id: `${scope}.LOCALITY_WATER_FRONT`,
    defaultMessage: 'Water Front',
  },
  LOCALITY_WATER_VIEWS : {
    id: `${scope}.LOCALITY_WATER_VIEWS`,
    defaultMessage: 'Water Views',
  },
  LOCALITY_WITHIN_NATIONAL_PARK : {
    id: `${scope}.LOCALITY_WITHIN_NATIONAL_PARK`,
    defaultMessage: 'Within National Park',
  },
  LOCALITY_WOODED_SURROUNDINGS : {
    id: `${scope}.LOCALITY_WOODED_SURROUNDINGS`,
    defaultMessage: 'Wooded Surroundings',
  },
  OUTDOOR_BALCONY : {
    id: `${scope}.OUTDOOR_BALCONY`,
    defaultMessage: 'Balcony',
  },
  OUTDOOR_BARBECUE : {
    id: `${scope}.OUTDOOR_BARBECUE`,
    defaultMessage: 'Barbecue',
  },
  OUTDOOR_SURVEILLANCE_MONITORING : {
    id: `${scope}.OUTDOOR_SURVEILLANCE_MONITORING`,
    defaultMessage: 'Surveillance Monitoring',
  },
  OUTDOOR_BOAT_SLIP_DOCK : {
    id: `${scope}.OUTDOOR_BOAT_SLIP_DOCK`,
    defaultMessage: 'Boat Slip Dock',
  },
  OUTDOOR_DECK_PATIO : {
    id: `${scope}.OUTDOOR_DECK_PATIO`,
    defaultMessage: 'Deck Patio',
  },
  OUTDOOR_DOG_PEN_KENNEL : {
    id: `${scope}.OUTDOOR_DOG_PEN_KENNEL`,
    defaultMessage: 'Dog Pen Kennel',
  },
  OUTDOOR_GARAGE : {
    id: `${scope}.OUTDOOR_GARAGE`,
    defaultMessage: 'Garage',
  },
  OUTDOOR_GARDEN_FURNITURE : {
    id: `${scope}.OUTDOOR_GARDEN_FURNITURE`,
    defaultMessage: 'Garden Furniture',
  },
  OUTDOOR_GARDEN : {
    id: `${scope}.OUTDOOR_GARDEN`,
    defaultMessage: 'Garden',
  },
  OUTDOOR_HAMMOCK : {
    id: `${scope}.OUTDOOR_HAMMOCK`,
    defaultMessage: 'Hammock',
  },
  OUTDOOR_OUTDOOR_DINING : {
    id: `${scope}.OUTDOOR_OUTDOOR_DINING`,
    defaultMessage: 'Dining',
  },
  OUTDOOR_OUTDOOR_SHOWER : {
    id: `${scope}.OUTDOOR_OUTDOOR_SHOWER`,
    defaultMessage: 'Shower',
  },
  OUTDOOR_OUTSIDE_STONE_PIZZA_OVEN : {
    id: `${scope}.OUTDOOR_OUTSIDE_STONE_PIZZA_OVEN`,
    defaultMessage: 'Outside Stone Pizza Oven',
  },
  OUTDOOR_SUMMER_HOUSE : {
    id: `${scope}.OUTDOOR_SUMMER_HOUSE`,
    defaultMessage: 'Summer House',
  },
  OUTDOOR_SWING_SET : {
    id: `${scope}.OUTDOOR_SWING_SET`,
    defaultMessage: 'Swing Set',
  },
  OUTDOOR_TABLE_AND_CHAIRS : {
    id: `${scope}.OUTDOOR_TABLE_AND_CHAIRS`,
    defaultMessage: 'Table And Chairs',
  },
  OUTDOOR_TERRACE : {
    id: `${scope}.OUTDOOR_TERRACE`,
    defaultMessage: 'Terrace',
  },
  SAFETY_FENCED_PERIMETER : {
    id: `${scope}.SAFETY_FENCED_PERIMETER`,
    defaultMessage: 'Fenced Perimeter',
  },
  SAFETY_GARDEN_ENCLOSED : {
    id: `${scope}.SAFETY_GARDEN_ENCLOSED`,
    defaultMessage: 'Garden Enclosed',
  },
  SAFETY_GARDEN_NOT_SECURED : {
    id: `${scope}.SAFETY_GARDEN_NOT_SECURED`,
    defaultMessage: 'Garden Not Secured',
  },
  SAFETY_GARDEN_SECURED_WITH_FULL_BOUNDARY_FENCED : {
    id: `${scope}.SAFETY_GARDEN_SECURED_WITH_FULL_BOUNDARY_FENCED`,
    defaultMessage: 'Garden Secured With Full Boundary Fenced',
  },
  SAFETY_GARDEN_SECURED_WITH_PARTIAL_BOUNDARY : {
    id: `${scope}.SAFETY_GARDEN_SECURED_WITH_PARTIAL_BOUNDARY`,
    defaultMessage: 'Garden Secured With Partial Boundary',
  },
  SAFETY_SECURITY_ALARM_SYSTEM : {
    id: `${scope}.SAFETY_SECURITY_ALARM_SYSTEM`,
    defaultMessage: 'Security Alarm System',
  },
  SAFETY_SMOKE_DETECTOR : {
    id: `${scope}.SAFETY_SMOKE_DETECTOR`,
    defaultMessage: 'Smoke Detector',
  },
  SAFETY_CARBON_MONOXIDE_DETECTOR : {
    id: `${scope}.SAFETY_CARBON_MONOXIDE_DETECTOR`,
    defaultMessage: 'Carbon Monoxide Detector',
  },
  SAFETY_STAIR_GATES : {
    id: `${scope}.SAFETY_STAIR_GATES`,
    defaultMessage: 'Stair Gates',
  },
  SAFETY_FIRST_AID_KIT : {
    id: `${scope}.SAFETY_FIRST_AID_KIT`,
    defaultMessage: 'First Aid Kit',
  },
  SAFETY_SAFETY_CARD : {
    id: `${scope}.SAFETY_SAFETY_CARD`,
    defaultMessage: 'Safety Card',
  },
  SAFETY_FIRE_EXTINGUISHER : {
    id: `${scope}.SAFETY_FIRE_EXTINGUISHER`,
    defaultMessage: 'Fire Extinguisher',
  },
  SAFETY_WATER_SPRINKLER_SYSTEM : {
    id: `${scope}.SAFETY_WATER_SPRINKLER_SYSTEM`,
    defaultMessage: 'Water Sprinkler System',
  },
  SPA_POOL_HEALTH_FACILITIES : {
    id: `${scope}.SPA_POOL_HEALTH_FACILITIES`,
    defaultMessage: 'Health Facilities',
  },
  SPA_POOL_JACUZZI_HOT_TUB : {
    id: `${scope}.SPA_POOL_JACUZZI_HOT_TUB`,
    defaultMessage: 'Jacuzzi Hot Tub',
  },
  SPA_POOL_MASSAGE : {
    id: `${scope}.SPA_POOL_MASSAGE`,
    defaultMessage: 'Massage',
  },
  SPA_POOL_OUTDOOR_TOWELS : {
    id: `${scope}.SPA_POOL_OUTDOOR_TOWELS`,
    defaultMessage: 'Towels',
  },
  SPA_POOL_SAUNA : {
    id: `${scope}.SPA_POOL_SAUNA`,
    defaultMessage: 'Sauna',
  },
  SPA_POOL_STEAM_SHOWER : {
    id: `${scope}.SPA_POOL_STEAM_SHOWER`,
    defaultMessage: 'Steam Shower',
  },
  SPA_POOL_SUN_LOUNGERS : {
    id: `${scope}.SPA_POOL_SUN_LOUNGERS`,
    defaultMessage: 'Sun Loungers',
  },
  SPA_POOL_SWIMMING_POOL : {
    id: `${scope}.SPA_POOL_SWIMMING_POOL`,
    defaultMessage: 'Swimming Pool',
  },
  THEMES_ADVENTURE : {
    id: `${scope}.THEMES_ADVENTURE`,
    defaultMessage: 'Adventure',
  },
  THEMES_AWAY_FROM_IT_ALL : {
    id: `${scope}.THEMES_AWAY_FROM_IT_ALL`,
    defaultMessage: 'Away From It All',
  },
  THEMES_BUDGET : {
    id: `${scope}.THEMES_BUDGET`,
    defaultMessage: 'Budget',
  },
  THEMES_CORPORATE : {
    id: `${scope}.THEMES_CORPORATE`,
    defaultMessage: 'Corporate',
  },
  THEMES_EXCLUSIVE : {
    id: `${scope}.THEMES_EXCLUSIVE`,
    defaultMessage: 'Exclusive',
  },
  THEMES_EXPLORER : {
    id: `${scope}.THEMES_EXPLORER`,
    defaultMessage: 'Explorer',
  },
  THEMES_FAMILY_FUN : {
    id: `${scope}.THEMES_FAMILY_FUN`,
    defaultMessage: 'Family Fun',
  },
  THEMES_FOR_SALE : {
    id: `${scope}.THEMES_FOR_SALE`,
    defaultMessage: 'For Sale',
  },
  THEMES_GAY_FRIENDLY : {
    id: `${scope}.THEMES_GAY_FRIENDLY`,
    defaultMessage: 'Gay Friendly',
  },
  THEMES_GAY_OWNER : {
    id: `${scope}.THEMES_GAY_OWNER`,
    defaultMessage: 'Gay Owner',
  },
  THEMES_GETTING_AWAY_FROM_IT_ALL : {
    id: `${scope}.THEMES_GETTING_AWAY_FROM_IT_ALL`,
    defaultMessage: 'Getting Away From It All',
  },
  THEMES_GOLF : {
    id: `${scope}.THEMES_GOLF`,
    defaultMessage: 'Golf',
  },
  THEMES_HEALTH : {
    id: `${scope}.THEMES_HEALTH`,
    defaultMessage: 'Health',
  },
  THEMES_HENS : {
    id: `${scope}.THEMES_HENS`,
    defaultMessage: 'Hens',
  },
  THEMES_HISTORIC : {
    id: `${scope}.THEMES_HISTORIC`,
    defaultMessage: 'Historic',
  },
  THEMES_HOLIDAY_COMPLEX : {
    id: `${scope}.THEMES_HOLIDAY_COMPLEX`,
    defaultMessage: 'Holiday Complex',
  },
  THEMES_LARGE_GROUPS : {
    id: `${scope}.THEMES_LARGE_GROUPS`,
    defaultMessage: 'Large Groups',
  },
  THEMES_LONG_STAY : {
    id: `${scope}.THEMES_LONG_STAY`,
    defaultMessage: 'Long Stay',
  },
  THEMES_LUXURY : {
    id: `${scope}.THEMES_LUXURY`,
    defaultMessage: 'Luxury',
  },
  THEMES_MONTHLY_RENTAL : {
    id: `${scope}.THEMES_MONTHLY_RENTAL`,
    defaultMessage: 'Monthly Rental',
  },
  THEMES_NIGHTLIFE : {
    id: `${scope}.THEMES_NIGHTLIFE`,
    defaultMessage: 'Nightlife',
  },
  THEMES_PROMOTIONAL : {
    id: `${scope}.THEMES_PROMOTIONAL`,
    defaultMessage: 'Promotional',
  },
  THEMES_ROMANCE : {
    id: `${scope}.THEMES_ROMANCE`,
    defaultMessage: 'Romance',
  },
  THEMES_SAME_SEX_GROUPS : {
    id: `${scope}.THEMES_SAME_SEX_GROUPS`,
    defaultMessage: 'Same Sex Groups',
  },
  THEMES_SEA_SAND_SUN : {
    id: `${scope}.THEMES_SEA_SAND_SUN`,
    defaultMessage: 'Sea Sand Sun',
  },
  THEMES_SHOPPING : {
    id: `${scope}.THEMES_SHOPPING`,
    defaultMessage: 'Shopping',
  },
  THEMES_SHORT_STAY : {
    id: `${scope}.THEMES_SHORT_STAY`,
    defaultMessage: 'Short Stay',
  },
  THEMES_SKIING : {
    id: `${scope}.THEMES_SKIING`,
    defaultMessage: 'Skiing',
  },
  THEMES_SPA : {
    id: `${scope}.THEMES_SPA`,
    defaultMessage: 'Spa',
  },
  THEMES_SPORTS_ACTIVITIES : {
    id: `${scope}.THEMES_SPORTS_ACTIVITIES`,
    defaultMessage: 'Sports Activities',
  },
  THEMES_STAGS : {
    id: `${scope}.THEMES_STAGS`,
    defaultMessage: 'Stags',
  },
  THEMES_TOURIST_ATTRACTIONS : {
    id: `${scope}.THEMES_TOURIST_ATTRACTIONS`,
    defaultMessage: 'Tourist Attractions',
  },
  THEMES_TROPICAL : {
    id: `${scope}.THEMES_TROPICAL`,
    defaultMessage: 'Tropical',
  },
  THEMES_WILDERNESS : {
    id: `${scope}.THEMES_WILDERNESS`,
    defaultMessage: 'Wilderness',
  },
  TRAVEL_AIRPORT : {
    id: `${scope}.TRAVEL_AIRPORT`,
    defaultMessage: 'Travel Airport',
  },
  TRAVEL_CAR_ESSENTIAL : {
    id: `${scope}.TRAVEL_CAR_ESSENTIAL`,
    defaultMessage: 'Travel Car Essential',
  },
  TRAVEL_CAR_NOT_NECESSARY : {
    id: `${scope}.TRAVEL_CAR_NOT_NECESSARY`,
    defaultMessage: 'Travel Car Not Necessary',
  },
  TRAVEL_CAR_RECOMMENDED : {
    id: `${scope}.TRAVEL_CAR_RECOMMENDED`,
    defaultMessage: 'Travel Car Recommended',
  },
  TRAVEL_FERRY : {
    id: `${scope}.TRAVEL_FERRY`,
    defaultMessage: 'Travel Ferry',
  },
  TRAVEL_PUBLIC_TRANSPORTATION : {
    id: `${scope}.TRAVEL_PUBLIC_TRANSPORTATION`,
    defaultMessage: 'Travel Public Transportation',
  },
  TRAVEL_SKI_SHUTTLE : {
    id: `${scope}.TRAVEL_SKI_SHUTTLE`,
    defaultMessage: 'Travel Ski Shuttle',
  },
  TRAVEL_TAXIS : {
    id: `${scope}.TRAVEL_TAXIS`,
    defaultMessage: 'Travel Taxis',
  },
  TRAVEL_TRAIN_STATION : {
    id: `${scope}.TRAVEL_TRAIN_STATION`,
    defaultMessage: 'Travel Train Station',
  },
  TRAVEL_TRAMS : {
    id: `${scope}.TRAVEL_TRAMS`,
    defaultMessage: 'Travel Trams',
  },
  TRAVEL_WATER_TAXI : {
    id: `${scope}.TRAVEL_WATER_TAXI`,
    defaultMessage: 'Travel Water Taxi',
  },
  WALKABLE_BEACH : {
    id: `${scope}.WALKABLE_BEACH`,
    defaultMessage: 'Walkable Beach',
  },
  WALKABLE_GROCERIES : {
    id: `${scope}.WALKABLE_GROCERIES`,
    defaultMessage: 'Walkable Groceries',
  },
  WALKABLE_PUB : {
    id: `${scope}.WALKABLE_PUB`,
    defaultMessage: 'Walkable Pub',
  },
  WALKABLE_RESTAURANTS : {
    id: `${scope}.WALKABLE_RESTAURANTS`,
    defaultMessage: 'Walkable Restaurants',
  },
  WALKABLE_SHOPPING : {
    id: `${scope}.WALKABLE_SHOPPING`,
    defaultMessage: 'Walkable Shopping',
  },
  LOGISTICS_LUGGAGE_DROPOFF_ALLOWED : {
    id: `${scope}.LOGISTICS_LUGGAGE_DROPOFF_ALLOWED`,
    defaultMessage: 'Luggage Dropoff Allowed',
  },
  LOGISTICS_LONG_TERM_STAYS_ALLOWED : {
    id: `${scope}.LOGISTICS_LONG_TERM_STAYS_ALLOWED`,
    defaultMessage: 'Long Term Stays Allowed',
  },
  LOGISTICS_CLEANING_BEFORE_CHECKOUT : {
    id: `${scope}.LOGISTICS_CLEANING_BEFORE_CHECKOUT`,
    defaultMessage: 'Cleaning Before Checkout',
  },
  EXPECTATIONS_REQUIRED_STAIRS : {
    id: `${scope}.EXPECTATIONS_REQUIRED_STAIRS`,
    defaultMessage: 'Required Stairs',
  },
  EXPECTATIONS_POTENTIAL_NOISE : {
    id: `${scope}.EXPECTATIONS_POTENTIAL_NOISE`,
    defaultMessage: 'Potential Noise',
  },
  EXPECTATIONS_HAS_PETS : {
    id: `${scope}.EXPECTATIONS_HAS_PETS`,
    defaultMessage: 'Has Pets',
  },
  EXPECTATIONS_LIMITED_PARKING : {
    id: `${scope}.EXPECTATIONS_LIMITED_PARKING`,
    defaultMessage: 'Limited Parking',
  },
  EXPECTATIONS_SHARED_SPACES : {
    id: `${scope}.EXPECTATIONS_SHARED_SPACES`,
    defaultMessage: 'Shared Spaces',
  },
  EXPECTATIONS_LIMITED_AMENITIES : {
    id: `${scope}.EXPECTATIONS_LIMITED_AMENITIES`,
    defaultMessage: 'Limited Amenities',
  },
  EXPECTATIONS_SURVEILLANCE : {
    id: `${scope}.EXPECTATIONS_SURVEILLANCE`,
    defaultMessage: 'Surveillance',
  },
  EXPECTATIONS_WEAPONS : {
    id: `${scope}.EXPECTATIONS_WEAPONS`,
    defaultMessage: 'Weapons',
  },
  EXPECTATIONS_ANIMALS : {
    id: `${scope}.EXPECTATIONS_ANIMALS`,
    defaultMessage: 'Animals',
  },
  EXPECTATIONS_OTHER : {
    id: `${scope}.EXPECTATIONS_OTHER`,
    defaultMessage: 'Other',
  },
  SUITABILITY_PETS_ALLOWED : {
    id: `${scope}.SUITABILITY_PETS_ALLOWED`,
    defaultMessage: 'Pets Welcome',
  },
  SUITABILITY_PETS_ASK : {
    id: `${scope}.SUITABILITY_PETS_ASK`,
    defaultMessage: 'Pets (Please Enquire)',
  },
  SUITABILITY_PETS_ALLOWED_WITH_RESTRICTIONS : {
    id: `${scope}.SUITABILITY_PETS_ALLOWED_WITH_RESTRICTIONS`,
    defaultMessage: 'Pets Allowed (With Restrictions)',
  },
  SUITABILITY_PETS_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_PETS_NOT_ALLOWED`,
    defaultMessage: 'Pets Not Allowed',
  },
  SUITABILITY_CHILDREN_ASK : {
    id: `${scope}.SUITABILITY_CHILDREN_ASK`,
    defaultMessage: 'Children Restrictions Apply (Please Enquire)',
  },
  SUITABILITY_CHILDREN_WELCOME : {
    id: `${scope}.SUITABILITY_CHILDREN_WELCOME`,
    defaultMessage: 'Children Welcome',
  },
  SUITABILITY_CHILDREN_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_CHILDREN_NOT_ALLOWED`,
    defaultMessage: 'Children Not Allowed',
  },
  SUITABILITY_SMOKING_ASK : {
    id: `${scope}.SUITABILITY_SMOKING_ASK`,
    defaultMessage: 'Smoking (Please Enquire)',
  },
  SUITABILITY_SMOKING_ALLOWED : {
    id: `${scope}.SUITABILITY_SMOKING_ALLOWED`,
    defaultMessage: 'Smoking Allowed',
  },
  SUITABILITY_SMOKING_ALLOWED_WITH_RESTRICTIONS : {
    id: `${scope}.SUITABILITY_SMOKING_ALLOWED_WITH_RESTRICTIONS`,
    defaultMessage: 'Smoking Allowed With Restrictions',
  },
  SUITABILITY_SMOKING_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_SMOKING_NOT_ALLOWED`,
    defaultMessage: 'Smoking Not Allowed',
  },
  SUITABILITY_ACCESSIBILITY_ELDERLY_ASK : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_ELDERLY_ASK`,
    defaultMessage: 'Elderly Ask',
  },
  SUITABILITY_ACCESSIBILITY_ELDERLY_GREAT : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_ELDERLY_GREAT`,
    defaultMessage: 'Elderly Great',
  },
  SUITABILITY_ACCESSIBILITY_ELDERLY_LIMITED_ACCESSIBILITY : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_ELDERLY_LIMITED_ACCESSIBILITY`,
    defaultMessage: 'Elderly Limited Accessibility',
  },
  SUITABILITY_ACCESSIBILITY_ELDERLY_NOT_RECOMMENDED : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_ELDERLY_NOT_RECOMMENDED`,
    defaultMessage: 'Elderly Not Recommended',
  },
  SUITABILITY_ACCESSIBILITY_WHEELCHAIR_ASK : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_WHEELCHAIR_ASK`,
    defaultMessage: 'Wheelchair Accessibility (Enquire For Arrangement)',
  },
  SUITABILITY_ACCESSIBILITY_WHEELCHAIR_GREAT : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_WHEELCHAIR_GREAT`,
    defaultMessage: 'Wheelchair Accessibility',
  },
  SUITABILITY_ACCESSIBILITY_WHEELCHAIR_LIMITED_ACCESSIBILITY : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_WHEELCHAIR_LIMITED_ACCESSIBILITY`,
    defaultMessage: 'Limited Wheelchair Accessibility',
  },
  SUITABILITY_ACCESSIBILITY_WHEELCHAIR_NOT_ACCESSIBLE : {
    id: `${scope}.SUITABILITY_ACCESSIBILITY_WHEELCHAIR_NOT_ACCESSIBLE`,
    defaultMessage: 'Not Wheelchair Accessible',
  },
  SUITABILITY_SENIOR_ADULTS_ONLY : {
    id: `${scope}.SUITABILITY_SENIOR_ADULTS_ONLY`,
    defaultMessage: 'Senior Adults Only',
  },
  SUITABILITY_GREAT_FOR_SAME_SEX_COUPLES : {
    id: `${scope}.SUITABILITY_GREAT_FOR_SAME_SEX_COUPLES`,
    defaultMessage: 'Great For Same Sex Couples',
  },
  SUITABILITY_EVENTS_CONSIDERED : {
    id: `${scope}.SUITABILITY_EVENTS_CONSIDERED`,
    defaultMessage: 'Events Considered',
  },
  SUITABILITY_EVENTS_ALLOWED : {
    id: `${scope}.SUITABILITY_EVENTS_ALLOWED`,
    defaultMessage: 'Events Allowed',
  },
  SUITABILITY_EVENTS_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_EVENTS_NOT_ALLOWED`,
    defaultMessage: 'Events Not Allowed',
  },
  SUITABILITY_WEDDINGS_CONSIDERED : {
    id: `${scope}.SUITABILITY_WEDDINGS_CONSIDERED`,
    defaultMessage: 'Weddings Considered',
  },
  SUITABILITY_WEDDINGS_WELCOME : {
    id: `${scope}.SUITABILITY_WEDDINGS_WELCOME`,
    defaultMessage: 'Weddings Welcome',
  },
  SUITABILITY_WEDDINGS_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_WEDDINGS_NOT_ALLOWED`,
    defaultMessage: 'Weddings Not Allowed',
  },
  SUITABILITY_LONG_TERM_RENTERS_ASK : {
    id: `${scope}.SUITABILITY_LONG_TERM_RENTERS_ASK`,
    defaultMessage: 'Long Term Renters (Please Enquire)',
  },
  SUITABILITY_LONG_TERM_RENTERS_ALLOWED : {
    id: `${scope}.SUITABILITY_LONG_TERM_RENTERS_ALLOWED`,
    defaultMessage: 'Long Term Renters Allowed',
  },
  SUITABILITY_LONG_TERM_RENTERS_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_LONG_TERM_RENTERS_NOT_ALLOWED`,
    defaultMessage: 'Long Term Renters Not Allowed',
  },
  SUITABILITY_GROUPS_ALLOWED : {
    id: `${scope}.SUITABILITY_GROUPS_ALLOWED`,
    defaultMessage: 'Groups Allowed',
  },
  SUITABILITY_GROUPS_ASK : {
    id: `${scope}.SUITABILITY_GROUPS_ASK`,
    defaultMessage: 'Group Bookings (Please Enquire)',
  },
  SUITABILITY_GROUPS_NOT_ALLOWED : {
    id: `${scope}.SUITABILITY_GROUPS_NOT_ALLOWED`,
    defaultMessage: 'Groups Not Allowed',
  },
  LISTING_TYPE_UNSPECIFIED : {
    id: `${scope}.LISTING_TYPE_UNSPECIFIED`,
    defaultMessage: 'Unspecified',
  },
  LISTING_TYPE_LODGE: {
    id: `${scope}.LISTING_TYPE_LODGE`,
    defaultMessage: 'Lodge',
  },
  LISTING_TYPE_APARTMENT : {
    id: `${scope}.LISTING_TYPE_APARTMENT`,
    defaultMessage: 'Apartment',
  },
  LISTING_TYPE_BUILDING : {
    id: `${scope}.LISTING_TYPE_BUILDING`,
    defaultMessage: 'Building',
  },
  LISTING_TYPE_BARN : {
    id: `${scope}.LISTING_TYPE_BARN`,
    defaultMessage: 'Barn',
  },
  LISTING_TYPE_HOUSE : {
    id: `${scope}.LISTING_TYPE_HOUSE`,
    defaultMessage: 'House',
  },
  LISTING_TYPE_VILLA : {
    id: `${scope}.LISTING_TYPE_VILLA`,
    defaultMessage: 'Villa',
  },
  LISTING_TYPE_TOWNHOUSE : {
    id: `${scope}.LISTING_TYPE_TOWNHOUSE`,
    defaultMessage: 'Townhouse',
  },
  LISTING_TYPE_CHALET : {
    id: `${scope}.LISTING_TYPE_CHALET`,
    defaultMessage: 'Chalet',
  },
  LISTING_TYPE_FARMHOUSE : {
    id: `${scope}.LISTING_TYPE_FARMHOUSE`,
    defaultMessage: 'Farmhouse',
  },
  LISTING_TYPE_CASTLE : {
    id: `${scope}.LISTING_TYPE_CASTLE`,
    defaultMessage: 'Castle',
  },
  LISTING_TYPE_COTTAGE : {
    id: `${scope}.LISTING_TYPE_COTTAGE`,
    defaultMessage: 'Cottage',
  },
  LISTING_TYPE_RESORT : {
    id: `${scope}.LISTING_TYPE_RESORT`,
    defaultMessage: 'Resort',
  },
  LISTING_TYPE_BNB : {
    id: `${scope}.LISTING_TYPE_BNB`,
    defaultMessage: 'BnB',
  },
  LISTING_TYPE_HOTEL : {
    id: `${scope}.LISTING_TYPE_HOTEL`,
    defaultMessage: 'Hotel',
  },
  LISTING_TYPE_BOAT : {
    id: `${scope}.LISTING_TYPE_BOAT`,
    defaultMessage: 'Boat',
  },
  LISTING_TYPE_CONDO : {
    id: `${scope}.LISTING_TYPE_CONDO`,
    defaultMessage: 'Condo',
  },
  LISTING_TYPE_HOUSE_BOAT : {
    id: `${scope}.LISTING_TYPE_HOUSE_BOAT`,
    defaultMessage: 'Boat',
  },
  LISTING_TYPE_CABIN : {
    id: `${scope}.LISTING_TYPE_CABIN`,
    defaultMessage: 'Cabin',
  },
  LISTING_TYPE_HOSTEL : {
    id: `${scope}.LISTING_TYPE_HOSTEL`,
    defaultMessage: 'Hostel',
  },
  LISTING_TYPE_BEACH_HUT : {
    id: `${scope}.LISTING_TYPE_BEACH_HUT`,
    defaultMessage: 'Beach Hut',
  },
  LISTING_TYPE_GITE : {
    id: `${scope}.LISTING_TYPE_GITE`,
    defaultMessage: 'Gite',
  },
  LISTING_TYPE_SUITE : {
    id: `${scope}.LISTING_TYPE_SUITE`,
    defaultMessage: 'Suite',
  },
  LISTING_TYPE_STUDIO : {
    id: `${scope}.LISTING_TYPE_STUDIO`,
    defaultMessage: 'Studio',
  },
  LISTING_TYPE_BUNGALOW : {
    id: `${scope}.LISTING_TYPE_BUNGALOW`,
    defaultMessage: 'Bungalow',
  },
  LISTING_TYPE_CHATEAU : {
    id: `${scope}.LISTING_TYPE_CHATEAU`,
    defaultMessage: 'Chateau',
  },
  LISTING_TYPE_CARAVAN : {
    id: `${scope}.LISTING_TYPE_CARAVAN`,
    defaultMessage: 'Caravan',
  },
  LISTING_TYPE_PITCH : {
    id: `${scope}.LISTING_TYPE_PITCH`,
    defaultMessage: 'Pitch',
  },
  LISTING_TYPE_PENTHOUSE : {
    id: `${scope}.LISTING_TYPE_PENTHOUSE`,
    defaultMessage: 'Penthouse',
  },
  LISTING_TYPE_CAMPER_VAN : {
    id: `${scope}.LISTING_TYPE_CAMPER_VAN`,
    defaultMessage: 'Camper van',
  },
  METERS_SQUARED : {
    id: `${scope}.METERS_SQUARED`,
    defaultMessage: 'Meters squared',
  },
  FEET_SQUARED : {
    id: `${scope}.FEET_SQUARED`,
    defaultMessage: 'Feet squared',
  },
  ENTIRE_LISTING : {
    id: `${scope}.ENTIRE_LISTING`,
    defaultMessage: 'Entire listing',
  },
  ROOM_OWN_FACILITIES : {
    id: `${scope}.ROOM_OWN_FACILITIES`,
    defaultMessage: 'Own facilities',
  },
  ROOM_SHARED_FACILITIES : {
    id: `${scope}.ROOM_SHARED_FACILITIES`,
    defaultMessage: 'Shared facilities',
  },
  NON_PROVIDED : {
    id: `${scope}.NON_PROVIDED`,
    defaultMessage: 'Non provided',
  },
  BREAKFAST_AVAILABLE : {
    id: `${scope}.BREAKFAST_AVAILABLE`,
    defaultMessage: 'Breakfast available',
  },
  LUNCH_AVAILABLE : {
    id: `${scope}.LUNCH_AVAILABLE`,
    defaultMessage: 'Lunch available',
  },
  DINNER_AVAILABLE : {
    id: `${scope}.DINNER_AVAILABLE`,
    defaultMessage: 'Dinner available',
  },
  BREAKFAST_DINNER_AVAILABLE : {
    id: `${scope}.BREAKFAST_DINNER_AVAILABLE`,
    defaultMessage: 'Breakfast dinner available',
  },
  FULL_MEALS_AVAILABLE : {
    id: `${scope}.FULL_MEALS_AVAILABLE`,
    defaultMessage: 'Full meals available',
  },
  HOUSEKEEPING_SUPPLIED : {
    id: `${scope}.HOUSEKEEPING_SUPPLIED`,
    defaultMessage: 'Housekeeping supplied',
  },
  HOUSEKEEPING_AVAILABLE : {
    id: `${scope}.HOUSEKEEPING_AVAILABLE`,
    defaultMessage: 'Housekeeping available',
  },
  BEDROOM: {
    id: `${scope}.BEDROOM`,
    defaultMessage: 'Bedroom',
  },
  SINGLE: {
    id: `${scope}.SINGLE`,
    defaultMessage: 'Single',
  },
  SINGLE_BUNK: {
    id: `${scope}.SINGLE_BUNK`,
    defaultMessage: 'Single bunk',
  },
  SINGLE_ZIP: {
    id: `${scope}.SINGLE_ZIP`,
    defaultMessage: 'Single zip',
  },
  DOUBLE: {
    id: `${scope}.DOUBLE`,
    defaultMessage: 'Double',
  },
  DOUBLE_BUNK: {
    id: `${scope}.DOUBLE_BUNK`,
    defaultMessage: 'Double bunk',
  },
  DOUBLE_SINGLE_BUNK: {
    id: `${scope}.DOUBLE_SINGLE_BUNK`,
    defaultMessage: 'Double single bunk',
  },
  QUEEN_SINGLE_BUNK: {
    id: `${scope}.QUEEN_SINGLE_BUNK`,
    defaultMessage: 'Queen single bunk',
  },
  TRIPLE_BUNK: {
    id: `${scope}.TRIPLE_BUNK`,
    defaultMessage: 'Triple bunk',
  },
  KING: {
    id: `${scope}.KING`,
    defaultMessage: 'King',
  },
  KING_SUPER: {
    id: `${scope}.KING_SUPER`,
    defaultMessage: 'Super king',
  },
  QUEEN: {
    id: `${scope}.QUEEN`,
    defaultMessage: 'Queen bed',
  },
  SOFA_QUEEN: {
    id: `${scope}.SOFA_QUEEN`,
    defaultMessage: 'Sofa queen',
  },
  SOFA_DOUBLE: {
    id: `${scope}.SOFA_DOUBLE`,
    defaultMessage: 'Sofa double',
  },
  SOFA_SINGLE: {
    id: `${scope}.SOFA_SINGLE`,
    defaultMessage: 'Sofa single',
  },
  FOLDING: {
    id: `${scope}.FOLDING`,
    defaultMessage: 'Folding bed',
  },
  OTHER: {
    id: `${scope}.OTHER`,
    defaultMessage: 'Other bed',
  },
  INFLATABLE: {
    id: `${scope}.INFLATABLE`,
    defaultMessage: 'Inflatable bed',
  },
  WATER: {
    id: `${scope}.WATER`,
    defaultMessage: 'Water bed',
  },
  HOT_TUB: {
    id: `${scope}.HOT_TUB`,
    defaultMessage: 'Hot tub',
  },
  BATH: {
    id: `${scope}.BATH`,
    defaultMessage: 'Bath',
  },
  WHIRLPOOL: {
    id: `${scope}.WHIRLPOOL`,
    defaultMessage: 'Whirlpool',
  },
  SHOWER: {
    id: `${scope}.SHOWER`,
    defaultMessage: 'Shower',
  },
  WASH_BASIN: {
    id: `${scope}.WASH_BASIN`,
    defaultMessage: 'Wash basin',
  },
  BIDET: {
    id: `${scope}.BIDET`,
    defaultMessage: 'Bidet',
  },
  TOILET: {
    id: `${scope}.TOILET`,
    defaultMessage: 'Toilet',
  },
  BOOKING_TERMS: {
    id: `${scope}.BOOKING_TERMS`,
    defaultMessage: 'Booking terms',
  },
  CUSTOM_COMMUNICATIONINTERNET_COMMUNICATIONINTERNETWIFI: {
    id: `${scope}.CUSTOM_COMMUNICATIONINTERNET_COMMUNICATIONINTERNETWIFI`,
    defaultMessage: 'Internet',
  },
  CUSTOM_GENERALPARKING_GENERALDEDICATEDPRIVATEPARKING: {
    id: `${scope}.CUSTOM_GENERALPARKING_GENERALDEDICATEDPRIVATEPARKING`,
    defaultMessage: 'Dedicated private parking',
  },
  CUSTOM_GENERALPARKING_GENERALSHAREDPRIVATEPARKING: {
    id: `${scope}.CUSTOM_GENERALPARKING_GENERALSHAREDPRIVATEPARKING`,
    defaultMessage: 'Shared private parking',
  },
  CUSTOM_GENERALPARKING_GENERALONSTREETFREEPARKING: {
    id: `${scope}.CUSTOM_GENERALPARKING_GENERALONSTREETFREEPARKING`,
    defaultMessage: 'On street parking',
  },
  CUSTOM_GENERALPARKING_GENERALPUBLICCARPARK: {
    id: `${scope}.CUSTOM_GENERALPARKING_GENERALPUBLICCARPARK`,
    defaultMessage: 'Public car park',
  },
  CUSTOM_SPAPOOLSWIMMINGPOOL_SPAPOOLPOOLPRIVATE: {
    id: `${scope}.CUSTOM_SPAPOOLSWIMMINGPOOL_SPAPOOLPOOLPRIVATE`,
    defaultMessage: 'Private Spa Pool',
  },
  SERVICE_FEE : {
    id: `${scope}.SERVICE_FEE`,
    defaultMessage: 'Service Fee',
  },
  TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE: {
    id: `${scope}.TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE`,
    defaultMessage: 'Service Fee',
  },
  SERVICE_FEE_DESCRIPTION : {
    id: `${scope}.SERVICE_FEE_DESCRIPTION`,
    defaultMessage: 'To help us run smoothly',
  },
  TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE_DESCRIPTION: {
    id: `${scope}.TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE_DESCRIPTION`,
    defaultMessage: 'To help us run smoothly',
  },
  DAMAGE_DEPOSIT : {
    id: `${scope}.DAMAGE_DEPOSIT`,
    defaultMessage: 'Damage Deposit',
  },
  DAMAGE_DEPOSIT_DESCRIPTION : {
    id: `${scope}.DAMAGE_DEPOSIT_DESCRIPTION`,
    defaultMessage: ' ',
  },
  MIN_STAY_NOT_MET : {
    id: `${scope}.MIN_STAY_NOT_MET`,
    defaultMessage: 'This property requires a minimum stay of {unit} days',
  },
  TYPE_NO_RATES_CONFIGURED : {
    id: `${scope}.TYPE_NO_RATES_CONFIGURED`,
    defaultMessage: 'Pricing has not been configured for this property',
  },
  TYPE_OTHER : {
    id: `${scope}.TYPE_OTHER`,
    defaultMessage: 'Sorry, there has been an issue generating a price for this property',
  },
  TYPE_CLEANING_FEE : {
    id: `${scope}.TYPE_CLEANING_FEE`,
    defaultMessage: 'Cleaning',
  },
  TYPE_CLEANING_FEE_DESCRIPTION : {
    id: `${scope}.TYPE_CLEANING_FEE_DESCRIPTION`,
    defaultMessage: 'Cleaning',
  },
  Cleaning : {
    id: `${scope}.Cleaning`,
    defaultMessage: 'Cleaning',
  },
  TYPE_EXTRA_GUEST_FEE : {
    id: `${scope}.TYPE_EXTRA_GUEST_FEE`,
    defaultMessage: 'Extra guest fee',
  },
  TYPE_DAMAGE_DEPOSIT : {
    id: `${scope}.TYPE_DAMAGE_DEPOSIT`,
    defaultMessage: 'Damage deposit',
  },
  TYPE_CHANNEL_ADJUSTMENT_FEE : {
    id: `${scope}.TYPE_CHANNEL_ADJUSTMENT_FEE`,
    defaultMessage: 'Channel fee',
  },
  TYPE_CHANNEL_GUEST_SERVICE_FEE : {
    id: `${scope}.TYPE_CHANNEL_GUEST_SERVICE_FEE`,
    defaultMessage: 'Guest service fee',
  },
  TYPE_UNKNOWN_EXTRA : {
    id: `${scope}.TYPE_UNKNOWN_EXTRA`,
    defaultMessage: 'Unknown fee',
  },
  TYPE_DISCOUNT : {
    id: `${scope}.TYPE_DISCOUNT`,
    defaultMessage: 'Discount'
  },
  TYPE_TRANSFER : {
    id: `${scope}.TYPE_TRANSFER`,
    defaultMessage: 'Transfer',
  },
  TYPE_COMMISSION : {
    id: `${scope}.TYPE_COMMISSION`,
    defaultMessage: 'Commission',
  },
  TYPE_DAMAGE_WAIVER : {
    id: `${scope}.TYPE_DAMAGE_WAIVER`,
    defaultMessage: 'Damage waiver',
  },
  TYPE_BOOKING_FEE : {
    id: `${scope}.TYPE_BOOKING_FEE`,
    defaultMessage: 'Booking fee',
  },
  TYPE_MANAGEMENT : {
    id: `${scope}.TYPE_MANAGEMENT`,
    defaultMessage: 'Management',
  },
  TYPE_SERVICE : {
    id: `${scope}.TYPE_SERVICE`,
    defaultMessage: 'Service',
  },
  TYPE_PET : {
    id: `${scope}.TYPE_PET`,
    defaultMessage: 'Pet',
  },
  TYPE_INTERNET : {
    id: `${scope}.TYPE_INTERNET`,
    defaultMessage: 'Internet',
  },
  TYPE_DEPARTURE_EARLY : {
    id: `${scope}.TYPE_DEPARTURE_EARLY`,
    defaultMessage: 'Departure early',
  },
  TYPE_DEPARTURE_LATE : {
    id: `${scope}.TYPE_DEPARTURE_LATE`,
    defaultMessage: 'Departure late',
  },
  TYPE_GAS : {
    id: `${scope}.TYPE_GAS`,
    defaultMessage: 'Gas',
  },
  TYPE_OIL : {
    id: `${scope}.TYPE_OIL`,
    defaultMessage: 'Oil',
  },
  DISCOUNT_LAST_MINUTE: {
    id: `${scope}.DISCOUNT_LAST_MINUTE`,
    defaultMessage: 'Last minute',
  },
  DISCOUNT_EARLY_BIRD: {
    id: `${scope}.DISCOUNT_EARLY_BIRD`,
    defaultMessage: 'Early bird',
  },
  FLAG_IS_ACTIVE: {
    id: `${scope}.FLAGS_IS_ACTIVE`,
    defaultMessage: 'Active',
  },
  FLAG_IS_INSTANT_BOOKABLE: {
    id: `${scope}.FLAG_IS_INSTANT_BOOKABLE`,
    defaultMessage: 'Instant bookable',
  },
  FLAG_IS_FEATURED: {
    id: `${scope}.FLAG_IS_FEATURED`,
    defaultMessage: 'Featured',
  },
  COMMERCE_BILLING_ERROR_SCA_DECLINED: {
    id: `${scope}.COMMERCE_BILLING_ERROR_SCA_DECLINED`,
    defaultMessage: 'SCA declined',
  },
  COMMERCE_BILLING_ERROR_SCA_VERSION_NOT_SUPPORTED: {
    id: `${scope}.COMMERCE_BILLING_ERROR_SCA_VERSION_NOT_SUPPORTED`,
    defaultMessage: 'SCA version not supported',
  },
  COMMERCE_BILLING_ERROR_SCA_ERROR: {
    id: `${scope}.COMMERCE_BILLING_ERROR_SCA_ERROR`,
    defaultMessage: 'SCA error',
  },
  COMMERCE_ACCEPTED_PAYMENT_METHOD_MISMATCH: {
    id: `${scope}.COMMERCE_ACCEPTED_PAYMENT_METHOD_MISMATCH`,
    defaultMessage: 'Unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_AGE_RESTRICTION: {
    id: `${scope}.COMMERCE_AGE_RESTRICTION`,
    defaultMessage: 'Too many children selected',
  },
  COMMERCE_BILLING_ERROR: {
    id: `${scope}.COMMERCE_BILLING_ERROR`,
    defaultMessage: 'We\'re sorry, but the dates you are trying to reserve are no longer available. Please select new stay dates and try again.',
  },
  COMMERCE_BILLING_ERROR_BILLING_ADDRESS_MISMATCH: {
    id: `${scope}.COMMERCE_BILLING_ERROR_BILLING_ADDRESS_MISMATCH`,
    defaultMessage: 'Please verify all information and try again.',
  },
  COMMERCE_BILLING_ERROR_CC_DECLINED: {
    id: `${scope}.COMMERCE_BILLING_ERROR_CC_DECLINED`,
    defaultMessage: 'The credit card was declined. Please provide a different credit card.',
  },
  COMMERCE_BILLING_ERROR_DEBIT_CARD_NOT_SUPPORTED: {
    id: `${scope}.COMMERCE_BILLING_ERROR_DEBIT_CARD_NOT_SUPPORTED`,
    defaultMessage: 'We\'re sorry, debit cards are not supported. Please enter a valid payment method.',
  },
  COMMERCE_BILLING_ERROR_INSUFFICIENT_FUNDS: {
    id: `${scope}.COMMERCE_BILLING_ERROR_INSUFFICIENT_FUNDS`,
    defaultMessage: 'The credit card provided does not have sufficient funds to complete the transaction. Please provide a different credit card.',
  },
  COMMERCE_BILLING_ERROR_INVALID_BILLING_ADDRESS: {
    id: `${scope}.COMMERCE_BILLING_ERROR_INVALID_BILLING_ADDRESS`,
    defaultMessage: 'The billing address is invalid.',
  },
  COMMERCE_BILLING_ERROR_INVALID_CC_NUMBER: {
    id: `${scope}.COMMERCE_BILLING_ERROR_INVALID_CC_NUMBER`,
    defaultMessage: 'The credit card number is invalid. Please provide a different credit card number.',
  },
  COMMERCE_BILLING_ERROR_INVALID_CVV_CODE: {
    id: `${scope}.COMMERCE_BILLING_ERROR_INVALID_CVV_CODE`,
    defaultMessage: 'The CVV code is invalid. Please provide the correct CVV code for this credit card.',
  },
  COMMERCE_BILLING_ERROR_INVALID_EXPIRATION_DATE: {
    id: `${scope}.COMMERCE_BILLING_ERROR_INVALID_EXPIRATION_DATE`,
    defaultMessage: 'The expiration date provided for this credit card is invalid. Please provide the correct expiration date for this credit card.',
  },
  COMMERCE_BILLING_ERROR_NO_CREDIT_CARD_CONFIGURED: {
    id: `${scope}.COMMERCE_BILLING_ERROR_NO_CREDIT_CARD_CONFIGURED`,
    defaultMessage: 'A configuration error occurred for this property. Please select another property or contact the owner of this property.',
  },
  COMMERCE_CHANGE_OVER_DAY_MISMATCH: {
    id: `${scope}.COMMERCE_CHANGE_OVER_DAY_MISMATCH`,
    defaultMessage: 'This property requires your stay to begin and end on the same day of the week',
  },
  COMMERCE_END_DAY_MISMATCH: {
    id: `${scope}.COMMERCE_END_DAY_MISMATCH`,
    defaultMessage: 'This property requires your stay to end on a different day',
  },
  COMMERCE_EXCEEDS_MAX_OCCUPANCY: {
    id: `${scope}.COMMERCE_EXCEEDS_MAX_OCCUPANCY`,
    defaultMessage: 'Guest limit exceeded',
  },
  COMMERCE_EXCEEDS_MAX_STAY: {
    id: `${scope}.COMMERCE_EXCEEDS_MAX_STAY`,
    defaultMessage: 'Quote cannot be created at this time (enhancement request for specific message in progress)',
  },
  COMMERCE_HTTP_OPERATION_FAILED: {
    id: `${scope}.COMMERCE_HTTP_OPERATION_FAILED`,
    defaultMessage: 'We\'re sorry, we are unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_INSUFFICIENT_PAYMENT_METHOD_INFORMATION: {
    id: `${scope}.COMMERCE_INSUFFICIENT_PAYMENT_METHOD_INFORMATION`,
    defaultMessage: 'Unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_INVALID_PAYMENT_METHOD: {
    id: `${scope}.COMMERCE_INVALID_PAYMENT_METHOD`,
    defaultMessage: 'We\'re sorry, we are unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_MERCHANT_ACCOUNT_ERROR: {
    id: `${scope}.COMMERCE_MERCHANT_ACCOUNT_ERROR`,
    defaultMessage: 'Quote cannot be created at this time',
  },
  COMMERCE_MIN_ADVANCED_NOTICE_NOT_MET: {
    id: `${scope}.COMMERCE_MIN_ADVANCED_NOTICE_NOT_MET`,
    defaultMessage: 'This property requires more advanced notice to book',
  },
  COMMERCE_MIN_STAY_NOT_MET: {
    id: `${scope}.COMMERCE_MIN_STAY_NOT_MET`,
    defaultMessage: 'Minimum stay requirement not met',
  },
  COMMERCE_NO_ACCEPTED_PAYMENT_METHODS: {
    id: `${scope}.COMMERCE_NO_ACCEPTED_PAYMENT_METHODS`,
    defaultMessage: 'Quote cannot be created at this time',
  },
  COMMERCE_NO_RATES_CONFIGURED: {
    id: `${scope}.COMMERCE_NO_RATES_CONFIGURED`,
    defaultMessage: 'Oops... Unfortunately a quote cannot be created at this time. Submit an inquiry and a quote will be emailed to you.',
  },
  COMMERCE_NO_TRAVELER_EMAIL_FOR_INQUIRY: {
    id: `${scope}.COMMERCE_NO_TRAVELER_EMAIL_FOR_INQUIRY`,
    defaultMessage: 'Quote cannot be created at this time',
  },
  COMMERCE_OLB_PROVIDER_NOT_PROVISIONED: {
    id: `${scope}.COMMERCE_OLB_PROVIDER_NOT_PROVISIONED`,
    defaultMessage: 'Quote cannot be created at this time',
  },
  COMMERCE_OTHER: {
    id: `${scope}.COMMERCE_OTHER`,
    defaultMessage: 'We\'re sorry, we are unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_PETS_NOT_ALLOWED: {
    id: `${scope}.COMMERCE_PETS_NOT_ALLOWED`,
    defaultMessage: 'Pets are not allowed',
  },
  COMMERCE_PROPERTY_NOT_AVAILABLE: {
    id: `${scope}.COMMERCE_PROPERTY_NOT_AVAILABLE`,
    defaultMessage: 'We\'re sorry, but the dates you are trying to reserve are no longer available. Please select new stay dates and try again.',
  },
  COMMERCE_QUOTE_CURRENCY_MISMATCH: {
    id: `${scope}.COMMERCE_QUOTE_CURRENCY_MISMATCH`,
    defaultMessage: 'Unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_QUOTE_PRICE_MISMATCH: {
    id: `${scope}.COMMERCE_QUOTE_PRICE_MISMATCH`,
    defaultMessage: 'We\'re sorry, but the rates for this stay have changed. Please review the updated quote before resubmitting your payment.',
  },
  COMMERCE_SERVER_ERROR: {
    id: `${scope}.COMMERCE_SERVER_ERROR`,
    defaultMessage: 'We\'re sorry, we are unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_SERVICE_UNAVAILABLE: {
    id: `${scope}.COMMERCE_SERVICE_UNAVAILABLE`,
    defaultMessage: 'Quote cannot be created at this time',
  },
  COMMERCE_START_DAY_MISMATCH: {
    id: `${scope}.COMMERCE_START_DAY_MISMATCH`,
    defaultMessage: 'This property requires your stay to begin on a different day',
  },
  COMMERCE_STAY_DATE_RECOMMENDATION: {
    id: `${scope}.COMMERCE_STAY_DATE_RECOMMENDATION`,
    defaultMessage: 'Quote cannot be created at this time',
  },
  COMMERCE_STAY_NIGHT_INCREMENT_MISMATCH: {
    id: `${scope}.COMMERCE_STAY_NIGHT_INCREMENT_MISMATCH`,
    defaultMessage: 'This property requires stays to be booked in specific increments',
  },
  COMMERCE_UNKNOWN_PROPERTY: {
    id: `${scope}.COMMERCE_UNKNOWN_PROPERTY`,
    defaultMessage: 'We\'re sorry, we are unable to complete your booking. Please try again in a few minutes.',
  },
  COMMERCE_VALIDATION: {
    id: `${scope}.COMMERCE_VALIDATION`,
    defaultMessage: 'We\'re sorry, we are unable to complete your booking. Please try again in a few minutes.',
  },
  DISTANCE_COAST : {
    id: `${scope}.DISTANCE_COAST`,
    defaultMessage: 'Coast: Nearby',
  },
  DISTANCE_TRAIN : {
    id: `${scope}.DISTANCE_TRAIN`,
    defaultMessage: 'Train Station: Nearby',
  },
  DISTANCE_SHOPS : {
    id: `${scope}.DISTANCE_SHOPS`,
    defaultMessage: 'Shops: Nearby',
  },
  DISTANCE_BEACH : {
    id: `${scope}.DISTANCE_BEACH`,
    defaultMessage: 'Beach: Nearby',
  },
  DISTANCE_METRO_STATION : {
    id: `${scope}.DISTANCE_METRO_STATION`,
    defaultMessage: 'Metro Station: Nearby',
  },
  DISTANCE_AIRPORT : {
    id: `${scope}.DISTANCE_AIRPORT`,
    defaultMessage: 'Airport: Nearby',
  },
  DISTANCE_NIGHTLIFE : {
    id: `${scope}.DISTANCE_NIGHTLIFE`,
    defaultMessage: 'Nightlife: Nearby',
  },
  DISTANCE_RESTAURANT : {
    id: `${scope}.DISTANCE_RESTAURANT`,
    defaultMessage: 'Restaurant: Nearby',
  },
  DISTANCE_BUS_STOP : {
    id: `${scope}.DISTANCE_BUS_STOP`,
    defaultMessage: 'Bus Stop: Nearby',
  },
  DISTANCE_LOCAL_PUB : {
    id: `${scope}.DISTANCE_LOCAL_PUB`,
    defaultMessage: 'Local Pub: Nearby',
  },
  DISTANCE_PET_WALKING : {
    id: `${scope}.DISTANCE_PET_WALKING`,
    defaultMessage: 'Pet Walking: Nearby',
  },
  DISTANCE_PARK : {
    id: `${scope}.DISTANCE_PARK`,
    defaultMessage: 'Park: Nearby',
  },
  DISTANCE_PARK_DOG_FRIENDLY : {
    id: `${scope}.DISTANCE_PARK_DOG_FRIENDLY`,
    defaultMessage: 'Dog-friendly Park: Nearby',
  },
  DISTANCE_HOSPITAL : {
    id: `${scope}.DISTANCE_HOSPITAL`,
    defaultMessage: 'Hospital: Nearby',
  },
  DISTANCE_DOCTORS : {
    id: `${scope}.DISTANCE_DOCTORS`,
    defaultMessage: 'Doctors: Nearby',
  },
  DISTANCE_PHARMACY_CHEMIST_APOTHECARY : {
    id: `${scope}.DISTANCE_PHARMACY_CHEMIST_APOTHECARY`,
    defaultMessage: 'Pharmacy: Nearby',
  },
  DISTANCE_COACH_STATION : {
    id: `${scope}.DISTANCE_COACH_STATION`,
    defaultMessage: 'Coach Station: Nearby',
  },
  NEARBY : {
    id: `${scope}.NEARBY`,
    defaultMessage: 'Nearby',
  },
  METERS : {
    id: `${scope}.METERS`,
    defaultMessage: 'm',
  },
  MILES : {
    id: `${scope}.MILES`,
    defaultMessage: 'mi',
  },
  YARDS : {
    id: `${scope}.YARDS`,
    defaultMessage: 'yd',
  },
  MINUTES_BY_CAR : {
    id: `${scope}.MINUTES_BY_CAR`,
    defaultMessage: 'Min. by car',
  },
  MINUTES_BY_FOOT : {
    id: `${scope}.MINUTES_BY_FOOT`,
    defaultMessage: 'Min. by foot',
  },
  KILOMETERS : {
    id: `${scope}.KILOMETERS`,
    defaultMessage: 'km',
  }
});
