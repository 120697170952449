import React from 'react';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import { Button, LocaleLink } from '@rentivo/gatsby-core/index';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Box, chakra, Heading, Text } from '@chakra-ui/react';
import AspectRatio from '@rentivo/gatsby-core/src/components/ui/AspectRatio';

const CardBlock = ({attrs, parentAttrs, childProps = {}, className}) => {
  const {
    bgOverlayColor = 'transparent',
    image: imageRaw,
    imageObject,
    link,
    overlayImage = false,
    subtitle,
    title,
    aspect = '1/1',
    ribbonText = '',
    buttonText = '',
    alignContent = 'center',
    coverClassName = '',
    metaClassName = '',
    titleClassName = '',
    subtitleClassName = '',
    ribbonClassName = '',
    buttonClassName = '',
    contentClassName = '',
    content = null,
    noPad = false,
    showPlaceholderImage = false
  } = attrs || {};

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_card');
  const { options: co, ...coverClassNameProps } = useClassNameProps(coverClassName, 'wpg-b_card_cover');
  const { options: mo, ...metaClassNameProps } = useClassNameProps(metaClassName, 'wpg-b_card_meta');
  const { options: to, ...titleClassNameProps } = useClassNameProps(titleClassName, 'wpg-b_card_title');
  const { options: sto, ...subtitleClassNameProps } = useClassNameProps(subtitleClassName, 'wpg-b_card_subtitle');
  const { options: ro, ...ribbonClassNameProps } = useClassNameProps(ribbonClassName, 'wpg-b_card_ribbon');
  const { options: bo, ...buttonClassNameProps } = useClassNameProps(buttonClassName, 'wpg-b_card_button');
  const { options: cto, ...contentClassNameProps } = useClassNameProps(contentClassName, 'wpg-b_card_content');

  const isHeight = (classNameProps.h || classNameProps.height);
  const isMaxW = (classNameProps.maxW === '100%' || classNameProps.maxWidth === '100%');

  let image = null;
  if(imageRaw) image = JSON.parse(decodeURIComponent(imageRaw));
  if(imageObject) image = imageObject;

  let width = 992;
  let headingAs = 'h3';
  if(parentAttrs && parentAttrs.colsLength) {
    if(parentAttrs.colsLength === 1) width = 1144;
    if(parentAttrs.colsLength === 2) width = 992;
    if(parentAttrs.colsLength === 3) {
      width = 576;
    }
    if(parentAttrs.colsLength > 3) {
      headingAs = 'h4';
      width = 576;
    }
  }

  const cdnProps = {
    width: isMaxW ? undefined : width,
    aspect,
    quality: 75,
    //cropForce: true,
    ...co
  };


  const linkProps = {
    _hover: {
      color: 'inherit'
    }
  };

  return (
    <Box
      maxW={parentAttrs && parentAttrs.colsLength === 1 ? 992 : 576}
      ml="auto"
      mr="auto"
      position="relative"
      borderTopRadius="md"
      borderBottomRadius="md"
      mb={0}
      css={{
        'a': {
          '&:hover': {
            'img': {
              //transform: 'scale(1.04)'
            }
          }
        }
      }}
      {...classNameProps}
    >
      <ConditionalWrapper
        condition={(link && !buttonText)}
        wrapper={(children) => <LocaleLink to={link} {...linkProps}>{children}</LocaleLink>}
      >
        {image && (
          <ConditionalWrapper
            condition={(link && buttonText)}
            wrapper={(children) => <LocaleLink to={link} {...linkProps}>{children}</LocaleLink>}
          >
            <Box
              layerStyle="boxed"
              overflow="hidden"
              zIndex={1}
              position="relative"
              borderRadius="md"
              _after={{
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
                backgroundColor: (bgOverlayColor && overlayImage) ? bgOverlayColor : 'transparent'
              }}
              layerStyle="coverImage"
              css={{
                '> div':{
                  maxWidth: isMaxW ? '100%' : undefined
                },
                'img': {
                  'transition': 'all 0.2s ease-in-out',
                  width: '100%'
                }
              }}
              {...coverClassNameProps}
            >
              <CdnImage alt={image.alt} uri={image.url} {...cdnProps} />
            </Box>
          </ConditionalWrapper>
        )}

        {(!image && (showPlaceholderImage || overlayImage)) && (
          <AspectRatio ratio={aspect} bgColor="gray.200" borderRadius="md"/>
        )}

        {(title || subtitle) && (
          <Box
            display={overlayImage ? 'flex' : 'block'}
            zIndex={2}
            alignItems="center"
            justifyContent="center"
            textAlign={alignContent}
            layerStyle={overlayImage ? 'filled' : undefined}
            textShadow={overlayImage && image ? '0 1px 10px rgba(0, 0, 0, 0.5)' : 'none'}
            {...metaClassNameProps}
          >
            <chakra.div
              position="relative"
              py={{base: 3, lg: 4}}
              px={overlayImage ? { base: 3, lg: 4 } : noPad ? 0 : 1}
              w="100%"
            >
              {title && <Heading as={headingAs} color={overlayImage && image ? 'white' : 'text'} {...titleClassNameProps}>{title}</Heading>}
              {subtitle && <Text mb={0} color={overlayImage && image ? 'whiteAlpha.800' : 'text'} {...subtitleClassNameProps}>{subtitle}</Text>}
              {(content && !overlayImage) && <Text mt={1} as="span" display="block" noOfLines={3} color={overlayImage && image ? 'whiteAlpha.800' : 'text'} {...contentClassNameProps}>{content}</Text>}
              {buttonText && link && <Button mt={3} type="primary" size="sm" to={link} {...buttonClassNameProps}>{buttonText}</Button>}
            </chakra.div>
          </Box>
        )}

        {ribbonText && (
          <Box
            zIndex={3}
            display="inline-block"
            bg="primary.500"
            color="white"
            pr={2}
            pl={2}
            pt="2px"
            pb="2px"
            borderRadius="sm"
            boxShadow="md"
            fontWeight="semibold"
            fontSize="sm"
            position="absolute"
            top="10px"
            left="-2px"
            {...ribbonClassNameProps}
          >
            {ribbonText}
          </Box>
        )}
      </ConditionalWrapper>
    </Box>
  );
};

export default CardBlock;
