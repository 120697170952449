import React from 'react';
import { Block, WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { withResponsive } from '@rentivo/gatsby-core/src/context/ResponsiveContext';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Flex, Box, chakra } from '@chakra-ui/react';
import { useHeaderHeight } from '@rentivo/gatsby-core/src/hooks/useHeaderHeight';
import ReactPlayer from 'react-player';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';

const HeroVideoBlock = (props) => {
  const { attrs, innerBlocks, className, isMobileOnly, classNameType = 'wpg-b_hero-gallery' } = props;

  const headerHeight = useHeaderHeight(true);
  let {
    video,
    fullHeight = false,
    autoHeight = false,
    height = '550px',
    bgOverlay = true,
    bgOverlayColor = 'blackAlpha.500',
    backgroundClassName = ''
  } = attrs;


  const { options, ...classNameProps } = useClassNameProps(className, classNameType);
  const { options: backgroundOptions, ...backgroundClassNameProps } = useClassNameProps(backgroundClassName, 'wpg-b_hero-video-img');

  const cornerProp = { base: 'auto', lg: 0 };
  
  const childProps = {
    color: { base: 'text', lg: 'white' },
    textShadow: { base: 'none', lg: '0px 1px 7px rgba(0,0,0,0.5)' }
  };

  return (
    <Block
      attrs={attrs}
      position="relative"
      {...classNameProps}
    >
      <Flex
        display="flex"
        direction={{base: 'column', lg: 'column'}}
      >
        <Box
          position="relative"
          order={{base: 0, lg: 0}}
          w={{base: '100%', lg: '100%'}}
          h={{base: 0, lg: (fullHeight) ? `calc(100vh - ${headerHeight})` : autoHeight ? 0 : height}}
          paddingBottom={{base: '56.25%', lg: autoHeight ? '56.25%' : 0}}
          overflow="hidden"
          _after={{
            position: 'absolute',
            zIndex: 1,
            inset: '0px',
            content: '""',
            backgroundColor: (bgOverlay) ? bgOverlayColor : "transparent",
            opacity: 0.1,
            pointerEvents: 'none'
          }}
          
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        >
          <chakra.div
            position="absolute"
            minWidth="102%"
            minHeight="102%"
            paddingBottom="56.25%"
            zIndex={1}
            width="auto"
            height="auto"
            top="51%"
            left="51%"
            objectFit="cover"
            transform="translate(-51%, -51%)"
            bgColor="gray.100"
            css={{
              '.react-player': {
                'position': 'absolute',
                'inset': '0px',
                'zIndex': 200
              }
            }}
          >
            <Loader/>
            <ReactPlayer 
              url={video} 
              width='100%' 
              height='100%' 
              className='react-player'
              loop={true}
              playing={true}
              light={false}
              playsinline={true}
              volume={null}
              muted={true}
              controls={false}
              config={{
                file: {
                  attributes: {
                    muted: true,
                    controls: false,
                    autoPlay: true,
                    loop: true,
                    playsinline: true
                  }
                },
                vimeo: {
                  playerOptions: {
                    autoPause: false,
                    autoPlay: true,
                    background: true,
                    controls: false,
                    loop: true,
                    muted: true
                  }
                },
                youtube: {
                  playerVars: { showinfo: 1, playsinline: 1 },
                  embedOptions: { autoPlay: 1, controls: 0, loop: 1 }
                }
              }}
            />
          </chakra.div>
        </Box>
        {(innerBlocks && innerBlocks.length > 0) && (
          <Box
            pointerEvents="none"
            order={{base: 1, lg: 1}}
            w={{base: '100%', lg: '100%'}}
            position={{base: 'relative', lg: 'absolute'}}
            zIndex={250}
            top={cornerProp}
            bottom={cornerProp}
            left={cornerProp}
            right={cornerProp}
            display={{base: 'block', lg: 'flex'}}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              className="wpg-b_hero-gallery__content"
              {...childProps}
              py={{base: 6, lg: 0}}
              px={{base: 0, sm: 0, md: 0, lg: 0}}
            >
              {innerBlocks.map((block, i) => <WPGBlock key={i} block={block} childProps={childProps}/>)}
            </Box>
          </Box>
        )}
      </Flex>
    </Block>
  );
};

export default withResponsive(HeroVideoBlock);
