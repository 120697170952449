

import { dataField, nestedField } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/FeaturesFilter/constants';

export const query = ({filterOptions, value}) => {
  //const fieldName = 'listing.type';
  const fieldName = (filterOptions.field) ? filterOptions.field : dataField;
  const nestedFieldPath = (filterOptions.nestedField !== undefined) ? filterOptions.nestedField : nestedField;



  if(!value || value.length === 0) return null;
  value = (Array.isArray(value)) ? value : [value];
  const query = {"bool": {"must": []}};

  if(nestedFieldPath) {
    value.map((val) => {
      if(val.includes('|')) {
        const vals = val.split('|');
        const should = vals.map(v => {
          return {
            "term": {
              [fieldName]: v
            }
          }
        });
        query.bool.must.push({
          "nested": {
            "path": nestedFieldPath,
            "query": {
              "bool": {
                "should": should
              }
            }
          }
        });
      } else {
        query.bool.must.push({
          "nested": {
            "path": nestedFieldPath,
            "query": {
              "bool": {
                "must": [
                  {
                    "term": {
                      [fieldName]: val
                    }
                  }
                ]
              }
            }
          }
        });
      }
    });
  } else {

    value.map((val) => {
      if(val.includes('|')) {
        const vals = val.split('|');
        const should = vals.map(v => {
          return {
            "term": {
              [fieldName]: v
            }
          }
        });
        query.bool.must.push({
          "bool": {
            "should": should
          }
        });
      } else {
        query.bool.must.push({
          "term": {
            [fieldName]: val
          }
        });
      }
    });


  }

  return query;
};

export const defaultQuery = query;
