import React from 'react';
import { StyledFlagIcon } from '@rentivo/gatsby-core/src/components/ui/FlagIcon/styles';

const FlagIcon = ({code}) => {
  const splitCode = code.split('_');
  const lang = (splitCode.length > 1) ? splitCode[1] : splitCode[0];

  return (
    <StyledFlagIcon><img src={`/flags/${lang.toLowerCase()}.svg`} alt={code}/></StyledFlagIcon>
  );
};
/*
const FlagIconSvg = ({ code, size = 16 }) => {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(`@rentivo/gatsby-site/svgs/${code.toLowerCase()}.svg`);
        ImportedIconRef.current = namedImport;
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [code]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <StyledFlagIcon><ImportedIcon /></StyledFlagIcon>;
  }

  return <StyledFlagIcon/>;
};
*/

export default FlagIcon;
