import React from 'react';
import SearchBar from '@rentivo/gatsby-core/src/components/search/SearchBar';
import { useTheme } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Block } from '@rentivo/gatsby-core/src/components/wpg/Blocks';

const SearchBarBlock = ({className, childProps, attrs}) => {
  const { align = 'left', size = 'large', stacked } = attrs;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_search-bar');
  const { components: { Button: { sizes }}} = useTheme();

  return (
    <Block
      attrs={attrs}
      px="3px"
      mx="-3px"
      {...classNameProps}
      minH={size === 'large' ? sizes.lg.h : (size === 'medium') ? sizes.md.h : sizes.sm.h }
      //overflow="hidden"
    >
      <SearchBar size={size} stacked={(stacked)} align={align} />
    </Block>
  );
};

export default SearchBarBlock;
