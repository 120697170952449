import React from 'react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { UnorderedList, OrderedList, ListItem } from '@chakra-ui/react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';

const ListBlock = ({attrs, className, childProps}) => {
  const {
    ordered = false,
    values = '',
  } = attrs;

  const ListWrapper = (ordered) ? OrderedList : UnorderedList;

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_list');
  return (
    <ListWrapper
      mb={4}
      {...childProps}
      {...classNameProps}
    >
      <RenderHtml html={values}/>
    </ListWrapper>
  );
};

export default ListBlock;
