import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import GuestsFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/component';
import withFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/withFilter';
import { query } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/query';
import { query as petsQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/petsQuery';
import { FILTER_NUMBER_TYPE_COUNTER } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';

const enhance = compose(
  withFilter('guests', query),
  withFilter('adults', null),
  withFilter('children', null),
  withFilter('infants', null),
  withFilter('pets', petsQuery),
);

GuestsFilter.defaultProps = {
  type: FILTER_NUMBER_TYPE_COUNTER,
  className: 'filter',
  runQuery: true,
  setFilterFrom: 'search'
};

GuestsFilter.propTypes = {
  className: PropTypes.string,
  runQuery: PropTypes.bool,
  setFilterFrom: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ])
};


export default enhance(GuestsFilter);
