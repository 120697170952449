import React from 'react';
import Counter from '@rentivo/gatsby-core/src/components/ui/Counter/component';

Counter.defaultProps = {
  max:               100,
  min:               0,
  value:             0,
  appendString:      '',
  prependString:     '',
  onChange:          (value) => null,
  applyWaitInMs:     300,
  maxedOut:          false
};

export default Counter;
