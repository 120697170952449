import React, { useEffect, useRef } from 'react';
import LocationFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter';
import AvailabilityFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilterPopover';
import GuestsFilterPopover from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilterPopover';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/search/SearchBar/messages';
import { defaultButtonProps } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/props';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import { Box, Flex, chakra } from '@chakra-ui/react';
import SearchProvider from '@rentivo/gatsby-core/src/containers/SearchProvider';

const setFilterFrom = 'searchbar';

const wrapItemProps = (stacked, maxW = '200px', w = 'auto') => {
  let props = {
    maxW: { base: 'none', md: maxW },
    w: { base: `calc(${w === 'auto' ? '100%' : w} - 0.5rem)`, md: w }
  };

  if(stacked) {
    props = {
      maxW: 'none',
      w: `calc(${w === 'auto' ? '100%' : w} - 0.5rem)`
    };
  }

  return {
    ...props,
    m: '0.25rem',
    css: {
      '*': {
        width: '100%'
      }
    }
  }
};

const SearchBarComponent = ({className, clearFilters, searchbarSearch, size, stacked, handleRedirect, align, localeLinkTo, routePaths, location}) => {
  /* // TODO: Need to use history.push({ pathname: "/about" }); instead of window.pushState: https://github.com/ReactTraining/react-router/issues/6304
  const [searchLocaleLinkTo, setSearchLocaleLinkTo] = useState(`${routePaths.ROUTE_BASE_SEARCH}${window.location.search}`);
  useEffect(() => history.listen(({action, location}) => {
    console.log('location changed!', location);
    setSearchLocaleLinkTo(`${routePaths.ROUTE_BASE_SEARCH}${location.search}`)
  }),[]);
  */

  const ref = useRef(null);
  useEffect(() => {
    if(ref.current) {
      clearFilters(); // TODO: Maybe only run when URL params don't exist
    }
  }, []);

  const handleSearch = () => {
    searchbarSearch();
    handleRedirect();
  };

  const isFull = (align === 'full' || align === 'wide');

  return (
    <SearchProvider fetchOnLoad={false}>
      <chakra.div
        ml={-1}
        mr={-1}
      >
        <Flex
          ref={ref}
          align="center"
          justify="center"
          spacing={0}
          wrap={(isFull) ? { base: 'wrap', md: 'nowrap'} : 'wrap'}
          ml={(align === 'center' ? 'auto': (align === 'right') ? 'auto' : 0)}
          mr={(align === 'center' ? 'auto': (align === 'right') ? 0 : 'auto')}
          maxW={(stacked) ? '480px' : (size === 'large' || size === 'lg') ? '992px' : align === 'full' ? '100%' : '680px'}
          className={className}
          css={{
            '.chakra-button': {
              width: '100%!important'
            },
            '.chakra-button__icon': {
              width: 'auto!important'
            }
          }}
        >
          <Box {...wrapItemProps(stacked, isFull ? '100%' : '325px', isFull ? '100%' : 'auto')}>
            <LocationFilter scrollTo={true} size={size} title={null} runQuery={false} setFilterFrom={setFilterFrom}/>
          </Box>
          <Box {...wrapItemProps(stacked, '200px', '50%')}>
            <AvailabilityFilterPopover scrollTo={true} button={{...defaultButtonProps, size}} runQuery={false} setFilterFrom={setFilterFrom}/>
          </Box>
          <Box {...wrapItemProps(stacked, '200px', '50%')}>
            <GuestsFilterPopover scrollTo={true} button={{...defaultButtonProps, size}} runQuery={false} setFilterFrom={setFilterFrom}/>
          </Box>
          <Box {...wrapItemProps(stacked, '280px')}>
            <Button size={size} type="primary" onClick={handleSearch}><FormattedMessage {...messages.buttonLabel}/></Button>
          </Box>
        </Flex>
      </chakra.div>
    </SearchProvider>
  );
};

export default SearchBarComponent;
