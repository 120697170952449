import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MENU_MODE_HORIZONTAL,
  MENU_MODE_VERTICAL,
  MENU_THEME_DARK
} from '@rentivo/gatsby-core/src/components/navigation/Menu/constants';
import { getChildren, isMenuText } from '@rentivo/gatsby-core/src/components/navigation/Menu/utils';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
  Portal,
  useBreakpointValue,
  useStyleConfig
} from '@chakra-ui/react';
import { Button, ConditionalWrapper, LocaleLink } from '@rentivo/gatsby-core/index';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

export const Arrow = ({rotation = 0, ...rest}) => <FontAwesomeIcon icon={faAngleDown} {...rest} rotation={rotation}/>;

const SubMenu = ({item, isNestedSubMenu, buttonProps}) => {
  const { label, cssClasses, extraFields } = item;
  const { classnameprops = '' } = extraFields;

  const children = getChildren(item);
  const { item: menuItemProps } = useStyleConfig('Menu', {});
  const className = cssClasses.join(' ');
  const { options, ...classNameProps } = useClassNameProps(classnameprops);
  const nestedMenuPosition = useBreakpointValue({ base: 'bottom-start', lg: 'right-start' });

  return (
    <Menu
      autoSelect={false}
      placement={isNestedSubMenu ? nestedMenuPosition : 'bottom-start'}
      boxShadow="lg"
    >
      {({ isOpen }) => (
        <>
          {isNestedSubMenu ? (
            <MenuButton
              {...menuItemProps}
              isActive={isOpen}
              position="relative"
              maxW="224px"
              w="100%"
              display="block"
              textAlign="left"
              _hover={menuItemProps._focus}
              {...classNameProps}
            >
              {label}
              <Arrow rotation={270} style={{
                position: 'absolute',
                right: '0.5rem',
                top: '50%',
                marginTop: '-7.5px'
              }}/>
            </MenuButton>
          ) : (
            <MenuButton
              as={Button}
              pr={{base: '1.75rem', lg: '0.5rem'}}
              {...buttonProps}
              isActive={isOpen}
              rightIcon={isOpen ? <Arrow rotation={180}/> : <Arrow/>} {...classNameProps}
            >
              {label}
            </MenuButton>
          )}
          <MenuList mt={isNestedSubMenu ? '-8px' : '-4px'} ml={isNestedSubMenu ? '-10px' : 0}>
            {children.map((subItem, i) => <MenuItem item={subItem} isSubMenuItem={true} key={subItem.id + i}/>)}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

const MenuItem = (props) => {
  const { mode, theme, item, isSubMenuItem = false } = props;
  const { label, url, cssClasses, extraFields } = item;
  const { classnameprops = '' } = extraFields;
  const children = getChildren(item);
  const className = cssClasses.join(' ');

  const { options, ...classNameProps } = useClassNameProps(classnameprops);

  if(isSubMenuItem) {
    if(children) return <SubMenu isNestedSubMenu={true} {...props}/>;
    return (
      <ConditionalWrapper condition={url} wrapper={c => <LocaleLink to={url}>{c}</LocaleLink>}>
        <ChakraMenuItem isFocusable={false} {...classNameProps}>{label}</ChakraMenuItem>
      </ConditionalWrapper>
    )
  }

  const buttonProps = {
    //borderRadius: (mode === MENU_MODE_HORIZONTAL) ? 'md' : 0,
    justifyContent: (mode === MENU_MODE_HORIZONTAL) ? 'center' : 'flex-start',
    textAlign: (mode === MENU_MODE_HORIZONTAL) ? 'center' : 'left',
    width: (mode === MENU_MODE_HORIZONTAL) ? 'auto' : '100%',
    variant: theme === MENU_THEME_DARK ? 'menuDark' : 'menuLight',
    className
  };

  if(mode === MENU_MODE_VERTICAL) {
    buttonProps.pl = 4;
  }

  if(children) return <SubMenu {...props} buttonProps={buttonProps}/>;

  if(!isMenuText(item) && url) buttonProps.to = url;

  return (
    <Button {...buttonProps} {...classNameProps}>{label}</Button>
  );
};

const MenuComponent = ({menuItems, theme, mode, spacing = 1}) => {

  //console.log({menuItems});

  if(!menuItems || menuItems.length === 0) return null;
  return (
    <Stack spacing={mode === MENU_MODE_HORIZONTAL ? spacing : 0} direction={mode === MENU_MODE_HORIZONTAL ? 'row' : 'column'}>
      {menuItems.map((item, i) => <MenuItem theme={theme} mode={mode} item={item} key={item.label + item.url + i}/>)}
    </Stack>
  );
};

export default MenuComponent;
