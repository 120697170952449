import { useStyleConfig } from '@chakra-ui/react';
import { removePx } from '@rentivo/gatsby-core/src/utils/strings';

export const useHeaderHeight = (addPixels = false) => {
  const { header: { h, height } } = useStyleConfig('HeaderNavbar', {});
  const theHeight = h ? removePx(h) : height ? removePx(height) : 60;
  return (addPixels) ? `${theHeight}px` : theHeight;
};

export const useUpdateBarHeight = (addPixels = false) => {
  const { header: { h, height } } = useStyleConfig('HeaderNavbar', {});
  const theHeight = h ? removePx(h) : height ? removePx(height) : 60;
  return (addPixels) ? `${theHeight}px` : theHeight;
};

export const useBodyHeight = () => {
  const height = useHeaderHeight(true);
  return `calc(100vh - ${height})`;
};
