import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@chakra-ui/react';
import React from 'react';

const AwesomeIcon = ({icon, fontSize, ...rest}) =>
  <Icon
    {...rest}
    style={{fontSize: (fontSize) ? fontSize : undefined}}
    as={(props) => <FontAwesomeIcon icon={icon} {...props} style={{height: '1em'}}/>}/>;

export default AwesomeIcon;
