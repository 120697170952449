import React from 'react';
import SelectCounter from '@rentivo/gatsby-core/src/components/ui/SelectCounter/component';

SelectCounter.defaultProps = {
  max:               100,
  min:               0,
  count:             0,
  appendString:      '',
  prependString:     '',
  onChange:          () => null,
  applyWaitInMs:     300,
  maxedOut:          false,
  placeholder:       'Please select'
};

export default SelectCounter;
