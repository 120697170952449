import React from 'react';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import { Block } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { ConditionalWrapper } from '@rentivo/gatsby-core/index';
import { chakra } from '@chakra-ui/react';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const ImageBlock = ({innerHTML, attrs, className, childProps}) => {

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_image');
  const { align } = attrs;
  // TODO: Need to fix links around aspsect... always seems to mess up the aspect ratio...

  return (
    <ConditionalWrapper
      condition={align === 'full' || align === 'wide'}
      wrapper={c => <Block attrs={attrs} pl={0} pr={0} {...childProps} {...classNameProps}>{c}</Block>}
      defaultWrapper={c => <chakra.span {...stripNonStyles(childProps)} {...classNameProps}>{c}</chakra.span>}
    >
      <RenderHtml html={innerHTML} options={options}/>
    </ConditionalWrapper>
  );
};

export default ImageBlock;
