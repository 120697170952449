import { defaultTagValue as defaultPriceTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilter/tag';
import { defaultTagValue as defaultAvailabilityTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilter/tag';
import { defaultTagValue as defaultAvailabilityInTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityInFilter/tag';
import { defaultTagValue as defaultLosTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LosFilter/tag';
import { defaultTagValue as defaultLocationTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/tag';
import { defaultTagValue as defaultFeaturesTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/FeaturesFilter/tag';
import { defaultTagValue as defaultTagsFlagsTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/TagsFlagsFilter/tag';
import { defaultTagValue as defaultDiscountsTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/DiscountsFilter/tag';
import { defaultTagValue as defaultSortResultsTagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/tag';

export default {
  'price': defaultPriceTagValue,
  'availability': defaultAvailabilityTagValue,
  'availabilityIn': defaultAvailabilityInTagValue,
  'los': defaultLosTagValue,
  'location': defaultLocationTagValue,
  'type': defaultFeaturesTagValue,
  'suitability': defaultFeaturesTagValue,
  'tagsFlags': defaultTagsFlagsTagValue,
  'discounts': defaultDiscountsTagValue,
  'features': defaultFeaturesTagValue,
  'specialFeatures': defaultFeaturesTagValue,
  'familyFriendly': defaultFeaturesTagValue,
  'kitchenAppliances': defaultFeaturesTagValue,
  'accessibilityFeatures': defaultFeaturesTagValue,
  'locationFeatures': defaultFeaturesTagValue,
  'relax': defaultFeaturesTagValue,
  'safety': defaultFeaturesTagValue,
  'sort': defaultSortResultsTagValue
}
