import { selectSearchFilterByKey } from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';
import { addCurrentYearToDate } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityInFilter/utils';

export const query = ({filterOptions, value, allFilters}) => {
  if(!value) return null;

  const availabilityFilter = selectSearchFilterByKey(allFilters, 'availability');

  if(!availabilityFilter && value.length >= 2) {

    const startDate = addCurrentYearToDate(value[0], value[1], 'startDate');
    const endDate = addCurrentYearToDate(value[0], value[1], 'endDate');

    return {
      "script": {
        "script": {
          "id": "lycan_availability_filter_advanced",
          "params": {
            "arrivalDate": startDate,
            "departureDate": endDate,
            "minimumNights": (value[3] && typeof value[3] === 'number') ? value[3] : 7
          }
        }
      }
    }
  } else {
    return null;
  }

};

export const defaultQuery = query;
