export const selectFooterBlocksFromPageContext = ({ reusableBlocks }) => {
  const [footerBlock] = reusableBlocks.filter(rb => rb.blockOptions.zone === 'footer');
  return (footerBlock) ? JSON.parse(footerBlock.blocksJSON) : [];
};

export const selectUpdateBarBlocksFromPageContext = ({ reusableBlocks }) => {
  const [updateBarBlock] = reusableBlocks.filter(rb => rb.blockOptions.zone === 'updateBar');
  return (updateBarBlock) ? JSON.parse(updateBarBlock.blocksJSON) : [];
};

export const selectTopBarBlocksFromPageContext = ({ reusableBlocks }) => {
  const [topBarBlock] = reusableBlocks.filter(rb => rb.blockOptions.zone === 'topBar');
  return (topBarBlock) ? JSON.parse(topBarBlock.blocksJSON) : [];
};

export const selectFooterBlocksFromQuery = ({ reusableBlocks }) => {
  const [footerBlock] = reusableBlocks.nodes.filter(rb => rb.blockOptions.zone === 'footer');
  return (footerBlock) ? JSON.parse(footerBlock.blocksJSON) : [];
};

export const selectUpdateBarBlocksFromQuery = ({ reusableBlocks }) => {
  const [footerBlock] = reusableBlocks.nodes.filter(rb => rb.blockOptions.zone === 'updateBar');
  return (footerBlock) ? JSON.parse(footerBlock.blocksJSON) : [];
};

export const selectBlocksFromPageQuery = ({ wpPage }) => {
  return (wpPage.blocksJSON) ? JSON.parse(wpPage.blocksJSON) : [];
};

export const selectBlocksFromHubQuery = ({ wpHub }) => {
  return (wpHub.blocksJSON) ? JSON.parse(wpHub.blocksJSON) : [];
};

export const selectBlocksFromPostQuery = ({ wpPost }) => {
  return (wpPost.blocksJSON) ? JSON.parse(wpPost.blocksJSON) : [];
};
