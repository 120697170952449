import React from 'react';
import { Select } from '@chakra-ui/react';

// TODO: Untested comp...

const SelectCounterComponent = ({max, min, value, appendString, prependString, onChange, placeholder}) => {

  const handleChange = (value) => {
    onChange(value);
  };

  const children = [];

  for (let i = min; i < max + 1; i++) {
    //const prepend = (i === 0) ? '' : prependString;
    //const append = (i === 0) ? '' : appendString;
    children.push(<option key={i} value={i}>{`${prependString}${i}${appendString}`}</option>);
  }

  return (
    <Select style={{minWidth: 75}} placeholder={placeholder} value={value} onChange={handleChange}>
      {children}
    </Select>
  );
};

export default SelectCounterComponent;
