import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import GuestsPicker from '@rentivo/gatsby-core/src/components/ui/GuestsPicker';
import NumberFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/NumberFilter';
import { selectGuestsFromFilters } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/selectors';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/messages';
import { StyledGuestsFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/styles';
import { debounce } from 'lodash';
const debounceWait = 250;

const GuestsFilterComponent = ({className, runQuery, setFilterFrom, title, type, ...rest}) => {
  const { filters } = rest || {};
  const { guests } = filters || {};
  const { filterOptions, setFilter } = guests || {};
  const { guestsPicker, min, max, queryFormat } = filterOptions || {};

  const {
    adults: { filterOptions: adultsOptions, setFilter: setAdultsFilter },
    children: { filterOptions: childrenOptions, setFilter: setChildrenFilter },
    infants: { filterOptions: infantsOptions, setFilter: setInfantsFilter },
    pets: { filterOptions: petsOptions, setFilter: setPetsFilter }
  } = filters || {};

  const { adults, children, infants, pets } = selectGuestsFromFilters(filters);

  const onChange = ({guests, adults, children, infants, pets}) => {
    setAdultsFilter(adults, runQuery, setFilterFrom);
    setChildrenFilter(children, runQuery, setFilterFrom);
    setInfantsFilter(infants, runQuery, setFilterFrom);
    setPetsFilter(pets, runQuery, setFilterFrom);
    setFilter(guests, runQuery, setFilterFrom);
  };

  const debouncedOnChange = useRef(debounce(data => onChange(data), debounceWait)).current;

  return (
    <StyledGuestsFilter className={classNames(className)}>
      {guestsPicker ? (
        <GuestsPicker
          options={{
            type,
            min,
            max,
            appendString: queryFormat === 'gte' ? '+' : '',
            adults: adultsOptions,
            children: childrenOptions,
            infants: infantsOptions,
            pets: petsOptions
          }}
          adults={adults}
          children={children}
          infants={infants}
          pets={pets}
          onChange={debouncedOnChange}
        />
      ) : (
        <>
          {title !== undefined ? title : <label><FormattedMessage {...messages.title}/></label>}
          <NumberFilter {...rest}/>
        </>
      )}
    </StyledGuestsFilter>
  );

};

export default GuestsFilterComponent;
