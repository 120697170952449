import { defaultQuery as defaultBedroomsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/BedroomsFilter/query';
import { defaultQuery as defaultGuestsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/query';
import { defaultQuery as defaultPetsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/GuestsFilter/petsQuery';
import { defaultQuery as defaultPriceFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilter/query';
import { defaultQuery as defaultAvailabilityFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilter/query';
import { defaultQuery as defaultAvailabilityInFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityInFilter/query';
import { defaultQuery as defaultLosFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LosFilter/query';
import { defaultQuery as defaultLocationFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/query';
import { defaultQuery as defaultBathroomsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/BathroomsFilter/query';
import { defaultQuery as defaultBedsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/BedsFilter/query';
import { defaultQuery as defaultFeaturesFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/FeaturesFilter/query';
import { defaultQuery as defaultTagsFlagsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/TagsFlagsFilter/query';
import { defaultQuery as defaultDiscountsFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/DiscountsFilter/query';
import { defaultQuery as defaultKeywordFilterQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/KeywordFilter/query';
import { defaultQuery as defaultSortResultsQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/query';
import { defaultQuery as defaultPaginateResultsQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/PaginateResults/query';

export default {
  'bedrooms': defaultBedroomsFilterQuery,
  'beds': defaultBedsFilterQuery,
  'bathrooms': defaultBathroomsFilterQuery,
  'guests': defaultGuestsFilterQuery,
  'pets': defaultPetsFilterQuery,
  'price': defaultPriceFilterQuery,
  'availability': defaultAvailabilityFilterQuery,
  'availabilityIn': defaultAvailabilityInFilterQuery,
  'los': defaultLosFilterQuery,
  'location': defaultLocationFilterQuery,
  'type': defaultFeaturesFilterQuery,
  'suitability': defaultFeaturesFilterQuery,
  'features': defaultFeaturesFilterQuery,
  'locationFeatures': defaultFeaturesFilterQuery,
  'relax': defaultFeaturesFilterQuery,
  'safety': defaultFeaturesFilterQuery,
  'tagsFlags': defaultTagsFlagsFilterQuery,
  'discounts': defaultDiscountsFilterQuery,
  'keyword': defaultKeywordFilterQuery,
  'sort': defaultSortResultsQuery,
  'page': defaultPaginateResultsQuery
}
