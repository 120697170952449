import { createSelector } from 'reselect';
import { initialState } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/reducer';
import { exchangeCurrencyAmount } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/utils';
import { selectDefaultCurrencyConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const selectCurrency = state => state.currency ? state.currency : initialState;
export const makeSelectCurrency = () => createSelector(selectCurrency, state => state);

export const selectCurrentCurrency = state => selectCurrency(state).currentCurrency;
export const makeSelectCurrentCurrency = () => createSelector(selectCurrentCurrency, state => state);

export const selectCurrentExchangeMap = state => selectCurrency(state).currencyExchangeMap;
export const makeSelectCurrencyExchangeMap = () => createSelector(selectCurrentExchangeMap, state => state);

export const makeSelectGetExchangedCurrency = createSelector(
  selectCurrentExchangeMap,
  selectDefaultCurrencyConfig,
  selectCurrentCurrency,
  (currencyExchangeMap, defaultCurrency, targetCurrency) => {
    return (value, decimalPlace = 2, sourceCurrency = null, targetC = targetCurrency) => {
      sourceCurrency = (sourceCurrency) ? sourceCurrency : defaultCurrency;
      return exchangeCurrencyAmount(currencyExchangeMap, sourceCurrency, targetC, value, decimalPlace);
    }
  }
);
