import React from 'react';
import { Block, WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

const InnerRowBlockBlock = ({attrs, innerBlocks, className, childProps}) => {
  const {
    align = ''
  } = attrs;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_inner-row-block');

  childProps = { ...childProps, innerRowAlign: align };

  return (
    <Block
      attrs={attrs}
      {...classNameProps}
      pl={4}
      pr={4}
    >
      {innerBlocks.map((block, i) => <WPGBlock key={i} block={block} childProps={childProps}/>)}
    </Block>
  );
};

export default InnerRowBlockBlock;
