import React from 'react';
import GooglePlaces from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/GooglePlaces';
import { LOCATION_FILTER_TYPE_COLLECTION_CASCADE } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/constants';
import Collections from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/ElasticCollections';

const LocationFilterComponent = (props) => {
  return (props.filterOptions && props.filterOptions.type === LOCATION_FILTER_TYPE_COLLECTION_CASCADE) ? <Collections {...props}/> : <GooglePlaces {...props}/>;
};

export default LocationFilterComponent;
