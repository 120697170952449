import React from 'react';
import { StyledLoaderBg, StyledLoaderSpinner } from '@rentivo/gatsby-core/src/components/generic/Loader/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';

const LoaderComponent = ({spinner, bg, styleProps = {}}) => {
  return (spinner && !bg) ? <StyledLoaderSpinner className="loader" {...styleProps}><FontAwesomeIcon icon={faSpinnerThird} spin/></StyledLoaderSpinner> :
    <StyledLoaderBg className="loader" {...styleProps}/>;
};

export default LoaderComponent;
