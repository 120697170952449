import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters.AvailabilityInFilter';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Please select your dates'
  },
  clearDates: {
    id: `${scope}.clearDates`,
    defaultMessage: 'Clear dates'
  },
  AVAILABILITY_IN_CHRISTMAS: {
    id: `${scope}.AVAILABILITY_IN_CHRISTMAS`,
    defaultMessage: 'Christmas'
  },
  AVAILABILITY_IN_SUMMER: {
    id: `${scope}.AVAILABILITY_IN_SUMMER`,
    defaultMessage: 'Summer'
  },
  AVAILABILITY_IN_NEW_YEAR: {
    id: `${scope}.AVAILABILITY_IN_NEW_YEAR`,
    defaultMessage: 'New Year'
  },
  AVAILABILITY_IN_CHINESE_NEW_YEAR: {
    id: `${scope}.AVAILABILITY_IN_CHINESE_NEW_YEAR`,
    defaultMessage: 'Chinese New Year'
  },
  AVAILABILITY_IN_EASTER: {
    id: `${scope}.AVAILABILITY_IN_EASTER`,
    defaultMessage: 'Easter'
  },
  AVAILABILITY_IN_SONGKRAN: {
    id: `${scope}.AVAILABILITY_IN_SONGKRAN`,
    defaultMessage: 'Songkran'
  }
});
