import { defineMessages } from 'react-intl';

export const scope = 'containers.SearchProvider.filters.GuestsFilterPopover';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Guests'
  },
  label: {
    id: `${scope}.label`,
    defaultMessage: 'Guests'
  },
  setLabel: {
    id: `${scope}.set.label`,
    defaultMessage: '{value, plural, =0 {Guests} one {1 guest} other {{value} guests}}'
  },
});
