import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectCdnConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  getImageKitTransformationSrcSet,
  getTransformationPath
} from '@rentivo/gatsby-core/src/components/ui/CdnImage/utils';

export const selectCdnImageUrl = (cdnConfig, props) => {
  const provider = get(cdnConfig, 'provider', null);
  const urlEndpoint = get(cdnConfig, 'options.urlEndpoint', null);
  if(!provider && !urlEndpoint) {
    console.log('Please provide a CDN provider & endPoint in your site-config');
    return null;
  }
  return getTransformationPath({...props, provider, urlEndpoint});
};

export const selectCdnImageSrcSet = (cdnConfig, props, imageUrl) => {
  const provider = get(cdnConfig, 'provider', null);
  const urlEndpoint = get(cdnConfig, 'options.urlEndpoint', null);
  if(!provider && !urlEndpoint) {
    console.log('Please provide a CDN provider & endPoint in your site-config');
    return null;
  }
  return getImageKitTransformationSrcSet({...props, provider, urlEndpoint}, imageUrl);
};

export const makeSelectCdnImageUrl = (state, props) => createSelector(
  selectCdnConfig,
  (cdnConfig) => {
    const { useProgressiveImage, useGatsbyImage, width, height } = props;
    let { aspect } = props;
    const imageUrl = selectCdnImageUrl(cdnConfig, props);

    if(width && width !== 'auto' && height && aspect !== false) {
      aspect = `${width}/${height}`;
    }

    let aspectDecimal = undefined;
    if(aspect) {
      const aspectParts = aspect.split('/');
      if(aspectParts.length === 2) {
        aspectDecimal = parseInt(aspectParts[0]) / parseInt(aspectParts[1]);
      }
    }

    return {
      aspect,
      imageUrl,
      imageSrcSet: (useProgressiveImage || useGatsbyImage) ? selectCdnImageSrcSet(cdnConfig, props, imageUrl) : null,
      aspectDecimal
    };
  }
);

export const makeSelectGetCdnImageUrl = () => {
  return createSelector(selectCdnConfig, state => {
    const provider = get(state, 'provider', null);
    const urlEndpoint = get(state, 'options.urlEndpoint', null);
    if(!provider && !urlEndpoint) {
      return () => console.log('Please provide a CDN provider & endPoint in your site-config');
    }
    return (uri, cdnProps) => {
      const transformationProps = {
        provider,
        urlEndpoint,
        uri,
        ...cdnProps
      };
      return getTransformationPath(transformationProps);
    };
  });
};
