import React from 'react';
import NumberFilter from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/NumberFilter/component';
import Counter from '@rentivo/gatsby-core/src/components/ui/Counter';
import { FILTER_NUMBER_TYPE_COUNTER } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';

NumberFilter.defaultProps = {
  max:               24,
  min:               0,
  value:             0,
  onChange:          () => null,
  disabled:          false,
  component:         Counter
};

export default NumberFilter;
