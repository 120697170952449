import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import SearchBar from '@rentivo/gatsby-core/src/components/search/SearchBar/component';
import { selectRoutePathsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { selectDefaultLang, selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { window } from 'browser-monads';
import { generateLocaleLink } from '@rentivo/gatsby-core/src/components/navigation/LocaleLink/utils';
import { navigate } from 'gatsby';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';
import {
  clearFilters,
  fetchDefaultData,
  searchbarSearch
} from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';

const mapStateToProps = (state, ownProps) => createSelector(
  selectRoutePathsConfig,
  selectLang,
  selectDefaultLang,
  (state, ownProps) => get(ownProps, 'location.search', ''),
  (routePaths, locale, defaultLang, locationSearch) => {
    //console.log({locationSearch});
    return {
      routePaths,
      handleRedirect: () => {
        const toLocale = generateLocaleLink(locale, defaultLang, `${routePaths.ROUTE_BASE_SEARCH}${window.location.search}`);
        //console.log(toLocale);
        navigate(toLocale);
      },
      //localeLinkTo: `${routePaths.ROUTE_BASE_SEARCH}${location}`
    };
  }
);

const mapDispatchToProps = dispatch => ({
  clearFilters: () => dispatch(clearFilters()),
  searchbarSearch: () => dispatch(searchbarSearch())
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

SearchBar.defaultProps = {
  size: 'large',
  stacked: false,
  align: 'center'
};

export default enhance(SearchBar);
