import { useMemo } from 'react';
import { stringContains, uriDecode } from '@rentivo/gatsby-core/src/utils/strings';
import classnames from 'classnames';
import { sanitizeObjKey, sanitizeProp, sanitizeValue } from '@rentivo/gatsby-core/src/components/wpg/utils';

const pseudoStrings = [
  'option-',
  '_after-',
  '_before-',
  '_hover-',
  '_focus-',
  '_active-'
];

export const useClassNameProps = (className, ...extraClassNames) => {
  return useMemo(() => {
    let props = {};
    let normalClassNames = '';
    let options = {};
    if(className) {
      const classNames = className.split(' ');
      normalClassNames = (classNames.length) ? classNames.filter(c => !stringContains(c , ':')).join(' ') : '';
      const propClassNames = (classNames.length) ? classNames.filter(c => stringContains(c , ':')) : [];

      if(propClassNames.length) {
        let i = 0, len = propClassNames.length;
        while (i < len) {
          const prop = sanitizeProp(propClassNames[i]);
          if(prop) {

            const matchesPseudoString = pseudoStrings.filter(ps => prop.key.includes(ps))[0];

            if(matchesPseudoString) {
              const objKey = sanitizeObjKey(matchesPseudoString.replace('-', ''));
              const propKey = prop.key.replace(matchesPseudoString, '');

              props[objKey] = {
                ...props[objKey],
                [propKey]: sanitizeValue(propKey, prop.value)
              };
            } else {
              props[prop.key] = sanitizeValue(prop.key, prop.value); // Add to props
            }
          }
          i++;
        }
      }
    }

    return {
      className: classnames(normalClassNames, extraClassNames),
      options,
      ...props
    };
  }, [className, extraClassNames]);
};
