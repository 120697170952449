import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearItem,
  invalidateData,
  receiveData,
  requestData,
  resetData
} from '@rentivo/gatsby-core/src/containers/ESProvider/actions';
import { useIsEqualRef } from '@rentivo/gatsby-core/src/hooks/helpers';
import { collectionExtractor, documentExtractor } from '@rentivo/gatsby-core/src/hooks/useElasticSearch/utils';
import { makeSelectDataItem, makeSelectESConfig } from '@rentivo/gatsby-core/src/hooks/useElasticSearch/selectors';
import isEqual from 'lodash/isEqual';

const matchAll = {
  "match_all": { }
};

const defaultWpConfig = {
  "from": 0,
  "size": 36,
  "query": matchAll,
};

const defaultConfigMap = {
  properties: {
    "from" : 0,
    "size" : 12,
    "query" : matchAll,
    "_source": {
      "exclude": ["pricing.los"]
    }
  },
  collections: defaultWpConfig,
  hubs: defaultWpConfig,
  experiences: defaultWpConfig,
  posts: defaultWpConfig
};

const useLoadingValue = ({key, defaultData = undefined, extractor }) => {
  const dispatch = useDispatch();
  const [cachedData, setCachedData] = useState(undefined);
  const { data, loading, error, didInvalidate } = useSelector(makeSelectDataItem(key));
  const reset = () => dispatch(resetData(key, defaultData));
  const invalidate = (error) => dispatch(invalidateData(key, error));
  const request = () => dispatch(requestData(key));
  const receive = (data) => dispatch(receiveData(key, extractor(data)));
  const clear = () => dispatch(clearItem(key));

  useEffect(() => {
    if(!isEqual(cachedData, data) && (data && data.length || (!cachedData || !cachedData.length))) {
      setCachedData(data);
    }
  }, [data, setCachedData]);

  return {
    error,
    didInvalidate,
    loading,
    data: cachedData,
    reset,
    invalidate,
    request,
    receive,
    clear
  };
};

export const useElasticSearch = ({key, query = {}, defaultData = null, extractor = collectionExtractor, index = 'properties', runQuery = true}) => {
  const { 
    siteName, 
    searchEndpoint, 
    collectionsEndpoint,
    hubsEndpoint,
    postsEndpoint, 
    experiencesEndpoint, 
    elasticSearch: { 
      credentials 
    } 
  } = useSelector(makeSelectESConfig);

  const endpointMap = {
    properties: searchEndpoint,
    collections: collectionsEndpoint,
    hubs: hubsEndpoint,
    posts: postsEndpoint,
    experiences: experiencesEndpoint
  };
  const endpoint = endpointMap[index];

  if(!endpoint || !defaultConfigMap[index]) return null;

  const queryToUse = { ...defaultConfigMap[index], ...query };
  const queryString = JSON.stringify(queryToUse);

  const { error, didInvalidate, loading, data, reset, invalidate, request, receive, clear } = useLoadingValue({key, extractor, defaultData});

  const ref = useIsEqualRef(key, reset);
  const prevQueryString = useRef(``);

  useEffect(() => {
    if (!ref.current) {
      clear();
      return null;
    }

    if(!isEqual(prevQueryString.current, queryString) && runQuery) {

      prevQueryString.current = queryString;
      request();
      axios({
        method: 'post',
        baseURL: endpoint,
        data: queryString,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-ndjson',
          'X-App': siteName,
          'Authorization': 'Basic ' + btoa(credentials),
          //'Bypass-Tunnel-Reminder': 'Yes'
        }
      }).then(receive).catch(invalidate);
    }
  }, [queryString, runQuery, endpoint, siteName, credentials, index]);
  
  return {
    data,
    error,
    loading,
    didInvalidate
  };

};

export const useElasticSearchCollection = ({key, query = {}, defaultData = undefined}) => {
  const { data, error, loading, didInvalidate } = useElasticSearch({key, query, defaultData, extractor: collectionExtractor});
  return { data, error, loading, didInvalidate };
};

export const useElasticSearchDocument = ({key, query = {}, defaultData = undefined}) => {
  const { data, error, loading, didInvalidate } = useElasticSearch({key, query: { ...query, "size": 1 }, defaultData, extractor: documentExtractor});
  return { data, error, loading, didInvalidate };
};








