import React from 'react';
import { FormattedMessage } from 'react-intl';
import FlagIcon from '@rentivo/gatsby-core/src/components/ui/FlagIcon';
import { Arrow } from '@rentivo/gatsby-core/src/components/navigation/Menu/component';
import { MENU_THEME_DARK, MENU_THEME_LIGHT } from '@rentivo/gatsby-core/src/components/navigation/Menu/constants';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal
} from '@chakra-ui/react';
import { Button } from '@rentivo/gatsby-core/index';
import { shortSize } from '@rentivo/gatsby-core/src/utils/transformers';

const LocaleToggleComponent = ({onToggle, values, value, applyLocaleIcons, messages, type, size, theme = MENU_THEME_LIGHT}) => {
  if(!value) return null;

  const buttonProps = {
    variant: theme === MENU_THEME_DARK ? 'menuDark' : 'menuLight'
  };

  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} {...buttonProps} size={shortSize(size)} rightIcon={isOpen ? <Arrow rotation={180}/> : <Arrow/>}>
            {applyLocaleIcons && <span style={{marginRight: 5}}><FlagIcon style={{marginRight: 5}} code={value} /></span>}
            {messages[value] ? <FormattedMessage {...messages[value]}/> : value.toUpperCase()}
          </MenuButton>
          <Portal>
            <MenuList mt="-4px" ml={0}>
              {values.map(v => (
                <MenuItem key={v} value={v} onClick={() => onToggle(v)}>
                  {applyLocaleIcons && <span style={{marginRight: 5}}><FlagIcon code={v} /></span>}
                  {messages[v] ? <FormattedMessage {...messages[v]}/> : v.toUpperCase()}
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};

export default LocaleToggleComponent;

/*
            style={{
              position: 'absolute',
              zIndex: 1000,
              top: '0px',
              left: '0px',
              right: '0px'
            }}*/
