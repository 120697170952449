import React from 'react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Box } from '@chakra-ui/react';
import { WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const BoxBlock = ({className, innerBlocks, childProps}) => {
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_box');
  return (
    <Box {...stripNonStyles(childProps)} {...classNameProps}>
      {innerBlocks.map((block, i) => <WPGBlock key={i} block={block} childProps={childProps}/>)}
    </Box>
  );
};

export default BoxBlock;
