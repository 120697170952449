import React, { useMemo } from 'react';
import { Block, WPGBlock } from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { isEven } from '@rentivo/gatsby-core/src/utils/numbers';

const Col = ({col, ci, colsLength, hasEvenCols, childProps, breakpoint}) => {
  const { attributes: attrs } = col;
  const { options, ...colClassNameProps } = useClassNameProps(attrs.className, `wpg-b_column ${ci+1}-column`);
  return (
    <GridItem
      colSpan={breakpoint === 'none' ? 1 : breakpoint ? {base: colsLength, [breakpoint]: 1} : {base: colsLength, md: hasEvenCols ? 2 : 1, lg: 1}}
      {...colClassNameProps}
    >
      <Box
        mb={(ci + 1) === colsLength ? 0 : {base: 6, lg: 0}}
        pr={breakpoint === 'none' ? '0.75rem' : breakpoint ? { base: 0, [breakpoint]: '0.75rem'} : {base: 0, md: '0.75rem'}}
        pl={breakpoint === 'none' ? '0.75rem' : breakpoint ? { base: 0, [breakpoint]: '0.75rem'} : {base: 0, md: '0.75rem'}}
      >
        {col.innerBlocks.map((block, bi) => <WPGBlock key={bi} block={block} childProps={childProps} parentAttrs={{colsLength}}/>)}
      </Box>
    </GridItem>
  )
};

const ColumnsBlock = (props) => {
  let {
    attrs,
    innerBlocks,
    className,
    childProps
  } = props;

  const insideColumns = (childProps.insideColumns);

  if(!insideColumns) {
    childProps = { ...childProps, insideColumns: true };
  }

  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_columns-wrap');
  const { templateColumns: classNamePropTemplateColumns } = classNameProps;
  const { breakpoint } = options;

  if (!Array.isArray(innerBlocks)) {
    console.warn('Columns should have innerBlocks');
    return null;
  }

  const colsLength = innerBlocks.length;
  const hasEvenCols = isEven(colsLength);

  const columns = useMemo(() => innerBlocks.map((col, ci) =>
    <Col key={ci} ci={ci} col={col} breakpoint={breakpoint} colsLength={colsLength} hasEvenCols={hasEvenCols} childProps={childProps}/>), [innerBlocks, colsLength, breakpoint]);

  const templateColumns = useMemo(() => {
    const customW = innerBlocks.filter((col) => col.attributes.width !== undefined).map((col) => col.attributes.width);
    const newCustomW = [...customW];
    if(customW.length < colsLength) {
      // Add up current %s..
      const widthLeft = 100 - customW.slice().reduce((acc, item) => {
        return acc + parseFloat(item);
      }, 0);
      const colsLeft = colsLength - customW.length;
      const averageSizeForRemaining = (widthLeft / colsLeft).toFixed(2);
      for (let i = 0; i < colsLeft; i++) {
        newCustomW.push(averageSizeForRemaining);
      }
    }

    const finalCustomW = newCustomW.map(cw => `${parseFloat(cw)}%`);

    return (finalCustomW.length) ? finalCustomW.join(' ') : `repeat(${innerBlocks.length}, 1fr)`;
  }, [innerBlocks, colsLength]);

  // TODO: Columns inside colums block, no padding on Second <Block>

  const extraProps = {};
  if(insideColumns) {
    extraProps.px = 0;
  }

  return (
    <Block attrs={{align: attrs.align}} mx="auto" px={0}>
      <Block
        attrs={attrs}
        ml={breakpoint === 'none' ? '-0.75rem' : breakpoint ? { base: 0, [breakpoint]: '-0.75rem'} : { base: 0, md: '-0.75rem'}}
        mr={breakpoint === 'none' ? '-0.75rem' : breakpoint ? { base: 0, [breakpoint]: '-0.75rem'} : { base: 0, md: '-0.75rem'}}
        w={breakpoint === 'none' ? 'calc(100% + 1.5rem)' : breakpoint ? { base: '100%', [breakpoint]: 'calc(100% + 1.5rem)' } : { base: '100%', md: 'calc(100% + 1.5rem)'}}
        {...extraProps}
        {...classNameProps}
      >
        <Grid
          templateColumns={classNamePropTemplateColumns ? classNamePropTemplateColumns : templateColumns}
          className={`wpg-b_columns has-${colsLength}-columns`}
        >
          {columns}
        </Grid>
      </Block>
    </Block>
  );
};

export default ColumnsBlock;
