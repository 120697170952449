import loadable from '@loadable/component';

import Heading from '@rentivo/gatsby-core/src/components/wpg/common/Heading';
import Image from '@rentivo/gatsby-core/src/components/wpg/common/Image';
import List from '@rentivo/gatsby-core/src/components/wpg/common/List';
import Paragraph from '@rentivo/gatsby-core/src/components/wpg/common/Paragraph';
import Quote from '@rentivo/gatsby-core/src/components/wpg/common/Quote';
import MediaText from '@rentivo/gatsby-core/src/components/wpg/common/MediaText';
import Spacer from '@rentivo/gatsby-core/src/components/wpg/common/Spacer';
import Separator from '@rentivo/gatsby-core/src/components/wpg/common/Separator';
import Block from '@rentivo/gatsby-core/src/components/wpg/common/Block';

const Cover = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Cover'));
const Gallery = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Gallery'));
const Video = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Video'));

//const Audio = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/audio'));
//const File = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/file'));

/*
const Heading = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Heading'));
const Image = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Image'));
const List = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/List'));
const Paragraph = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Paragraph'));
const Quote = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Quote'));
const MediaText = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/MediaText'));
const Spacer = loadable(() => import('@rentivo/gatsby-core/src/components/wpg/common/Spacer'));
*/

export default {
  //Audio,
  Cover,
  //File,
  Block,
  Gallery,
  Heading,
  Image,
  List,
  Paragraph,
  Quote,
  Video,
  MediaText,
  Spacer,
  Separator
}
