import { getIdByEvaluation, sortOptions } from '@rentivo/gatsby-core/src/components/ui/NestedCollectionSelector/utils';
import { urlParamValueParser } from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/urlParams';
import { isEqual } from 'lodash';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/LocationFilter/components/Collections/messages';

export const parseCollections = (collections, currentLang, formatMessage, isChild = false) => {
  let parsedCollections = [];
  
  if(!collections) return [];

  for (const c of collections) {
    if (!c || !c.slug) break;
    const { slug, databaseId, menuOrder, collection: { inNestedSelector, name, destination, filters }, language: { slug: lang }, relationships, translations } = c || {};

    const { elementChildren } = relationships || {};

    const [translation] = translations.filter(c => c.language.slug === currentLang);

    if(inNestedSelector/* && lang === currentLang*/) {
      const label = translation ? translation.title : name;
      const orderNumber = (!menuOrder) ? 0 : menuOrder;
      const collectionDetails = {
        label,
        value:{
          order: `${orderNumber}-${label}`,
          slug: slug,
          id: databaseId,
          name: translation ? translation.name : name,
          databaseId: databaseId,
          destination: translation ? translation.collection.destination : destination,
          filters: translation ? translation.collection.filters : filters,
        }
      };

      if(elementChildren && elementChildren.length) {
        collectionDetails.children = parseCollections(elementChildren, currentLang, formatMessage, true);
      }
      parsedCollections.push(collectionDetails);
    }
  }

  return sortOptions(parsedCollections);
};

export const parseAllCollections = (collections, currentLang, formatMessage) => {
  let parsedCollections = [];

  for (const c of collections) {
    if(!c || !c.slug) break;
    const { slug, collection, inNestedSelector, language: { lang }, relationships } = c || {};

    const { elementChildren = [] } = relationships || {};

    const collectionDetails = {
      label: collection.name,
      value:{
        slug,
        id: slug,
        destination: collection.destination,
        filters: collection.filters
      }
    };

    if(elementChildren && elementChildren.length) {
      collectionDetails.children = parseAllCollections(elementChildren, currentLang, formatMessage).sort((a, b) => parseFloat(a.menuOrder) - parseFloat(b.menuOrder));
    }

    if(inNestedSelector === undefined && lang === currentLang) {
      parsedCollections.push(collectionDetails);
    }
  }

  // If only 1 && 1 has children, expand items... global.
  if(parsedCollections.length === 1 && parsedCollections[0].children && parsedCollections[0].children.length > 1) {
    const { children, ...parentItem } = parsedCollections[0];
    parentItem.label = formatMessage(messages.allPlaceName, { placeName: parentItem.label });
    parsedCollections = [parentItem, ...children];
  }

  return parsedCollections;
};

export const getCurrentCollectionID = (collections, value, filterOptions) => getIdByEvaluation(collections, (option) => {
  if(option && option.value && option.value.id && option.value.filters) {

    const destination = option.value.destination;
    if(destination && destination.name && destination.boundingBox && destination.boundingBox.coordinates) {
      const optionValue = urlParamValueParser(`${destination.name}~${destination.boundingBox.coordinates}`, filterOptions);
      return (isEqual(optionValue, value));
      // TODO: getCurrentCollectionID does it work?
      // TODO: Need to sort for Google Search too.
      // TODO: Not working for collections...
    }

    const locationFilters = option.value.filters.filter(f => f.key === 'location');
    if(locationFilters && locationFilters.length) {
      const locationFilter = locationFilters[0];
      const optionValue = urlParamValueParser(locationFilter.value, filterOptions);
      return (isEqual(optionValue, value));
    }
  }
  return false;
});

export const injectNewOptions = (options, id, newOptions) => {
  for (let i = 0; i < options.length; i++) {
    if (options[i]?.value?.databaseId === id) {
      options[i].children = sortOptions(newOptions);
      break;
    } else if(options[i].children && options[i].children.length) {
      options[i].children = injectNewOptions(options[i].children, id, newOptions);
    }
  }
  return options;
};
