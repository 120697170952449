export const calculateGuests = (
  {
    adults,
    children,
    infants,
    pets
  }, {
    petsCount = false,
    infantsCount = false,
    childrenCount = true,
    adultsCount = true
}) => {
  let guests = 0;
  if(adultsCount) guests = guests + adults;
  if(childrenCount) guests = guests + children;
  if(infantsCount) guests = guests + infants;
  if(petsCount) guests = guests + pets;
  return {
    adults,
    children,
    infants,
    pets,
    guests
  }
};
