import React from 'react';
import { Flex, CloseButton, Text, IconButton, useStyleConfig } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { Button } from '@rentivo/gatsby-core/index';
import RenderHtml from '@rentivo/gatsby-core/src/components/generic/RenderHtml';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

const UpdateBarBlock = ({className, childProps, attrs}) => {
  const { inner } = useStyleConfig('UpdateBar', {});
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_update-bar');
  const { onCloseUpdateBar } = childProps;
  const {
    backgroundColor = 'primary.500',
    buttonClassName,
    textClassName,
    text,
    useLightText,
    buttonLabel,
    buttonLink,
    buttonType,
    buttonGhost,
    buttonSize = 'small'
  } = attrs;

  if(useLightText) {
    childProps = { ...childProps, color: "white" };
  }

  return (
    <Flex
      align="center"
      justify="center"
      //direction={{base: 'column', md: 'row'}}
      bg={backgroundColor}
      {...inner}
      {...classNameProps}
    >
      <Text
        as="div"
        mb={0}
        mr={3}
        {...childProps}
      >
        <RenderHtml html={text}/>
      </Text>
      <IconButton display={{base: 'inline-block', md: 'none'}} type={buttonType} ghost={buttonGhost} size="sm" aria-label="Go" icon={<AwesomeIcon icon={faChevronRight} />} />
      <Button display={{base: 'none', md: 'inline-block'}} type={buttonType} ghost={buttonGhost} size={buttonSize}>{buttonLabel}</Button>
      {onCloseUpdateBar && (
        <CloseButton color={useLightText ? 'white' : 'text'} ml={{base: 2, md: 3}} onClick={onCloseUpdateBar}/>
      )}
    </Flex>
  );
};

export default UpdateBarBlock;
