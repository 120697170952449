import React  from 'react';
import Img from 'gatsby-image';
import AspectRatio from '@rentivo/gatsby-core/src/components/ui/AspectRatio';
import InView from '@rentivo/gatsby-core/src/components/generic/InView';
import { ConditionalWrapper, LocaleLink } from '@rentivo/gatsby-core/index';
import FadeIn from '@rentivo/gatsby-core/src/components/ui/FadeIn';
import { Helmet } from 'react-helmet';
import SmallestImage from '@rentivo/gatsby-core/src/components/generic/SmallestImage';
import { chakra } from '@chakra-ui/react';

const CdnImageComponent = ({
 imageUrl,
 ogImage,
 twitterImage,
 imageSrcSet,
 useProgressiveImage,
 useGatsbyImage,
 useReactImage,
 fallback,
 verticalOffset,
 aspect,
 expandAspectChild,
 critical,
 aspectDecimal,
 durationFadeIn,
 fadeIn,
 alt,
 style,
 styleProps,
 className,
 href,
 linkTarget,
 height,
 width
}) => {
  const useAspect = (aspect !== undefined && aspect !== null);
  if(!imageUrl) return null;
  if(ogImage) return <Helmet><meta property="og:image" content={imageUrl}/></Helmet>;
  if(twitterImage) return <Helmet><meta name="twitter:image" content={imageUrl}/></Helmet>;

  if(useGatsbyImage && aspectDecimal) {

    const fluidProps = {
      aspectRatio: aspectDecimal,
      src: imageUrl,
      ...imageSrcSet,
      critical,
      durationFadeIn,
      fadeIn
    };

    return <Img style={style} fluid={fluidProps}/>;

  } else {

    let imageProps = {};
    if(useProgressiveImage && imageSrcSet) {
      imageProps = {
        ...imageProps,
        ...imageSrcSet
      };
    }

    if(width && width !== 'auto' && height) {
      style = { ...style, width, height };
    }

    //console.log(critical, alt);

    return (
      <ConditionalWrapper condition={(href)} wrapper={c => <LocaleLink to={href} target={linkTarget}>{c}</LocaleLink>}>
        <ConditionalWrapper condition={useAspect} wrapper={c => <AspectRatio width={width} ratio={aspect} expandChild={expandAspectChild}>{c}</AspectRatio>}>
          <ConditionalWrapper condition={!critical} wrapper={c => <InView el="span" verticalOffset={verticalOffset} fallback={<SmallestImage alt={alt}/>}>{c}</InView>}>
            <ConditionalWrapper condition={fadeIn && !critical} wrapper={c => <FadeIn wrapperTag="span" childTag="span" transitionDuration={durationFadeIn}>{c}</FadeIn>}>
              <chakra.img style={style} src={imageUrl} alt={alt} className={className} {...imageProps} {...styleProps}/>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </ConditionalWrapper>
      </ConditionalWrapper>
    );
  }
};

export default CdnImageComponent;
