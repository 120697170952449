import React from 'react';
import { Box } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';

const SpacerBlock = (props) => {
  const { attrs, className } = props;
  const { height = 100 } = attrs;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_spacer');

  return (
    <Box h={`${height}px`} {...classNameProps}/>
  );
};

export default SpacerBlock;
