import { createSelector } from 'reselect';
import {
  selectElasticSearchConfig,
  selectIndicesConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { selectESItems, selectItemFromESItems } from '@rentivo/gatsby-core/src/containers/ESProvider/selectors';
import { getSearchEndpoint } from '@rentivo/gatsby-core/src/hooks/useElasticSearch/utils';

export const makeSelectESConfig = createSelector(
  selectSiteNameConfig,
  selectElasticSearchConfig,
  selectIndicesConfig,
  (siteName, elasticSearch, indices) => {
    const { app, credentials, url } = elasticSearch || {};
    return {
      siteName,
      elasticSearch,
      searchEndpoint: getSearchEndpoint(url, app, false),
      collectionsEndpoint: indices.collections ? getSearchEndpoint(url, indices.collections, false) : null,
      hubsEndpoint: indices.hubs ? getSearchEndpoint(url, indices.hubs, false) : null,
      postsEndpoint: indices.posts ? getSearchEndpoint(url, indices.posts, false) : null,
      experiencesEndpoint: indices.experiences ? getSearchEndpoint(url, indices.experiences, false) : null
    }
  }
);

export const makeSelectDataItem = (key) => createSelector(
  selectESItems,
  (items) => {
    return selectItemFromESItems(key, items);
  }
);
