import React from 'react';
import { Divider } from '@chakra-ui/react';
import { useClassNameProps } from '@rentivo/gatsby-core/src/components/wpg/hooks';
import { stripNonStyles } from '@rentivo/gatsby-core/src/components/wpg/utils';

const SeparatorBlock = (props) => {
  const { attrs, className, childProps } = props;
  const { customColor = 'gray.300' } = attrs;
  const { options, ...classNameProps } = useClassNameProps(className, 'wpg-b_spacer');
  return (
    <Divider
      orientation="horizontal"
      borderBottomColor={customColor}
      ml="auto"
      mr="auto"
      {...stripNonStyles(childProps)}
      {...classNameProps}
    />
  );
};

export default SeparatorBlock;
