import React from 'react';
import GuestsPicker from '@rentivo/gatsby-core/src/components/ui/GuestsPicker/component';
import { FILTER_NUMBER_TYPE_COUNTER } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';

GuestsPicker.defaultProps = {
  options: {
    type: FILTER_NUMBER_TYPE_COUNTER,
    min: 0,
    max: 24,
    adults: {
      active: true,
      defaultValue: 0,
      countsToTotal: true,
      min: 0,
      max: 24,
    },
    children: {
      active: true,
      defaultValue: 0,
      countsToTotal: true,
      min: 0,
      max: 24,
    },
    infants: {
      active: true,
      defaultValue: 0,
      countsToTotal: false,
      min: 0,
      max: 24,
    },
    pets: {
      active: true,
      defaultValue: 0,
      countsToTotal: false,
      min: 0,
      max: 1
    }
  },
  adults: 0,
  children: 0,
  infants: 0,
  pets: 0,
  onChange: () => null
};

export default GuestsPicker;
