import dayjs from 'dayjs'

export const query = ({ filterOptions, value, allFilters, reduxState }) => {
  if (!value) return null
  const startDateDay =
    value && value.length && value[0] ? dayjs(value[0]) : null
  const isBeforeOneYear =
    startDateDay && startDateDay.isBefore(dayjs().add(1, 'year'))

  const nights =
    value && value.length && value[0] && value[1]
      ? dayjs(value[1]).diff(value[0], 'day')
      : 0

  console.log('search query', { nights, isBeforeOneYear })

  const query = {
    query: {
      script: {
        script: {
          id: 'situ_availability_filter',
          params: {
            arrivalDate: value[0],
            departureDate: value[1],
          },
        },
      },
    },
    sort: [
      {
        _script: {
          order: 'desc',
          type: 'number',
          script: {
            id: 'situ_availability_sort',
            params: {
              arrivalDate: value[0],
              departureDate: value[1],
            },
          },
        },
      },
    ],
  }

  return query
}

export const defaultQuery = query
