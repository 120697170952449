import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
  selectDefaultLang, selectLang,
  selectLanguages,
  selectLocale, selectLocales, selectLocalesOnly,
  selectUri
} from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { changeLocale } from '@rentivo/gatsby-core/src/containers/LanguageProvider/actions';
import LocaleToggleComponent from '@rentivo/gatsby-core/src/containers/LanguageProvider/components/LocaleToggle/component';
import { redirectToNewLocale } from '@rentivo/gatsby-core/src/containers/LanguageProvider/components/LocaleToggle/utils';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';

const mapStateToProps = createSelector(
  selectLocale,
  selectLang,
  selectLanguages,
  selectLocales,
  selectLocalesOnly,
  selectDefaultLang,
  selectUri, // TODO: Not needed, as it comes from pageContent now.
  (locale, lang, languages, locales, localesOnly, defaultLang, uri) => ({
    locale,
    lang,
    languages,
    locales,
    localesOnly,
    defaultLang,
    uri
  })
);

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { pageContext: { uri, translations, lang: contextLang, locale: contextLocale, isDynamic }, location: { pathname } } = ownProps;
  const { dispatch } = dispatchProps;
  let { defaultLang, locale, lang, /* uri */ } = stateProps;

  lang = (contextLang) ? contextLang : lang;
  locale = (contextLocale) ? contextLocale : locale;

  // TODO: Now using pathname, do we need uri from page context anymore?

  return {
    ...stateProps,
    ...ownProps,
    lang,
    locale,
    onLocaleToggle: (newLocale) => {
      const splitLocale = (newLocale) ? newLocale.split('_') : ['en'];
      const newLang = (splitLocale.length) ? splitLocale[0] : 'en';

      dispatch(changeLocale(newLocale, newLang));
      redirectToNewLocale(lang, newLang, defaultLang, translations, pathname, isDynamic); // Locale needs to be lang...
    }
  }
};

const enhance = compose(
  withRouter,
  withPageContext(),
  connect(mapStateToProps, null, mergeProps),
);

export default enhance(LocaleToggleComponent);
