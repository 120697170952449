import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilterPopover/messages';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import { tagValue } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilter/tag';
import loadable from '@loadable/component';
import { StyledFilter } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/styles';

const Component = loadable(() => import('@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/AvailabilityFilter'));

const AvailabilityFilterPopoverComponent = ({filterComponent, title, scrollTo, button, size, runQuery, setFilterFrom, filterOptions, filter, className, children, styleProps = {}, ...rest}) => {
  const [visible, setVisible] = useState(false);
  const value = (filter && filter.value) ? filter.value : filterOptions.defaultValue;

  return (
    <StyledFilter className={className} {...styleProps}>
      {title === undefined ? '' : title === true ? <label><FormattedMessage {...messages.title}/></label> : title}
      <PopoverButton
        component={filterComponent ? filterComponent : Component}
        componentProps={{runQuery, setFilterFrom}}
        button={button}
        visible={visible}
        popoverWidth={618}
        popoverHeight={339}
        scrollTo={scrollTo}
        onVisibleChange={() => setVisible(!visible)}
        buttonLabel={value ? <FormattedMessage {...messages.setLabel} values={{value: tagValue({value})}}/> : <FormattedMessage {...messages.label}/>}
      />
    </StyledFilter>
);
};

export default AvailabilityFilterPopoverComponent;

