import { chakra } from '@chakra-ui/react';

export const StyledPretendLocationFilterGooglePlacesInput = chakra('div', {
   baseStyle: {
      position: 'relative'
   }
});

export const StyledLocationFilterGooglePlacesGeoInput = chakra('div', {
   baseStyle: {
      position: 'absolute!important',
      inset: '0px',
      zIndex: 1,
      '.cancel-btn': {
         position: 'absolute',
         zIndex:4,
         right: '0.5rem',
         top: '50%',
         transform: 'translateY(-50%)'
      }
   }
});
