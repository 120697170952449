import get from 'lodash/get'
import {
  getBounds,
  handleLongitudeOutOfBounds,
} from '@rentivo/gatsby-core/src/utils/geo'

export const query = ({ filterOptions, value }) => {
  if (!value) return null
  const buffer = get(filterOptions, 'buffer', null)
  let bounds = value.slice(1)

  if (bounds.length === 1) {
    bounds = bounds[0]
  }

  if (
    bounds &&
    Array.isArray(bounds) &&
    bounds.length === 2 &&
    Array.isArray(bounds[0]) &&
    bounds[0].length === 2 &&
    Array.isArray(bounds[1]) &&
    bounds[1].length === 2
  ) {
    const query = {
      bool: {
        should: [
          {
            geo_bounding_box: {
              location: {
                top_right: {
                  lat: parseFloat(bounds[1][1]),
                  lon: handleLongitudeOutOfBounds(parseFloat(bounds[1][0])),
                },
                bottom_left: {
                  lat: parseFloat(bounds[0][1]),
                  lon: handleLongitudeOutOfBounds(parseFloat(bounds[0][0])),
                },
              },
            },
          },
        ],
      },
    }

    if (buffer) {
      const expandedBounds = getBounds(
        bounds[1][1],
        bounds[1][0],
        bounds[0][1],
        bounds[0][0],
        buffer
      )
      if (expandedBounds) {
        query.bool.should[0]['geo_bounding_box'].boost = 4
        query.bool.should.push({
          geo_distance: {
            distance: `${expandedBounds.distance}${expandedBounds.units}`,
            location: expandedBounds.center,
          },
        })
      }
    }

    return query
  } else if (bounds && Array.isArray(bounds) && bounds.length > 2) {
    const geoshape = []
    bounds.forEach(b => {
      if (b && b.length === 2) {
        geoshape.push({ lat: b[1], lon: b[0] })
      }
    })

    if (geoshape.length > 3) {
      return {
        query: {
          geo_polygon: {
            location: {
              points: geoshape,
            },
          },
        },
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export const defaultQuery = query
